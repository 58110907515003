import React from 'react';
import { Typography, Button, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import {inputArrayHelper} from '../../FormGenerator/fields';
import { DomainConfig } from '../../FormGenerator/formConfigs';

interface Props {
    remove: (index: number) => void;
    add: (object: any) => void;
    form: DomainConfig;
    array: string;
    index: number;
    propsExpansion: number;
    values: any;
    pannelChoice: (panel: number) => void;
}

const ArrayItemCard: React.FC<Props> = ({form, remove, add, array, index, propsExpansion, pannelChoice, values}) => {

    const title = () => {

        if(values[array][index].name) {
            return values[array][index].name
        } if (values[array][index].componentName) {
            return values[array][index].componentName

        } if (values[array][index].expenseOngoingName) {
            return values[array][index].expenseOngoingName

        } if (values[array][index].expenseSetupName) {
            return values[array][index].expenseSetupName

        } if (values[array][index].channel) {
            return values[array][index].channel

        } else {
            return form.title
        }
    }

    const removeButton = () => {
        if (values[array].length > 1) {
            return <Grid item xs={6}>
                <Button 
                    onClick={() => {
                        remove(index)
                        pannelChoice(index-1)
                    }}
                    color="secondary"
                    size="small">
                    Remove
                </Button>
            </Grid>
        } else {
            return <Grid item xs={6}></Grid>
        }
    }

    return (
        <ExpansionPanel 
            expanded={propsExpansion === index} 
            onChange={() => pannelChoice(index)}
        >
            <ExpansionPanelSummary>
                <Typography variant="subtitle1">{title()}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container>
                    {form.options.map(option => inputArrayHelper(option, array, index))}
                    {removeButton()}
                    <Grid item xs={6}>
                        <Button 
                            color="primary"
                            onClick={() => {
                                add({})
                                pannelChoice(values[array].length)
                            }}
                            size="small">
                            Add Another
                        </Button>
                    </Grid>  
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>)
}

export default ArrayItemCard;