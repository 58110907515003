import React from 'react';
import { Typography, Button, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import {inputArrayHelper} from '../../components/FormGenerator/fields';
import { DomainConfig } from './formConfigs';

interface Props {
    remove: (index: number) => void;
    add: (object: any) => void;
    form: DomainConfig;
    array: string;
    index: number;
    propsExpansion: number;
    values: any;
    panelChoice: (panel: number) => void;
}

const ArrayItemCard: React.FC<Props> = ({form, remove, add, array, index, propsExpansion, panelChoice, values}) => {

    const title = () => {

        if(values[array][index].name) {
            return values[array][index].name
        } if (values[array][index].componentName) {
            return values[array][index].componentName

        } if (values[array][index].expenseOngoingName) {
            return values[array][index].expenseOngoingName

        } if (values[array][index].expenseSetupName) {
            return values[array][index].expenseSetupName

        } if (values[array][index].channel) {
            return values[array][index].channel

        } else {
            return form.title
        }
    }

    return (
        <ExpansionPanel 
            expanded={propsExpansion === index} 
            onChange={() => panelChoice(index)}
        >
            <ExpansionPanelSummary>
                <Typography variant="subtitle1">{title()}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container>
                    {form.options.map(option => inputArrayHelper(option, array, index))}
                    <Grid item xs={6}>
                        <Button 
                            onClick={() => {
                                remove(index)
                                panelChoice(index-1)
                            }}
                            color="secondary"
                            size="small">
                            Remove
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                            color="primary"
                            onClick={() => {
                                add({})
                                panelChoice(index+1)
                            }}
                            size="small">
                            Add Another
                        </Button>
                    </Grid>  
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>)
}

export default ArrayItemCard;