import React from 'react';
import {store} from '../../store/store';
import {hasLoggedInUser} from '../../stitch/authentication';
import { Redirect, Route } from 'react-router';

const PrivateRoute = ({component, ...rest}: any) => {
        if (!hasLoggedInUser) {
            return <Redirect to={{pathname: '/login'}}/>
        } else {
            const routeComponent = (props: any) => React.createElement(component, props)
            return <Route {...rest} render={routeComponent}/>;
        }
}; 

export default PrivateRoute;