import React from 'react';
import OnboardingOneProductDialog from './ProductOptions/OnboardingOneProductDialog';
import OnboardingAverageRevenueDialog from './ProductOptions/OnboardingAverageRevenueDialog';

interface FuncProps {
    next: (form: any, stepName: string) => void;
    back: () => void;
    close: () => void;
    currentStep: number;
    choice: string;
    initialValues: any;
}

const OnboardingProduct: React.FC<FuncProps> = ({next, back, close, currentStep, choice, initialValues}) => {
    switch(choice) {
        case 'oneProduct':
            return <OnboardingOneProductDialog 
                next={next} back={back} close={close} 
                currentStep={currentStep} 
                initialValues={initialValues} 
            />
        case 'avgRevenue':
            return <OnboardingAverageRevenueDialog 
                next={next} 
                back={back} 
                close={close} 
                currentStep={currentStep} 
                initialValues={initialValues} 
            />
        default: 
        return <OnboardingAverageRevenueDialog 
            next={next} 
            back={back} 
            close={close} 
            currentStep={currentStep} 
            initialValues={initialValues}  
        />
    }
}

export default OnboardingProduct