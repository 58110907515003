import React from 'react';
import _ from 'lodash';
import { Formik, Form, FieldArray } from 'formik';
import * as config from '../FormGenerator/formConfigs';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid, Card, CardContent } from '@material-ui/core';
import ArrayItemCard from './FieldArray/ArrayItemCard';
import { inputHelper } from '../FormGenerator/fields';
import { FieldSize, ProjectDurationFrequency } from '../../classes/types';
import classes from './OnboardingSteps.module.css';
import * as Yup from 'yup';
import { checkUserMobile } from '../../helpers/helpers';

interface FuncProps {
    next: (form: any, stepName: string) => void;
    back: () => void;
    close: () => void;
    currentStep: number;
    variable: string;
    initialValues: any
}

const validationSchema = Yup.object().shape({
    improvNumber: Yup
        .number()
        .min(0, 'Improvement Number must be greater than 0')
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a number'),
})

const OnboardingSalesFunnelStepTwoDialog: React.FC<FuncProps>= ({next, back, close, currentStep, variable, initialValues}) => { 

    const thisStep = 5;
    const stepName = 'salesFunnelStepTwo';

    const [expEnabled, setExpectationState] = React.useState();

    const handleButtonClick = (state: boolean) => {
        setExpectationState(state)
    }

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
            next(values, stepName);
            actions.resetForm();
            actions.setSubmitting(false);
            close()

        }}
    >
        {({ values, isSubmitting, dirty, errors }) => (
            <Dialog
                open={thisStep === currentStep}
                onClose={close}
                disableBackdropClick={true}
                fullWidth={true}
                fullScreen={checkUserMobile()}
                maxWidth="md"
            >
                <Form>
                    <DialogTitle> Create a Sales Funnel: Funnel Costs</DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}><Typography variant="h6">Do you expect your Funnel to generate any {_.capitalize(variable)} passively?</Typography></Grid>
                            <Grid item xs={6}>
                                <Button onClick={()=> handleButtonClick(true)} disabled={expEnabled === undefined ? false : expEnabled} color="primary" variant="outlined">Yes</Button>
                                {/*
                                //@ts-ignore */}
                                <Button onClick={()=> {handleButtonClick(false); values.improvNumber = null}} disabled={expEnabled === undefined ? false : !expEnabled} color="primary" variant="outlined">No</Button>
                            </Grid>
                            {expEnabled === true ? (
                                <Grid item xs={12} style={{marginTop: "10px"}}>
                                <Card>
                                    <CardContent>
                                        <Grid item xs={12}><Typography variant="h6">How much {_.capitalize(variable)} per month?</Typography></Grid>
                                        <Grid item xs={12}>
                                            {inputHelper({
                                                name: "improvNumber",
                                                label: _.capitalize(variable) + " Amount",
                                                input: 'number',
                                                required: true,
                                                size: FieldSize.six,
                                            })}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>) : null
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={back}
                            color="secondary" 
                            type="button">
                            Back
                        </Button>
                        <Button 
                            disabled={
                                isSubmitting || 
                                !_.isEmpty(errors)
                            } 
                            color="primary" 
                            type="submit">
                            Next Step
                        </Button>
                    </DialogActions>    
                </Form>      
        </Dialog>
        )}
    </Formik>
    )
}

export default OnboardingSalesFunnelStepTwoDialog;