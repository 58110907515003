import React from 'react'

import { FieldArray } from 'formik';
import { DomainConfig } from '../formConfigs';

import { Typography, Button, Grid } from '@material-ui/core';
import ArrayItemCard from '../ArrayItemCard';

import classes from '../FormGenerator.module.css';

interface MyProps {
    domain: DomainConfig;
    values: any;
}

const ArrayOptions: React.FC<MyProps> = ({domain, values}) => {

    const [selection, setSelection] = React.useState(0);

    const handleExpansionChoice = (choice: number) => {
        setSelection(choice);
    }
    return (<React.Fragment key={domain.kind}>
        <Grid item xs={12}><Typography variant="h6">{domain.arrayTitle}</Typography></Grid>
        <FieldArray
                name={domain.kind} 
                render={arrayHelpers => (
                    <div>
                        {values[domain.kind].length > 0? 
                            (values[domain.kind].map((item: any, index: any) => 
                            <div className={classes.arrayCard}>
                                <ArrayItemCard 
                                    panelChoice={handleExpansionChoice}
                                    propsExpansion={selection}
                                    array={domain.kind} 
                                    index={index} 
                                    form={domain} 
                                    remove={arrayHelpers.remove} 
                                    values={values}
                                    add={arrayHelpers.push}
                                />
                            </div>))       
                            : (
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button" 
                                    onClick={() => {
                                        arrayHelpers.push({})
                                        setSelection(0)
                                }}>Add New</Button>
                            </Grid> 
                        )}
                    </div>
                )}
            />
    </React.Fragment>)
}

export default ArrayOptions