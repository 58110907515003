import React from 'react';
import { Grid, Card, CardContent, Typography, ButtonBase, Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemIcon, Icon } from '@material-ui/core';
import numeral from 'numeral';
import moment from 'moment';
import { checkUserMobile } from '../../helpers/helpers';

interface FuncProps {
    projections: any
}

const ProjectionGrid: React.FC<FuncProps> = ({projections}) => {
    const max = Math.max.apply(Math, projections.map((event: any) => event.totals.gross))
    const min = Math.min.apply(Math, projections.map((event: any) => event.totals.gross))

    const backgroundColorReturn = (value: number) => {
        if(value < 0 && value > min/3) {
            return '#ffcdd2'
        } else if (value < min/3 && value > min/3*2) {
            return '#f44336'
        } else if (value < min/3*2) {
            return '#c62828'
        } else if (value > 0 && value < max/3) {
            return '#c8e6c9'
        } else if (value > max/3 && value < max/3*2) {
            return '#4caf50'
        } else if (value > max/3*2) {
            return '#2e7d32'
        } else {
            return '#fffcfc'
        }
    }
    const textColorReturn = (value: number) => {
        if(value < 0 && value > min/3) {
            return 'black'
        } else if (value < min/3 && value > min/3*2) {
            return 'black'
        } else if (value < min/3*2) {
            return 'white'
        } else if (value > 0 && value < max/3) {
            return 'black'
        } else if (value > max/3 && value < max/3*2) {
            return 'black'
        } else if (value > max/3*2) {
            return 'white'
        } else {
            return 'black'
        }
    }

    const card = {
        margin: 'auto', 
        width: '100%',
        backgroundColor: '#fffcfc',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#C9EAF8',
        marginBottom: '5px'
    }

    const heatmapBoxStyle = (value: number) => {
        return {
            width: '95%',
            height: '100px',
            marginBottom: '5%',
            backgroundColor: backgroundColorReturn(value),
            color: textColorReturn(value),
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'

        } as React.CSSProperties
    }

    const [dialogState, dialogStateSetter] = React.useState(false)
    const [dialogEvent, eventSetter] = React.useState({month: '', revenue: {sum: 0}, expenses: {sum: 0}, totals:{gross: 0, runningTotal: 0}})

    const dialogClose = () => {
        dialogStateSetter(false);
    }

    const dialogOpen = (event: any) => {
        dialogStateSetter(true); 
        eventSetter(event);
    }

    const revenueSection = () => {
        if (Object.entries(dialogEvent.revenue).length > 1) {
            return <Card style={card}>
                <CardContent>
                    <Typography variant="h6">Revenue</Typography>
                        {Object.entries(dialogEvent.revenue).map(([key, value]) => {
                            if (key !== "sum") {
                                return <List dense>
                                    <ListItem><ListItemIcon><Icon style={{color: 'green'}}>attach_money</Icon></ListItemIcon>{key}: ${numeral(value).format('0,0.00')}</ListItem>
                                </List>
                            }
                            
                        })}
                </CardContent>
            </Card>
        } else {
            return <Card style={card}>
            <CardContent>
            <Typography variant="h6">Revenue</Typography>
               <List dense>
                    <ListItem><ListItemIcon><Icon>close</Icon></ListItemIcon><i>No Revenue Items</i></ListItem>
                </List>
            </CardContent>
        </Card>
        }
    }

    const expenseSection = () => {
        if (Object.entries(dialogEvent.expenses).length > 1) {
            console.log(Object.entries(dialogEvent.expenses))
            return <Card style={card}>
                <CardContent>
                    <Typography variant="h6">Expenses</Typography>
                    {Object.entries(dialogEvent.expenses).map(([key, value]) => {
                        if (key !== "sum") {
                            return <List dense>
                                <ListItem><ListItemIcon><Icon style={{color: 'red'}}>attach_money</Icon></ListItemIcon>{key}: ${numeral(value).format('0,0.00')}</ListItem>
                            </List>
                        }
                        
                        })}
                </CardContent>
            </Card>  
        } else {
            return <Card style={card}>
                <CardContent>
                    <Typography variant="h6">Expenses</Typography>
                    <List dense>
                        <ListItem><ListItemIcon><Icon>close</Icon></ListItemIcon><i>No Expense Items</i></ListItem>
                    </List>
                    </CardContent>
            </Card>

        }
    }

    const color = (value: number) => {
        if (value < 0) {
            return 'red'
        } if (value > 0) {
            return 'green'
        } else {
            return 'black'
        }
    }

    
    const MonthDialog = () => (
        <Dialog
            open={dialogState}
            onClose={dialogClose}
            fullWidth={true}
            fullScreen={checkUserMobile()}
        >
            <DialogTitle>{moment(dialogEvent.month).format('MMM YYYY')}</DialogTitle>
            <DialogContent>
                {revenueSection()}
                {expenseSection()}
                <Card style={card}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={6}><Typography><b>Total Revenue:</b></Typography></Grid><Grid item xs={6}><Typography> {numeral(dialogEvent.revenue.sum).format('$0,0.00')}</Typography></Grid>
                            <Grid item xs={6}><Typography><b>Total Expenses:</b></Typography></Grid><Grid item xs={6}><Typography>{numeral(dialogEvent.expenses.sum * -1).format('$0,0.00')}</Typography></Grid>
                            <br />
                            <Grid item xs={6}><Typography><b>Profit/Loss:</b></Typography></Grid><Grid item xs={6}><Typography style={{color: color(dialogEvent.totals.gross)}}><b>{numeral(dialogEvent.totals.gross).format('$0,0.00')}</b></Typography></Grid>
                            <Grid item xs={6}><Typography><b>Running Total:</b></Typography></Grid><Grid item xs={6}><Typography style={{color: color(dialogEvent.totals.runningTotal)}}><b>{numeral(dialogEvent.totals.runningTotal).format('$0,0.00')}</b></Typography></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions><Button color="primary" onClick={dialogClose}>Close</Button></DialogActions>
        </Dialog>
    )


    return (<Grid container>
        <Grid container style={{marginLeft: '3%', marginRight:'3%'}}>
            {projections.map((event: any, i: number) => <React.Fragment>
                {i/12 % 1 === 0 ? <Grid item xs={12} style={{marginTop: '10px'}}><Typography variant="h5">Year {(i/12)+1}</Typography></Grid> : null }
                <Grid item xs={4}>
                    <ButtonBase style={heatmapBoxStyle(event.totals.gross)} onClick={() => dialogOpen(event)}>
                        <div >
                            <Grid container style={{textAlign: 'center', display: 'inline'}}>
                                <Typography variant="subtitle1"><b>{moment(event.month).format('MMM YYYY')}</b><br/>${numeral(event.totals.gross).format('0,0.00')}</Typography>
                            </Grid>
                        </div>  
                    </ButtonBase>
                </Grid>
            </React.Fragment>)}  
        </Grid>
        {MonthDialog()}
    </Grid>)
}

export default ProjectionGrid;
