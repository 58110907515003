import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import { CardHeader, CardContent, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Divider } from '@material-ui/core';
import ActionOptions from './ActionOptions/ActionOptions';
import classes from './UserCards.module.css';
import { ProjectEventSettings } from '../../classes/userEvents';

interface Props {
    event: ProjectEventSettings;
    deleteHandler: (event: ProjectEventSettings) => void;
}

const GenericProjectCard: React.FC<Props> = ({event, deleteHandler}) => {

    const expectationDisplay = (expectation: any) => {
        //@ts-ignore
        if (expectation.type === "new" && expectation.rampUpType === "gradually") {
            return <Typography variant="subtitle2" color="textSecondary">Improve {_.capitalize(expectation.variable)} by {Math.round(expectation.improvNumber/expectation.rampUpMonths)} every month until {expectation.improvNumber} per month</Typography>  
        //@ts-ignore
        } else if (expectation.type === "new" && expectation.rampUpType === "gradually" && expectation.rampUpMonths === 0) {
            return <Typography variant="subtitle2" color="textSecondary">Improve {_.capitalize(expectation.variable)} by {expectation.improvNumber} every month</Typography>  
        //@ts-ignore
        } else if (expectation.type === "new" && expectation.rampUpType === "atOnce") {
            return <Typography variant="subtitle2" color="textSecondary">Improve {_.capitalize(expectation.variable)} by {expectation.improvNumber} every month after {expectation.rampUpMonths} months </Typography>  
        //@ts-ignore
        } else if (expectation.type === "percent_improvement" && expectation.rampUpType === "gradually" && expectation.rampUpMonths > 0) {
            return <Typography variant="subtitle2" color="textSecondary">Improve {_.capitalize(expectation.variable)} Conversions by {Math.round(expectation.improvNumber/expectation.rampUpMonths)}% every month until {expectation.improvNumber}% </Typography>  
        //@ts-ignore
        } else if (expectation.type === "percent_improvement" && expectation.rampUpType === "gradually" && expectation.rampUpMonths === 0) {
            return <Typography variant="subtitle2" color="textSecondary">Improve {_.capitalize(expectation.variable)} Conversions by {expectation.improvNumber}% every month</Typography>  
        //@ts-ignore
        } else if (expectation.type === "new" && expectation.rampUpType === "atOnce") {
            return <Typography variant="subtitle2" color="textSecondary">Improve {_.capitalize(expectation.variable)} Conversions by {expectation.improvNumber}% every month after {expectation.rampUpMonths} months </Typography>  
        }
    }

    return (<React.Fragment>
        <CardHeader 
            title={_.startCase(event.projectName)} 
            subheader={moment(event.date).format('DD MMMM YYYY')}
            action={<ActionOptions event={event} deleteHandler={deleteHandler} />}
        />
        <CardContent>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="body1"><b>Project Duration: </b>{event.duration} {event.durationFrequency}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1"><b>Project End Date: </b>
                        {moment(event.date).add(event.duration, event.durationFrequency).format('DD MMMM YYYY')}
                    </Typography>
                </Grid>            
                <br></br>
                <br></br>
                <br></br>
                <Divider />
                <Grid item xs={12}>
                    <Typography variant="h6">Project Costs</Typography>
                </Grid>
                    <Grid container>
                        {event.projectCosts.map(cost => 
                        <Grid item xs={12} key={cost.expenseSetupName}>
                            <ExpansionPanel className={classes.arrayCard}>
                                <ExpansionPanelSummary>
                                    <Typography variant="body1"><b>Name: </b>{cost.expenseSetupName}</Typography>  
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="body1"><b>Amount: </b>${numeral(cost.amount).format('0,0.00')}</Typography>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>)}
                    </Grid>
                    <br></br>
                    <Divider />
                    <Typography variant="h6">Maintenance Costs</Typography>
                    {event.ongoingCosts.map((cost: any) => 
                        <Grid item xs={12} key={cost.expenseOngoingName + cost.amount}>
                            <ExpansionPanel className={classes.arrayCard}>
                                <ExpansionPanelSummary>
                                    <Typography variant="body1"><b>Name: </b>{cost.expenseOngoingName}</Typography>  
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="body1"><b>Amount: </b>${numeral(cost.amount).format('0,0.00')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1"><b>Recurring: </b>{cost.recurring}</Typography>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>)}
                    {/* 
                    //@ts-ignore */}
                    {event.projectExpectations.map(expectation => <Grid container>
                        <Grid item xs={12}>
                            {expectationDisplay(expectation)}
                        </Grid>
                    </Grid>)}
                </Grid>
        </CardContent>
    </React.Fragment>)
}

export default GenericProjectCard;
