import React from 'react'
import _ from 'lodash'
import { VictoryPie, VictoryTooltip } from 'victory';

interface FuncProps {
    projections: any;
    type: string;
    color: any;
}

interface PieData {
    x: string;
    y: number;
}

const PieChart: React.FC<FuncProps> = ({projections, type, color}) => {
    const totals: any = {};
    let sum: number = 0;
    const chartData: Array<PieData> = [{x: _.capitalize(type), y: 1}];
    const legend: Array<any> = [{name: type}]

   projections.forEach((month: any) => {
    Object.keys(month[type]).forEach(key => {
        if (key !== 'sum') {
            totals[key] = 0;
            totals[key] = totals[key] + month[type][key];
        }
    })
   })

   projections.forEach((month: any) => {
       sum = sum + month[type].sum
   })

   setTimeout(() => {
        chartData.shift();
        legend.shift();
        Object.keys(totals).forEach(key => {
        const chartEntry = {x: key, y: totals[key]/sum}
        const legendEntry = {name: key}
        chartData.push(chartEntry)
        legend.push(legendEntry)
    })}, 100)

    

    return (
        <VictoryPie
            data={chartData}
            colorScale={color}
            animate={{
                duration: 500, 
                onEnter: {
                    duration: 500, 
                    before: () => ({y: 0})
                }
            }}
            labelComponent={<VictoryTooltip/>}
            labelRadius={50}
        />
       
    )
}

export default PieChart;
