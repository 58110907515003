import * as types from '../../classes/types';
import * as Yup from 'yup';


export interface Forms {
    forms: Array<FormConfig>;
}

export interface OptionSettings {
    name: string;
    label: string;
    input: string;
    size: types.FieldSize
    required?:boolean;
    placeholder?: string;   
    options?: any;
    helperText?: string;
 
 }

export interface DomainConfig {
    kind: string;
    title: string;
    arrayTitle?: string;
    type: 'options' | 'array';
    options: Array<OptionSettings>
}

export interface FormConfig {

    kind: types.UserEventKinds;
    title: string;
    components: Array<string>;
    initialValues: object;
    menuText?: string;
    validation: object;
    
}

// Validations

export const improvNumber = Yup.object().shape({
    improvNumber: Yup
        .number()
        .min(0, 'Improvement Number must be greater than 0')
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'number must be > 0')
        .required('Please enter a number'),
})

export const salesFunnelValidation = Yup.object().shape({
    projectName: Yup
        .string()
        .min(4)
        .required('Please enter your funnel name'),
    funnelInput: Yup
        .string()
        .min(1)
        .required('Please enter your funnel source'),
    funnelOutput: Yup
        .mixed()
        .required('Please make a selection'),
    funnelOutputRate: Yup        
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(1, 'Rate must be 1% or more')
        .max(100, "Rate cannot exceed 100%")
        .required('Please enter a rate'),
    leadTypes: Yup.array(Yup.object().shape({
        leadName: Yup
            .string()
            .min(4, "Tier name must be longer than 4 characters")
            .required('Please enter your company name'),
        conversionRate: Yup        
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(1, 'Rate must be 1% or more')
            .max(100, "Rate cannot exceed 100%")
            .required('Please enter a rate'),
        
    }))
})

export const foundersValidation = Yup.object().shape({
    founders: Yup.array(Yup.object().shape({
        name: Yup
            .string()
            .min(4, 'Must be at least 4 characters long')
            .required('Please input a name'),
        compensation: Yup
            .number()
            .nullable(true)
            .min(0, '$ greater than 0')
        })).min(0, 'One founder is required').required('At least 1 founder is required')
})

const founderValidationForCompany = Yup.array(Yup.object().shape({
    name: Yup
            .string()
            .min(4, 'Must be at least 4 characters long')
            .required('Please input a name'),
        compensation: Yup
            .number()
            .nullable(true)
            .min(0, '$ greater than 0')
    
})).min(0, 'One founder is required').required('At least 1 founder is required');

export const setupCostsValidationForCompany = Yup.array(Yup.object().shape({
    expenseSetupName: Yup
        .string()
        .required('Please enter a name'),
    amount: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Amount must be > $0')
        .required('Please input an amount')
}))

export const setupCostsValidation = Yup.object().shape({
    setupCosts: Yup.array(Yup.object().shape({
        expenseSetupName: Yup
            .string()
            .required('Please enter a name'),
        amount: Yup
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Amount must be > $0')
            .required('Please input an amount')
        }))
});

export const productPartComponentsValidation = Yup.array(Yup.object().shape({
    componentName: Yup
        .string()
        .required('Please enter a name'),
    unitCost: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Cost must be > $0')
        .required('Please enter a cost'),
    unitVolume: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Volume must be > 0')
        .required('Please enter a volume')
}));

export const productTypeComponentsValidation = Yup.array(Yup.object().shape({
    componentName: Yup
        .string()
        .required('Please enter a name'),
    unitCost: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Cost must be > $0')
        .required('Please enter a cost'),
    type: Yup
        .mixed()
        .required('Please make a selection')
}));

export const projectCostsValidation = Yup.array(Yup.object().shape({
        expenseSetupName: Yup
            .string()
            .required('Please enter a name'),
        amount: Yup
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Amount must be > $0')
            .required('Please input an amount')
    }));

export const ongoingCostsValidation = Yup.array(Yup.object().shape({
    expenseOngoingName: Yup
        .string()
        .required('Please enter a name'),
    amount: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Amount must be > $0')
        .required('Please input an amount'),
    recurring: Yup
        .mixed()
        .required('Please make a selection')
    }));

export const recurringCostValidation = Yup.object().shape({
        recurringCosts: Yup.array(Yup.object().shape({
        expenseOngoingName: Yup
            .string()
            .required('Please enter a name'),
        amount: Yup
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Amount must be > $0')
            .required('Please input an amount'),
        recurring: Yup
            .mixed()
            .required('Please make a selection')

        }))
    });

export const basicCompanyValidation = Yup.object().shape({
    companyName: Yup
        .string()
        .min(4)
        .required('Please enter your company name'),
    date: Yup
        .date()
        .required('Please enter your founding date'),
    founders: Yup.array(Yup.object().shape({
        name: Yup
            .string()
            .min(4, 'Must be at least 4 characters long')
            .required('Please input a name')
        })).min(0, 'One founder is required').required('At least 1 founder is required'),
    productName: Yup
        .string()
        .required('Please enter a name for your product/service'),
    price: Yup
        .number()
        .min(0, 'Price must be greater than 0')
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'number must be > 0')
        .required('Please enter a price'),
    amountOfSales: Yup
        .number()
        .min(0, 'Amount must be greater than 0')
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'number must be > 0')
        .required('Please enter an amount'),
    salesStartDate: Yup
        .date()
        .min(Yup.ref('date'),'Must be on or after your founding date')
        .typeError('Please enter a date')
        .required('Please enter a date'),
    costs: Yup.array(Yup.object().shape({
        expenseName: Yup
            .string()
            .min(4, 'Must be at least 4 characters long')
            .required('Please input a name'),
        expenseAmount: Yup
            .number()
            .min(0, 'Amount must be greater than 0')
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'number must be > 0')
            .required('Please enter an amount'),
        recurring: Yup
            .mixed()
            .required('Please select an option'),
        }))
})

const expectations = Yup.array(Yup.object().shape({
        variable: Yup
            .mixed()
            .required('Please select an option'),
        type: Yup
            .mixed()
            .required('Please select an option'),
        improvNumber: Yup
            .number()
            .min(0, 'Improvement Number must be greater than 0')
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Duration must be > 0')
            .required('Please enter an amount'),
        recurring: Yup
            .mixed()
            .required('Please select an option'),
        permanent: Yup
            .mixed()
            .required('Please select an option'),
        rampUpMonths: Yup
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Duration must be > 0')
            .required('Please enter the number of months'),
        rampUpType: Yup
            .mixed()
            .required('Please select an option'),
    }));

    const permanentExpectationValidation = Yup.array(Yup.object().shape({
        variable: Yup
            .mixed()
            .required('Please select an option'),
        type: Yup
            .mixed()
            .required('Please select an option'),
        improvNumber: Yup
            .number()
            .min(0, 'Improvement Number must be greater than 0')
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Duration must be > 0')
            .required('Please enter the number of months'),
        rampUpMonths: Yup
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Duration must be > 0')
            .required('Please enter the number of months'),
        rampUpType: Yup
            .mixed()
            .required('Please select an option'),
    }));

const employeeValidation = Yup.object().shape({
        department: Yup
            .mixed()
            .required('Please select an option'),
        date: Yup
            .date()
            .typeError('Please enter a date')
            .required('Please enter a date'),
        compensation: Yup
            .number()
            .nullable(true)
            .typeError('Please enter a compensation')
            .min(0, 'Amount must be > 0')
            .required('Please enter a compensation'),
        permanentExpectations: permanentExpectationValidation
            
        });
   

export const oneOffCostValidation = Yup.object().shape({
    oneOffCost: Yup.array(Yup.object().shape({
        date: Yup
            .date()
            .typeError('Please enter a date')
            .required('Please enter a date'),
        expenseSetupName: Yup
            .string()
            .required('Please enter a name'),
        amount: Yup
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Amount must be > $0')
            .required('Please input an amount')
    }))
});

export const companyOnboardingValidation = Yup.object().shape({
    companyName: Yup
        .string()
        .min(4)
        .required('Please enter your company name'),
    date: Yup
        .date()
        .required('Please enter your founding date')
});

export const companyValidation = Yup.object().shape({
    companyName: Yup
        .string()
        .min(4)
        .required('Please enter your company name'),
    date: Yup
        .date()
        .required('Please enter your founding date'),
    founders: founderValidationForCompany,
    setupCosts: setupCostsValidationForCompany
});

export const productValidation = Yup.object().shape({
    productName: Yup
        .string()
        .required('Please enter a name'),
    price: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Price must be > $0')
});

export const projectOneProductCreationValidation = Yup.object().shape({
    date: Yup
        .date()
        .typeError('Please enter a date')
        .required('Please enter a date'),
    duration: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a duration'),
    durationFrequency: Yup
        .mixed()
        .required('Please make a selection'),
    productName: Yup
        .string()
        .required('Please enter a name'),
    price: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Price must be > $0')
        .required('Please input a price'),
    projectCosts: projectCostsValidation,
    productComponents: productPartComponentsValidation
});

export const projectAvgRevProductCreationValidation = Yup.object().shape({
    date: Yup
        .date()
        .typeError('Please enter a date')
        .required('Please enter a date'),
    duration: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a duration'),
    durationFrequency: Yup
        .mixed()
        .required('Please make a selection'),
    productName: Yup
        .string()
        .required('Please enter a name'),
    price: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Price must be > $0')
        .required('Please input a price'),
    projectCosts: projectCostsValidation,
    productComponents: productTypeComponentsValidation
});

export const projectMarketingSiteOnboardingValidation = Yup.object().shape({
    date: Yup
        .date()
        .typeError('Please enter a date')
        .required('Please enter a date'),
    duration: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a duration'),
    durationFrequency: Yup
        .mixed()
        .required('Please make a selection'),
    traffic: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a duration'),
    projectCosts: projectCostsValidation,
    ongoingCosts: ongoingCostsValidation
});

export const projectMarketingSiteValidation = Yup.object().shape({
    date: Yup
        .date()
        .typeError('Please enter a date')
        .required('Please enter a date'),
    duration: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a duration'),
    durationFrequency: Yup
        .mixed()
        .required('Please make a selection'),
    traffic: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a duration'),
    percent_viewProduct: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a duration'),
    percent_addToCart: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a duration'),
    percent_purchase: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a duration'),
    
    projectCosts: projectCostsValidation,
    ongoingCosts: ongoingCostsValidation
});

export const funnelStepTwoValidation = Yup.object().shape({
    date: Yup
        .date()
        .typeError('Please enter a date')
        .required('Please enter a date'),
    duration: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a duration'),
    durationFrequency: Yup
        .mixed()
        .required('Please make a selection'),
    setupCosts: projectCostsValidation,
    ongoingCosts: ongoingCostsValidation
})


const marketingProjectConfigValidation = Yup.array(Yup.object().shape({
    channel: Yup
        .mixed()
        .required('Please select an option'),
    budget: Yup
        .number()
        .nullable(true)
        .typeError('Please enter an amount')
        .min(0, 'Amount must be > 0')
        .required('Please input a budget'),
    cpc: Yup
        .number()
        .typeError('Please enter an amount')
        .min(0, 'Amount must be > 0')
        .required('Please input the Cost Per Click')
}));

const projectMarketingCampaignValidation = Yup.object().shape({
    date: Yup
        .date()
        .typeError('Please enter a date')
        .required('Please enter a date'),
    duration: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a duration'),
    durationFrequency: Yup
        .mixed()
        .required('Please make a selection'),
    marketingProjectConfig: marketingProjectConfigValidation
});

const leadTypesValidation = Yup.array(Yup.object().shape({
    leadName: Yup
        .string()
        .min(4, "Tier name must be longer than 4 characters")
        .required('Please enter your company name'),
    conversionRate: Yup        
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(1, 'Rate must be 1% or more')
        .max(100, "Rate cannot exceed 100%")
        .required('Please enter a rate'),
    
}));

export const funnelSetupValidation = Yup.object().shape({
    projectName: Yup
        .string()
        .min(4)
        .required('Please enter your funnel name'),
    funnelInput: Yup
        .string()
        .min(1)
        .required('Please enter your funnel source'),
    funnelOutput: Yup
        .mixed()
        .required('Please make a selection'),
    funnelOutputRate: Yup        
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(1, 'Rate must be 1% or more')
        .max(100, "Rate cannot exceed 100%")
        .required('Please enter a rate'),
    leadTypes: leadTypesValidation
})

const genericProjectValidation = Yup.object().shape({
    date: Yup
        .date()
        .typeError('Please enter a date')
        .required('Please enter a date'),
    duration: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Duration must be > 0')
        .required('Please enter a duration'),
    durationFrequency: Yup
        .mixed()
        .required('Please make a selection'),
    projectCosts: setupCostsValidationForCompany,
    ongoingCosts: ongoingCostsValidation,
    expectations
});




// Initial Values

export const initalValues_Company = {
    companyName: '',
    date: undefined
}

const initialValues_permanentExpectations = {
        improvNumber: undefined,
        variable: undefined,
        type: undefined,
        rampUpMonths: undefined,
        rampUpType: undefined

}

export const initalValues_founders = {
    date: undefined,
    founders: [
        {
            name: undefined,
            availabilityFrequency: undefined,
            availabilityHours: undefined,
            equity: undefined,
            compensation: undefined,
            cashInvestment: undefined
        }
    ]
}

export const initialValues_funnelLeadComponent = [
    {
        leadName: undefined,
        conversionRate: undefined
    }
]

export const initialValues_setupCosts = {
    setupCosts: [
        {
            expenseSetupName: undefined,
            amount: undefined
        }
    ]
}

export const initialValues_oneOffCost = [
    {
        expenseSetupName: undefined,
        amount: undefined
    }

]

export const initialValues_projectSetupCosts = [
        {
            expenseSetupName: undefined,
            amount: undefined
        }
    ]

const initialValues_ongoingCosts = [
    {
        expenseOngoingName: '',
        amount: undefined,
        recurring: undefined
    }
]

const initialValues_productComponent = [
    {
        componentName: '',
        unitCost: undefined,
        unitVolume: undefined
    }
]
export const initalValues_ProductCreation = {
        date: undefined,
        duration: undefined,
        durationFrequency: undefined,
        designPercentage: undefined,
        buildPercentage: undefined,
        projectCosts: initialValues_projectSetupCosts,
        productName: '', 
        price: undefined,
        productComponents: initialValues_productComponent
}

const initialValues_marketingProjectConfig = [
    {
        channel: '',
        budget: undefined,
        cpc: undefined
    }
]

export const initialValues_funnelSetup = {
    projectName: undefined,
    funnelInput: 'Lead',
    funnelOutput: 'customer',
    funnelOutputRate: 10,
    leadTypes: []
}

export const initialValues_funnelStepTwo = {
    setupCosts: initialValues_projectSetupCosts,
    ongoingCosts: initialValues_ongoingCosts

}

const initialValues_expectations = [
    {
        improvNumber: undefined,
        variable: undefined,
        type: undefined,
        recurring: undefined,
        permanent: undefined,
        rampUpMonths: undefined,
        rampUpType: undefined

    }
]



// Forms 

export const forms: Array<FormConfig> = [
    {
        kind: types.UserEventKinds.account,
        title: 'Create New Company',
        components: ['company', 'founders', 'setupCosts'],
        initialValues: {
        companyName: '',
        date: undefined,
        founders: initalValues_founders,
        setupCosts: initialValues_setupCosts
        },
        validation: companyValidation
    },
    // {
    //     kind: types.UserEventKinds.project_product_creation,
    //     title: 'Create a new product to sell',
    //     validation: projectProductCreationValidation,
    //     components: ['project', 'projectCosts', 'product', 'productComponents'],
    //     initialValues: {
    //         date: undefined,
    //         duration: undefined,
    //         durationFrequency: undefined,
    //         designPercentage: undefined,
    //         buildPercentage: undefined,
    //         projectCosts: initialValues_setupCosts,
    //         productName: '', 
    //         price: undefined,
    //         productComponents: initialValues_productComponent
    //     }
    // },
    // No longer in use after funnel but might need code for the funnel edit form
    // {
    //     kind: types.UserEventKinds.project_marketingSiteCreation,
    //     title: 'Create a marketing site',
    //     validation: projectMarketingSiteValidation,
    //     components: ['project', 'marketingWebsite','projectCosts', 'ongoingCosts'],
    //     initialValues: {
    //         date: undefined,
    //         duration: undefined,
    //         durationFrequency: undefined,
    //         designPercentage: undefined,
    //         buildPercentage: undefined,
    //         traffic: undefined,
    //         projectCosts: initialValues_setupCosts,
    //         ongoingCosts: initialValues_ongoingCosts
    //     }
    // },
    {
        kind: types.UserEventKinds.project_marketingCampaign,
        title: 'Configure Marketing Campaign',
        menuText: 'Marketing Campaign',
        validation: projectMarketingCampaignValidation,
        components: ['project', 'marketingProjectConfig'],
        initialValues: {
            date: undefined,
            duration: undefined,
            durationFrequency: undefined,
            designPercentage: undefined,
            buildPercentage: undefined,
            marketingProjectConfig: initialValues_marketingProjectConfig
            
        }
    },
    {
        kind: types.UserEventKinds.oneOffExpense,
        title: 'Add One Off Expense',
        menuText: 'One Off Expense',
        validation: oneOffCostValidation,
        components: ['oneOffExpense'],
        initialValues: {
            oneOffExpense: initialValues_oneOffCost
        }
    },
    {
        kind: types.UserEventKinds.recurringExpense,
        title: 'Add Recurring Expense',
        menuText: 'Recurring Expense',
        validation: recurringCostValidation,
        components: ['recurringCosts'],
        initialValues: {
            recurringCosts: initialValues_ongoingCosts
        }
    },
    {
        kind: types.UserEventKinds.genericEmployee,
        title: 'Add a New Employee',
        menuText: 'Add Employee',
        validation: employeeValidation,
        components: ['genericEmployee', 'permanentExpectations'],
        initialValues: { 
            date: undefined,
            compensation: undefined,
            department: undefined,
            permanentExpectations: [initialValues_permanentExpectations]
        }
    },
    {
        kind: types.UserEventKinds.project_generic,
        title: 'Create new project',
        menuText: 'Create Project',
        validation: genericProjectValidation,
        components: ['project', 'projectCosts', 'ongoingCosts', 'projectExpectations'],
        initialValues: {
            projectName: undefined,
            date: undefined,
            duration: undefined,
            durationFrequency: undefined,
            projectCosts: initialValues_projectSetupCosts,
            ongoingCosts: initialValues_ongoingCosts,
            projectExpectations: initialValues_expectations
        }
    }
    
]

interface OnboardingForm {
    title: string;
    subtitle?: String;
    options: Array<OptionSettings>
}

export const onboardingSteps: Array<OnboardingForm>  = [
    {
        title: `Tell me about your company`,
        options: [
            {
                name: 'labelCompanyName',
                label: `What's the name of your Company?`,
                input: 'label',
                size: types.FieldSize.twelve
            },
            {
                name: "companyName",
                label: "Company Name",
                input: 'text',
                required: true,
                size: types.FieldSize.six,
            },
            {
                name: 'labelCompanyDate',
                label: `What date will your company be founded?`,
                input: 'label',
                size: types.FieldSize.twelve
            },
            {
                name: "date",
                label: "Founded Date",
                input: 'date',
                required: true,
                size: types.FieldSize.six,
            }
        ]
    },
    {
        title: 'Tell me about your founding team',
        options: []
    },
    {
        title: 'What costs are involved in founding your company?',
        subtitle: 'This would be things like your legal and accounting costs.',
        options: []
    },
    {
        title: `Tell me about your product`,
        options: [
            {
                name: 'labelProductname',
                label: `What's the name of the product you are building?`,
                input: 'label',
                size: types.FieldSize.twelve
            },
            {
                name: "productName",
                label: "Product Name",
                input: 'text',
                required: true,
                size: types.FieldSize.six,
            },
            {
                name: 'labelProductDate',
                label: `When are you planning to start building this product?`,
                input: 'label',
                size: types.FieldSize.twelve
            },
            {
                name: "date",
                label: "Start Date",
                input: 'date',
                required: true,
                size: types.FieldSize.six,
            },
            {
                name: 'labelProjectDuration',
                label: `How long will it take to build this product?`,
                input: 'label',
                size: types.FieldSize.twelve
            },
            {
                name: 'duration',
                label: 'Project Duration',
                input: 'hours',
                required: true,
                size: types.FieldSize.six
            },
            {
                name: 'durationFrequency',
                label: 'Duration Type',
                input: 'select',
                options: types.ProjectDurationFrequency,
                placeholder: 'weeks',
                required: true,
                size: types.FieldSize.six
            },
            {
                name: 'labelProjectCosts',
                label: `What costs are involved in making this product?`,
                input: 'label',
                size: types.FieldSize.twelve
            },
            {
                name: 'labelProjectPrice',
                label: `How much will you sell the product for?`,
                input: 'label',
                size: types.FieldSize.twelve
            },
            {
                name: 'price',
                label: 'Price',
                input: 'money',
                required: true,
                size: types.FieldSize.four
            },
            {
                name: 'labelProjectComponets',
                label: `What does it take to make an individual product?`,
                input: 'label',
                size: types.FieldSize.twelve
            },
        ]
    },
    {
        title: 'How are you planning to sell initially?',
        subtitle: 'Red options are coming soon. If you would like us to build one sooner than others click on the option to vote.',
        options: []
    },
    {
        title: `Create a Sales Funnel: 2 of 3`,
        options: [
            {
                name: 'labelFunnelDate',
                label: `When are you going to start setting up this funnel?`,
                input: 'label',
                size: types.FieldSize.twelve
            },
            {
                name: "date",
                label: "Start Date",
                input: 'date',
                required: true,
                size: types.FieldSize.six,
            },
            {
                name: 'labelFunnelProjectDuration',
                label: `How long will it take to set it up?`,
                input: 'label',
                size: types.FieldSize.twelve
            },
            {
                name: 'duration',
                label: 'Duration Amount',
                input: 'hours',
                required: true,
                size: types.FieldSize.six
            },
            {
                name: 'durationFrequency',
                label: 'Duration Type',
                input: 'select',
                options: types.ProjectDurationFrequency,
                placeholder: 'weeks',
                required: true,
                size: types.FieldSize.six
            },
            {
                name: 'labelProjectCosts',
                label: `What costs are involved in setting up the funnel`,
                input: 'label',
                size: types.FieldSize.twelve
            },
            {
                name: 'labelOngoingCosts',
                label: `What ongoing costs are involved in maintaining the funnel?`,
                helperText: `For example, if this was a marketing website this might include things like "web hosting" or your "domain name". This would not include the employees who manage it`,
                input: 'label',
                size: types.FieldSize.twelve
            }
        ]
    }
]

export const domains: Array<DomainConfig> = [
    {
        kind: 'company',
        title: 'Company Details',
        type: 'options',
        options: [
            {
                name: "companyName",
                label: "Company Name",
                input: 'text',
                required: true,
                size: types.FieldSize.six,
            },
            {
                name: "date",
                label: "Founded Date",
                input: 'date',
                required: true,
                size: types.FieldSize.six,
            }
        ]
    },
    {
        kind: 'projectExpectations',
        title: 'New Expectation',
        type: 'array',
        arrayTitle: 'Set an Expectation',
        options: [
            {
                name: 'lblImpact',
                label: `What impact do you expect this project to make?`,
                input: 'label',
                size: types.FieldSize.twelve        
            },
            {
                name: 'type',
                label: 'Type of Improvement',
                input: 'select',
                options: types.ExpectationType,
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'improvNumber',
                label: 'Amount of Improvement',
                input: 'number',
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'variable',
                label: 'Area of Improvement',
                input: 'select',
                options: 'improvVariables',
                required: true,
                size: types.FieldSize.four
            },
            {
                name: 'lblRecurring',
                label: 'How often do you expect to see this result?',
                input: 'label',
                size: types.FieldSize.twelve        
            },
            {
                name: 'recurring',
                label: 'Result Frequency',
                input: 'select',
                options: types.ExpectationRecurring,
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'lblPermanent',
                label: 'Will this keep happening after the project has finished?',
                input: 'label',
                size: types.FieldSize.twelve        
            },
            {
                name: 'permanent',
                label: 'Permanent',
                input: 'select',
                options: types.ExpectationPermanence,
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'lblRampUp',
                label: 'How long do you expect until you see this result?',
                input: 'label',
                size: types.FieldSize.twelve        
            },
            {
                name: 'rampUpMonths',
                label: 'Number of Months',
                input: 'number',
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'rampUpType',
                label: 'Ramp up Type',
                input: 'select',
                options: types.ExpectationRampUp,
                required: true,
                size: types.FieldSize.four,
            },

        ]
    },
    {
        kind: 'permanentExpectations',
        title: 'New Expectation',
        type: 'array',
        arrayTitle: 'Set an Expectation',
        options: [
            {
                name: 'lblImpact',
                label: `What impact do you expect this employee to make every month?`,
                input: 'label',
                size: types.FieldSize.twelve        
            },
            {
                name: 'type',
                label: 'Type of Improvement',
                input: 'select',
                options: types.ExpectationType,
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'improvNumber',
                label: 'Amount of Improvement',
                input: 'number',
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'variable',
                label: 'Area of Improvement',
                input: 'select',
                options: 'improvVariables',
                required: true,
                size: types.FieldSize.four
            },
            {
                name: 'lblRampUp',
                label: 'How long do you expect until the employee delivers this result?',
                input: 'label',
                size: types.FieldSize.twelve        
            },
            {
                name: 'rampUpMonths',
                label: 'Number of Months',
                input: 'number',
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'rampUpType',
                label: 'Ramp up Type',
                input: 'select',
                options: types.ExpectationRampUp,
                required: true,
                size: types.FieldSize.four,
            },

        ]
    },
    {
        kind: 'founders',
        title: 'Founder',
        type: 'array',
        arrayTitle: 'Add Founders',
        options: [
            {
                name: 'name',
                label: 'Name',
                input: 'text',
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'compensation',
                label: 'Monthly Wage',
                input: 'money',
                required: false,
                size: types.FieldSize.four,
            },
            {
                name: 'cashInvestment',
                label: 'Cash Investment',
                input: 'money',
                required: false,
                size: types.FieldSize.four,
            }
        ]
    },
    {
        kind: 'setupCosts',
        type: 'array',
        arrayTitle: 'Add Setup Costs',
        title: 'Setup Cost',
        options: [
            {
                name: 'expenseSetupName',
                label: 'Name',
                input: 'text',
                required: true,
                size: types.FieldSize.six,
            },
            {
                name: 'amount',
                label: 'Amount',
                input: 'money',
                required: true,
                size: types.FieldSize.six,
            }
        ]  
    },
    {
        kind: 'oneOffExpense',
        type: 'array',
        arrayTitle: '',
        title: 'New One Off Expense',
        options: [
            {
                name: 'date',
                label: 'Date',
                input: 'date',
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'expenseSetupName',
                label: 'Name',
                input: 'text',
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'amount',
                label: 'Amount',
                input: 'money',
                required: true,
                size: types.FieldSize.four,
            }
        ]  
    },
    {
        kind: 'projectCosts',
        type: 'array',
        arrayTitle: 'Project Costs',
        title: 'Item',
        options: [
            {
                name: 'expenseSetupName',
                label: 'Name',
                input: 'text',
                required: true,
                size: types.FieldSize.six,
            },
            {
                name: 'amount',
                label: 'Amount',
                input: 'money',
                required: true,
                size: types.FieldSize.six,
            }
        ]  
    },
    {
        kind: 'recurringCosts',
        type: 'array',
        arrayTitle: '',
        title: 'New Recurring Expense',
        options: [
            {
                name: 'date',
                label: 'Start Date',
                input: 'date',
                required: true,
                size: types.FieldSize.six,
            },
            {
                name: 'expenseOngoingName',
                label: 'Name',
                input: 'text',
                required: true,
                size: types.FieldSize.six,
            },
            {
                name: 'amount',
                label: 'Amount',
                input: 'money',
                required: true,
                size: types.FieldSize.six,
            },
            {
                name: 'recurring',
                label: 'Recurring',
                input: 'select',
                required: true,
                options: types.RecurringExpense,
                size: types.FieldSize.six,
            }
        ]  
    },
    {
        kind: 'genericEmployee',
        type: 'options',
        title: 'Hire New Employee',
        options: [
            {
                name: 'date',
                label: 'Hire Date',
                input: 'date',
                required: true,
                size: types.FieldSize.four
            },
            {
                name: 'department',
                label: 'Department',
                input: 'select',
                options: types.DepartmentType,
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'compensation',
                label: 'Monthly Compensation',
                input: 'money',
                required: true,
                size: types.FieldSize.four,
            }
        ]  
    },
    {
        kind: 'ongoingCosts',
        type: 'array',
        arrayTitle: 'Ongoing Costs',
        title: 'Ongoing Cost',
        options: [
            {
                name: 'expenseOngoingName',
                label: 'Name',
                input: 'text',
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'amount',
                label: 'Amount',
                input: 'money',
                required: true,
                size: types.FieldSize.four,
            },
            {
                name: 'recurring',
                label: 'Recurring',
                input: 'select',
                required: true,
                options: types.RecurringExpense,
                size: types.FieldSize.four,
            }
        ]  
    },
    {
        kind: 'project',
        type: 'options',
        title: 'Project Details',
        options: [
            {
                name: "projectName",
                label: "Project Name",
                input: 'text',
                required: true,
                size: types.FieldSize.six,
            },
            {
                name: 'date',
                label: 'Start Date',
                input: 'date',
                required: true,
                size: types.FieldSize.six
            },
            {
                name: 'duration',
                label: 'Project Duration',
                input: 'hours',
                required: true,
                size: types.FieldSize.six
            },
            {
                name: 'durationFrequency',
                label: 'Duration Type',
                input: 'select',
                options: types.ProjectDurationFrequency,
                placeholder: 'weeks',
                required: true,
                size: types.FieldSize.six
            }
        ]
    },
    {
        kind: 'product',
        type: 'options',
        title: 'New Product Settings',
        options: [
            {
                name: 'productName',
                label: 'Product Name',
                input: 'text',
                required: true,
                size: types.FieldSize.four
            },
            {
                name: 'price',
                label: 'Sale Price',
                input: 'money',
                required: true,
                size: types.FieldSize.four
            }
        ]
    },
    {
        kind: 'productComponents',
        type: 'array',
        title: 'Product Component',
        options: [
            {
                name: 'componentName',
                label: 'Component Name',
                input: 'text',
                required: true,
                size: types.FieldSize.four
            },
            {
                name: 'unitVolume',
                label: '# of items',
                input: 'number',
                required: true,
                size: types.FieldSize.four
            },
            {
                name: 'unitCost',
                label: 'Cost per item',
                input: 'money',
                required: true,
                size: types.FieldSize.four
            },
        ]
    },
    {
        kind: 'avgRevenueComponents',
        type: 'array',
        title: 'Component for each purchase',
        options: [
            {
                name: 'componentName',
                label: 'Component Name',
                input: 'text',
                required: true,
                size: types.FieldSize.four
            },
            {
                name: 'componentType',
                label: 'Type per Purchase',
                input: 'select',
                options: types.ComponentType,
                required: true,
                size: types.FieldSize.four
            },
            {
                name: 'unitCost',
                label: 'Amount',
                input: 'number',
                required: true,
                size: types.FieldSize.four
            },
        ]
    },
    {
        kind: 'marketingProjectConfig',
        type: 'array',
        title: 'Campaign Budget',
        options: [
            {
                name: 'channel',
                label: 'Channel',
                input: 'select',
                options: types.MarketingChannels,
                required: true,
                size: types.FieldSize.four
            },
            {
                name: 'budget',
                label: 'Budget per month',
                input: 'money',
                required: true,
                size: types.FieldSize.four
            },
            {
                name: 'cpc',
                label: 'Cost Per Click',
                input: 'money',
                required: true,
                size: types.FieldSize.four
            },
        ]
    },
    {
        kind: 'marketingWebsite',
        type: 'options',
        title: 'Starting Funnel Variables',
        options: [
            {
                name: 'traffic',
                label: 'Starting Traffic',
                input: 'number',
                required: true,
                size: types.FieldSize.three
            },
            {
                name: 'percent_viewProduct',
                label: 'Product Views',
                input: 'percentage',
                required: true,
                size: types.FieldSize.three
            },
            {
                name: 'percent_addToCart',
                label: 'Add to Carts',
                input: 'percentage',
                required: true,
                size: types.FieldSize.three
            },
            {
                name: 'percent_purchase',
                label: 'Purchases',
                input: 'percentage',
                required: true,
                size: types.FieldSize.three
            }
        ]
    }

]

