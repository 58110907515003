import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';

import { hasLoggedInUser } from '../../stitch/authentication';
import { getEvents, restoreSession, getResults, addResults, updateResults, startLoading, stopLoading, getUserSettings} from '../../store/appState/actions/actionCreators';
import { AppState, ActionTypes } from '../../store/store';

import classes from './Layout.module.css';
import { UserEventKinds } from '../../classes/types';
import MainAppBar from '../../components/MainAppBar/MainAppBar';
import ThenaButton from '../../components/ThenaButton/ThenaButton';
import AddEventMenuDialog from '../../components/AddEventMenuDialog/AddEventMenuDialog';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import RegisterUser from '../../components/RegisterUser/RegisterUser';
import LoginDialog from '../../components/LoginDialog/LoginDialog';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import { forms } from '../../components/FormGenerator/formConfigs';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import UserEventTimelinePage from '../../pages/UserEventTimelinePage/UserEventTimelinePage';
import ProjectionsPageBasic from '../../pages/ProjectionsPage/ProjectionsPageBasic';
import OnboardingFlowPage from '../../pages/OnboardingFlowPage/OnboardingFlowPage';
import OnboardingSalesFunnelStepOneDialog from '../../components/OnboardingDialogs/OnboardingSalesFunnelStepOneDialog';
import VerifyEmailDialog from '../../components/VerifyEmailDialog/VerifyEmailDialog';
import Drawer from '../../components/MainAppBar/Drawer/Drawer';
import GridPage from '../../pages/GridPage/GridPage';
import AnalyticsPage from '../../pages/AnalyticsPage/AnalyticsPage';

interface State {
    eventMenuOpen: boolean;
    formOpen: boolean;
    loginOpen: boolean;
    formSelection: UserEventKinds;
    menuSelection: string;
    drawerState: boolean;
}

interface StateProps {
    loggedin: boolean;
    loading: boolean;
    onboardingComplete: boolean;
    emailVerified: boolean;

}

interface DispatchProps {
    loggedin: boolean;
    loading: boolean;
    restoreSession: () => void;
    getEvents: (props: any) => void;
    getResults: () => void;
    addResults: (values: any) => void;
    updateResults: (values: any) => void;
    loadingStart: () => void;
    loadingStop: () => void;
    getUserSettings: () => void;
}

type Props = StateProps & DispatchProps & RouteComponentProps;

class Layout extends React.Component<Props, State> {
    state: State = {
        eventMenuOpen: false,
        formOpen: false,
        loginOpen: false,
        formSelection: UserEventKinds.account,
        menuSelection: 'userTimeline',
        drawerState: false
    }

    async handleInitialLoad()  {
        if (hasLoggedInUser()) {
            await this.props.restoreSession();
            await this.props.getResults();
            await this.props.getUserSettings();
            await this.props.getEvents(this.props.history);
            if (!this.props.emailVerified) {
                this.props.history.push('/please-verify-your-email')
            }
        } else {
            this.props.history.push('/login')
        }
        this.props.loadingStop()
    }

    componentDidMount() {
        this.handleInitialLoad();
    }

    handleEventButtonClicked = () => {
        this.setState({
            eventMenuOpen: true
        })
    }

    handleEventOpenOption = () => {
        this.setState({
            eventMenuOpen: false,
            formOpen: true
        })
    }

    handleMenuClose = () => {
        this.setState({
            eventMenuOpen: false
        })
    }

    menuClick = (selection: string) => {
        this.setState({
            menuSelection: selection
        })
    }

    toggleDrawer = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                drawerState: !prevState.drawerState
            }
        });
    }

    menuOptions = forms.filter(form => form.menuText);

    addEventMenu = () => {
        if (this.state.eventMenuOpen) {
            return <AddEventMenuDialog
                state={this.state.eventMenuOpen}
                close={this.handleMenuClose} />
        }
    }

    render() {
        return (
            <React.Fragment>
                <MainAppBar toggleDrawer={this.toggleDrawer} />
                <main className={classes.content}>
                    {this.addEventMenu()}
                    {this.props.children}
                    <Route path='/login' component={LoginDialog} />
                    <Route path='/confirmEmail' params component={RegisterUser} />
                    <Route path="/testing" render={() => <OnboardingSalesFunnelStepOneDialog 
                        currentStep={3} 
                        close={()=>{}} 
                        next={()=>{}} 
                        back={()=>{}} 
                        initialValues={{funnelInput: 'lead', funnelOutput: 'customer'}}/>} />
                    <PrivateRoute path="/please-verify-your-email" component={VerifyEmailDialog} />
                    <PrivateRoute path='/projections' component={ProjectionsPageBasic} /> 
                    <PrivateRoute path='/grid' component={GridPage} />    
                    <PrivateRoute path='/analytics' component={AnalyticsPage} /> 
                    <PrivateRoute path='/setup' component={OnboardingFlowPage} />
                    <PrivateRoute path='/' exact component={UserEventTimelinePage}/>
                    <PrivateRoute path='/events' component={UserEventTimelinePage}/>
                    
                    
                </main>
                <div className={classes.fab}>
                    <ThenaButton click={this.handleEventButtonClicked}/>
                </div>
                <Drawer state={this.state.drawerState} toggleDrawer={this.toggleDrawer}/>
                {/* 
                //@ts-ignore */}
                <LoadingDialog />
            </React.Fragment>
        )
    }
}

interface LinkDispatchProps {
    restoreSession: () => void;
    getEvents: (history: any) => void;
    getResults: () => void;
    addResults: (values: any) => void;
    updateResults: (values: any) => void;
    loadingStart: () => void;
    loadingStop: () => void;
    getUserSettings: () => void;
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        loggedin: state.appState.loggedin,
        loading: state.appState.loading,
        onboardingComplete: state.appState.userSettings.onboardingComplete,
        emailVerified: state.appState.userSettings.emailVerified
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>, ownProps: any): LinkDispatchProps => {
    return {
        restoreSession: bindActionCreators(restoreSession, dispatch),
        getEvents: bindActionCreators(getEvents, dispatch),
        getResults: bindActionCreators(getResults, dispatch),
        addResults: bindActionCreators(addResults, dispatch),
        updateResults: bindActionCreators(updateResults, dispatch),
        loadingStart: bindActionCreators(startLoading, dispatch),
        loadingStop: bindActionCreators(stopLoading, dispatch),
        getUserSettings: bindActionCreators(getUserSettings, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));