import React from 'react';
import { AppBar, Toolbar, IconButton, Icon, Typography } from '@material-ui/core';

interface MyProps {
    toggleDrawer: () => void;
}

const mainAppBar: React.FC<MyProps> = ({ toggleDrawer }) => {

    return (
        <AppBar 
            position="fixed" 
            style={{backgroundColor: '#C9EAF8'}}
        >
            <Toolbar>
                <IconButton onClick={toggleDrawer}>
                    <Icon>
                        menu
                    </Icon>
                </IconButton>
                <Typography variant="h6" color="textPrimary" style={{marginLeft: '1%'}}>Thena OS</Typography>
                {/* <img src={logo} width="75px" height="75px"/>
                <NavLink 
                    style={{ textDecoration: 'none' }} 
                    to='/events'>
                    <Button 
                    >
                        Events
                    </Button>
                </NavLink>
                <NavLink 
                    style={{ textDecoration: 'none' }} 
                    to='/projections'>
                    <Button 
                    >
                        Projections
                    </Button>
                </NavLink>
                {runCalcButton()}
                <Button 
                    onClick={toggleDrawer}
                >Drawer</Button>
                <span style={{marginLeft: 'auto'}}>
                    <Button 
                        onClick={() => {
                            logout();
                            history.push('/login');
                        }}>
                            Logout
                    </Button>
                </span>          */}
            </Toolbar>
        </AppBar>  
    )
}

export default mainAppBar;