import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import { BasicCompanySettings } from '../../classes/userEvents';
import { Card, CardHeader, CardContent, Typography, Grid, List, ListItem, ListItemIcon, Icon, ListItemText, makeStyles, IconButton, Collapse, Button } from '@material-ui/core';
import ActionOptions from './ActionOptions/ActionOptions';

interface FuncProps {
    event: BasicCompanySettings;
    deleteHandler: (event: BasicCompanySettings) => void;
    history: any;
}

type Props = FuncProps;

const useStyles = makeStyles(theme => ({
    card: {
        margin: 'auto', 
        width: '98%',
        backgroundColor: '#fffcfc',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#ebf7fc',
        marginBottom: '5px'
    },
    cardTitle: {
        marginTop: 'auto', 
        marginBottom: 'auto'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
}))

const CompanyCard: React.FC<Props> = ({event, deleteHandler, history}) => {

    const classes = useStyles();
    
    const [productExpanded, setProductExpanded] = React.useState(false);
    const handleProdExpClicked = () => {
        setProductExpanded(!productExpanded)
    }

    const [costsExpanded, setCoststExpanded] = React.useState(false);
    const handleCostExpClicked = () => {
        setCoststExpanded(!costsExpanded)
    }

    const [foundersExpanded, setFoundExpanded] = React.useState(false);
    const handleFoundExpClicked = () => {
        setFoundExpanded(!foundersExpanded)
    }

    const productCosts = event.costs.filter(cost => cost.recurring === 'perPurchase')
    const companyCosts = event.costs.filter(cost => cost.recurring !== 'perPurchase')
    const recurringText = (recurring: string) => {
        if (recurring === 'monthly') {
            return 'month'
        } else {
            return 'year'
        }
    }

    const titleCreator = (title: string, amount: number, expanded: boolean) => {
        if(expanded){
            return title
        } else {
            return `${title} (${amount})`
        }
    }

    const displayProductCosts = () => {
        if (productCosts.length > 0) {
            return <React.Fragment>
                <Grid item xs={12}><Typography variant="subtitle1"><b>Product/Service Costs</b></Typography></Grid>
                    <List>
                        {productCosts.map(cost => 
                            <ListItem key={cost.expenseName}>
                                <ListItemIcon>
                                    <Icon>
                                        monetization_on
                                    </Icon>
                                </ListItemIcon>
                                <ListItemText>
                                    <b>{cost.expenseName}</b><br/>
                                    <Typography variant="body2"><b>Amount: </b>${numeral(cost.expenseAmount).format('0,0.00')} per purchase</Typography>
                                </ListItemText>
                            </ListItem>)}
                    </List>  
            </React.Fragment>   
        }
    }

    const displayCompanyCosts = () => {
        if (companyCosts.length > 0) {
            return <React.Fragment>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={11} className={classes.cardTitle}><Typography variant="h6">{titleCreator('Costs', companyCosts.length, costsExpanded)}</Typography></Grid> 
                            <Grid item xs={1}>
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: costsExpanded,
                                    })}
                                    onClick={handleCostExpClicked}
                                    aria-expanded={costsExpanded}
                                    aria-label="show more"
                                    >
                                    <Icon>
                                        expand_more
                                    </Icon>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Collapse in={costsExpanded} timeout="auto" unmountOnExit>
                            <Grid container>
                                <List>
                                    {companyCosts.map(cost => 
                                    <ListItem key={cost.expenseName}>
                                        <ListItemIcon>
                                            <Icon>
                                                monetization_on
                                            </Icon>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <b>{cost.expenseName}</b><br/>
                                            <Typography variant="body2"><b>Amount: </b>${numeral(cost.expenseAmount).format('0,0.00')} per {recurringText(cost.recurring)}</Typography>
                                        </ListItemText>
                                    </ListItem>)}
                                </List>   
                            </Grid>
                        </Collapse>
                    </CardContent>
                </Card>
            </React.Fragment>
        }
    }

    return (
        <React.Fragment>
            <CardHeader 
                title={'Founded: ' + _.startCase(event.companyName)} 
                subheader={moment(event.date).format('DD MMMM YYYY')} 
                action={<ActionOptions event={event} deleteHandler={deleteHandler} />}
            />
            <CardContent>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={11} className={classes.cardTitle}><Typography variant="h6">{titleCreator('Founders', event.founders.length, foundersExpanded)}</Typography></Grid> 
                            <Grid item xs={1}>
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: foundersExpanded,
                                    })}
                                    onClick={handleFoundExpClicked}
                                    aria-expanded={foundersExpanded}
                                    aria-label="show more"
                                    >
                                    <Icon>
                                        expand_more
                                    </Icon>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Collapse in={foundersExpanded} timeout="auto" unmountOnExit>
                            <Grid container>
                                <Grid item xs={12}>
                                    <List>
                                        {event.founders.map(founder => 
                                        <ListItem key={founder.name}>
                                            <ListItemIcon>
                                                <Icon>
                                                    account_circle
                                                </Icon>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <b>{founder.name}</b><br/>
                                                <Typography variant="body2"><b>Compensation:</b> ${numeral(founder.compensation).format('0,0.00')} per month</Typography>
                                                <Typography variant="body2"><b>Cash Investment:</b> ${numeral(founder.cashInvestment).format('0,0.00')}</Typography>
                                            </ListItemText>
                                        </ListItem>)}
                                    </List>
                                    
                                </Grid>
                            </Grid>
                        </Collapse>
                    </CardContent>
                </Card>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={11} className={classes.cardTitle}><Typography variant="h6">Product/Service</Typography></Grid> 
                                <Grid item xs={1}>
                                    <IconButton
                                        className={clsx(classes.expand, {
                                            [classes.expandOpen]: productExpanded,
                                        })}
                                        onClick={handleProdExpClicked}
                                        aria-expanded={productExpanded}
                                        aria-label="show more"
                                        >
                                        <Icon>
                                            expand_more
                                        </Icon>
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Collapse in={productExpanded} timeout="auto" unmountOnExit>
                                <Grid container> 
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Icon>
                                                    info
                                                </Icon>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <b>{event.productName}</b><br/>
                                                <Typography variant="body2"><b>Price: </b>${numeral(event.price).format('0,0.00')} | <b>Expected Sales: </b>{event.amountOfSales} per month</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                    {displayProductCosts()}
                                </Grid>
                            </Collapse>
                        </CardContent>    
                    </Card>
                    <Grid item xs={12}>
                        {displayCompanyCosts()}
                    </Grid>
                    <Grid container>
                        <Button 
                            variant="outlined" 
                            color="primary"
                            onClick={() => history.push(`events/edit/${event._id.toString()}/basic-ramp-up`)}
                        >
                            Ramp Up Sales
                        </Button>
                    </Grid>
            </CardContent>
        </React.Fragment>
    )
}

export default CompanyCard;