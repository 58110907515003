import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import { CardHeader, CardContent, Grid, Typography } from '@material-ui/core';
import ActionOptions from './ActionOptions/ActionOptions';
import classes from './UserCards.module.css';
import { EmployeeSettings } from '../../classes/userEvents';

interface Props {
    event: EmployeeSettings;
    deleteHandler: (event: EmployeeSettings) => void;
}

const GenericEmployeeCard: React.FC<Props> = ({event, deleteHandler}) => {

    const expectationDisplay = (expectation: any) => {
        //@ts-ignore
        if (expectation.type === "new" && expectation.rampUpType === "gradually") {
            return <Typography variant="subtitle2" color="textSecondary">Improve {_.capitalize(expectation.variable)} by {Math.round(expectation.improvNumber/expectation.rampUpMonths)} every month until {expectation.improvNumber} per month</Typography>  
        //@ts-ignore
        } else if (expectation.type === "new" && expectation.rampUpType === "gradually" && expectation.rampUpMonths === 0) {
            return <Typography variant="subtitle2" color="textSecondary">Improve {_.capitalize(expectation.variable)} by {expectation.improvNumber} every month</Typography>  
        //@ts-ignore
        } else if (expectation.type === "new" && expectation.rampUpType === "atOnce") {
            return <Typography variant="subtitle2" color="textSecondary">Improve {_.capitalize(expectation.variable)} by {expectation.improvNumber} every month after {expectation.rampUpMonths} months </Typography>  
        //@ts-ignore
        } else if (expectation.type === "percent_improvement" && expectation.rampUpType === "gradually") {
            return <Typography variant="subtitle2" color="textSecondary">Improve {_.capitalize(expectation.variable)} Conversions by {Math.round(expectation.improvNumber/expectation.rampUpMonths)}% every month until {expectation.improvNumber}% </Typography>  
        //@ts-ignore
        } else if (expectation.type === "percent_improvement" && expectation.rampUpType === "gradually" && expectation.rampUpMonths === 0) {
            return <Typography variant="subtitle2" color="textSecondary">Improve {_.capitalize(expectation.variable)} Conversions by {expectation.improvNumber}% every month</Typography>  
        //@ts-ignore
        } else if (expectation.type === "new" && expectation.rampUpType === "atOnce") {
            return <Typography variant="subtitle2" color="textSecondary">Improve {_.capitalize(expectation.variable)} Conversions by {expectation.improvNumber}% every month after {expectation.rampUpMonths} months </Typography>  
        }
    }

    return (
        <React.Fragment>
            <CardHeader 
                title={_.startCase(event.department) + ' Employee Hired'} 
                subheader={moment(event.date).format('DD MMMM YYYY')}
                action={<ActionOptions event={event} deleteHandler={deleteHandler} />}
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                    <Typography variant="body1"><b>Department: </b>{_.capitalize(event.department)}</Typography>  
                    </Grid>
                    <Typography variant="body1"><b>Compensation: </b>${numeral(event.compensation).format('0,0.00')}</Typography>
                    </Grid>
                    {event.permanentExpectations.map(expectation => <Grid container>
                        <Grid item xs={12}>
                        {expectationDisplay(expectation)}
                        </Grid>
                    </Grid>)}
            </CardContent>
        </React.Fragment>
    )

}

export default GenericEmployeeCard;