import React from 'react'
import _ from 'lodash'
import { VictoryLine, VictoryChart } from 'victory';

interface FuncProps {
    projections: any;
}


const RevExpLineGraph: React.FC<FuncProps> = ({ projections }) => {
    
    const revenue: Array<any> = []
    const expenses: Array<any> = []

    projections.forEach((month: any, i: number) => {
        const revEntry = {x: i, y: month.revenue.sum}
        const expEntry = {x: i, y: month.expenses.sum}
        revenue.push(revEntry);
        expenses.push(expEntry);
    });

    return (

                <VictoryChart height={450}>
                <VictoryLine
                    data={expenses} 
                    style={{ data: { stroke: "#e57373" } }}
                    animate={{
                        duration: 500, 
                        onEnter: {
                            duration: 500, 
                            before: () => ({y: 0})
                        }
                    }}
                    />
                <VictoryLine 
                    data={revenue} 
                    style={{ data: { stroke: "#81c784" } }}
                    animate={{
                        duration: 500, 
                        onEnter: {
                            duration: 500, 
                            before: () => ({y: 0})
                        }
                    }}
                />
        </VictoryChart>
        
       
    )
}

export default RevExpLineGraph;
