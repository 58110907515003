import React from 'react';
import moment from 'moment';
import numeral from 'numeral';

import { CardHeader, CardContent, Grid, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails } from '@material-ui/core';
import ActionOptions from './ActionOptions/ActionOptions';
import classes from './UserCards.module.css';
import { UserRecurringExpenseSettings } from '../../classes/userEvents';

interface Props {
    event: UserRecurringExpenseSettings;
    deleteHandler: (event: UserRecurringExpenseSettings) => void;
}

const RecurringCostCard: React.FC<Props> = ({event, deleteHandler}) => {

    return (
        <React.Fragment>
            <CardHeader 
                title={'Recurring Expenses'} 
                subheader={moment(event.date).format('DD MMMM YYYY')}
                action={<ActionOptions event={event} deleteHandler={deleteHandler} />}
            />
            <CardContent>
                <Grid container>
                    {event.recurringCosts.map(cost => 
                    <Grid item xs={12} key={cost.expenseOngoingName + cost.date.toString()}>
                         <ExpansionPanel className={classes.arrayCard}>
                                <ExpansionPanelSummary>
                                    <Typography variant="body1"><b>Name: </b>{cost.expenseOngoingName}</Typography>  
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="body1"><b>Amount: </b>${numeral(cost.amount).format('0,0.00')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1"><b>Recurring: </b>{cost.recurring}</Typography>
                                    </Grid>
                                </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                    </Grid>)}
                </Grid>
            </CardContent>
        </React.Fragment>
    )

}

export default RecurringCostCard;