import React from 'react';
import { DialogTitle, DialogContent } from '@material-ui/core';

interface FuncProps {
    email: string;
}

const Email: React.FC<FuncProps> = ({email}) => (
    <React.Fragment>
        <DialogTitle>Please check your email</DialogTitle>
            <DialogContent>
                <div>
                    We have sent an email to {email}. Please open and confirm your email to complete registration.
                </div>
            </DialogContent>
        </React.Fragment>
)

export default Email;