import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, Button, Grid, Avatar, Typography, IconButton, Icon } from '@material-ui/core';
import { UserEventKinds } from '../../classes/types';
import logo from '../../assets/thena_icon.svg';
import {checkUserMobile} from '../../helpers/helpers'


interface Props extends RouteComponentProps {
    state: boolean
    close: () => void;
}

const AddEventMenuDialog: React.FC<Props> = ({state, history, close}) => {

    const basicMenuOptions = [
        {
            kind: UserEventKinds.project_generic,
            menuText: 'Create Project'
        },
        {
            kind: UserEventKinds.genericEmployee,
            menuText: 'Add an Employee'
        },
        {
            kind: UserEventKinds.oneOffExpense,
            menuText: 'Add One Off Expenses'
        },
        {
            kind: UserEventKinds.recurringExpense,
            menuText: 'Add Recurring Expense'
        }
    ]

    const AdvancedMenuOptions = [
        {
            kind: UserEventKinds.project_product_creation,
            menuText: 'Create a Product'
        },
        {
            kind: UserEventKinds.project_sales_funnel,
            menuText: 'Create a Sales Funnel'
        }
    ]

    const supportButton = <Grid item xs={12} sm={6}>
        <a href="mailto:support@thena.app">
            <Button 
                color="secondary"
                key='support'
                onClick={() => {
                    close()
                }}
            >Contact Support</Button>
        </a>
    </Grid>

    const basicButtons = basicMenuOptions.map(option => <Grid key={option.kind} item xs={12} sm={6} md={6}><Button 
                color="primary"
                key={option.kind}
                onClick={() => {
                    history.push(`/events/create/${option.kind}`)
                    close()
                }}
            >{option.menuText}</Button></Grid>)

    const advancedButtons = AdvancedMenuOptions.map(option => <Grid key={option.kind} item xs={12} sm={6} md={6}><Button 
        color="primary"
        key={option.kind}
        onClick={() => {
            history.push(`/events/create/${option.kind}`)
            close()
        }}
    >{option.menuText}</Button></Grid>)

    return (
        <Dialog 
            open={state} 
            onClose={close}
            fullScreen={checkUserMobile()}
            id="EventAddMenu">
            <DialogTitle id="eventMenu">
                <Grid container>
                    <Grid item xs={2}>
                        <Avatar alt="Thena" src={logo} style={{boxShadow: "1px 1px 0.5px grey"}}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h6">What do you want to do?</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            onClick={close}
                        >
                            <Icon>close</Icon>
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    {basicButtons}
                </Grid>
                {/* <Grid container>
                    <Grid item xs={12}><Typography style={{marginLeft: '10px'}} variant="subtitle1"><b>Advanced</b></Typography></Grid>
                    {advancedButtons}
                </Grid> */}
                <Grid container>
                    <Grid item xs={12}><Typography style={{marginLeft: '10px'}} variant="subtitle1"><b>Support</b></Typography></Grid>
                    {supportButton}
                </Grid>
                
            </DialogContent>
        </Dialog>
    )

}

export default withRouter(AddEventMenuDialog)