import React from 'react';

import { Fab } from '@material-ui/core';

import logo from '../../assets/thena_icon.svg';

interface Props {
    click: () => void
}

const ThenaButton: React.FC<Props> = ({click}) => {
    
        return (
            <Fab 
                style={{
                    backgroundColor: "white",
                    height: "70px",
                    width: "70px",
                    position: 'relative'
                }}
                color="primary"
                aria-label="eventAddButton"
                onClick={click}
            >
                <img src={logo} width="65px" height="65px"/>
            </Fab>
        )
}

export default ThenaButton;