import React from 'react';
import { Typography, Button, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import {inputArrayHelper} from '../../FormGenerator/fields';
import { DomainConfig } from '../../FormGenerator/formConfigs';
import { FieldSize, RecurringExpense } from '../../../classes/types';
import { Field } from 'formik';

interface Props {
    remove: (index: number) => void;
    add: (object: any) => void;
    array: string;
    index: number;
    propsExpansion: number;
    values: any;
    pannelChoice: (panel: number) => void;
}


const ArrayCostItemCard: React.FC<Props> = ({remove, add, array, index, propsExpansion, pannelChoice, values}) => {
    
    const [expenseType, setExpenseType] = React.useState('')
    const title = () => {

        if(values[array][index].name) {
            return values[array][index].name
        } if (values[array][index].componentName) {
            return values[array][index].componentName

        } if (values[array][index].expenseOngoingName) {
            return values[array][index].expenseOngoingName

        } if (values[array][index].expenseSetupName) {
            return values[array][index].expenseSetupName

        } if (values[array][index].channel) {
            return values[array][index].channel

        } else {
            return "New Cost"
        }
    }

    const oneOff = () => (
        <Grid container>
            {inputArrayHelper({
                name: 'expenseSetupName',
                label: 'Name',
                input: 'text',
                required: true,
                size: FieldSize.six,
            }, array, index )}
            {inputArrayHelper({
                name: 'amount',
                label: 'Amount',
                input: 'money',
                required: true,
                size: FieldSize.six,
            }, array, index )}
        </Grid>
    )

    const recurring = () => (
        <Grid container>
            {inputArrayHelper({
                name: 'expenseOngoingName',
                label: 'Name',
                input: 'text',
                required: true,
                size: FieldSize.four,
            }, array, index )}
            {inputArrayHelper({
                name: 'amount',
                label: 'Amount',
                input: 'money',
                required: true,
                size: FieldSize.four,
            }, array, index )}
            {inputArrayHelper({
                name: 'recurring',
                label: 'Recurring',
                input: 'select',
                required: true,
                options: RecurringExpense,
                size: FieldSize.four,
            }, array, index )}
        </Grid>
    )

    const handleOptionSelected = (option: string) => {
        if (option === 'oneOff' && expenseType !== 'oneOff') {
            setExpenseType(option)
        } else if (option === 'oneOff' && expenseType === 'oneOff') {
            setExpenseType('')
        } else if (option === 'recurring' && expenseType !== 'recurring') {
            setExpenseType('recurring')
        } else if (option === 'recurring' && expenseType === 'recurring') {
            setExpenseType('')
        }
    }

    const optionDisplay = () => {
        if (expenseType === 'oneOff') {
            return oneOff()
        } else if (expenseType === 'recurring') {
            return recurring()
        } else {
            return null
        }
    }
    const backgroundColor = (option: string) => {
        if (option === expenseType) {
            return {backgroundColor: '#B3E5FC'}
        } else {
            return {}
        }
    }

    return (
        <ExpansionPanel 
            expanded={propsExpansion === index} 
            onChange={() => pannelChoice(index)}
        >
            <ExpansionPanelSummary>
                <Typography variant="subtitle1"><b>{title()}</b></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container>
                    <Grid item xs={12}><Typography variant="subtitle1">What kind of cost is this?</Typography></Grid>
                    <Grid item xs={6}>
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            style={backgroundColor('oneOff')} 
                            onClick={() => {
                                 handleOptionSelected('oneOff')
                                 values[array].recurringStatus = false
                                }}>One Off</Button>
                        <Button 
                        variant="outlined" 
                        color="primary" 
                        style={backgroundColor('recurring')} 
                        onClick={() => {
                            handleOptionSelected('recurring')
                            if (!values[array].recurringStatus) {
                                values[array].recurringStatus = true
                            } else {
                                values[array].recurringStatus = !values[array].recurringStatus
                            }
                        }}>Recurring</Button>
                    </Grid>
                    {optionDisplay()}
                    <Grid item xs={12} />
                    <Grid item xs={6}>
                        <Button 
                            onClick={() => {
                                remove(index)
                                pannelChoice(index-1)
                            }}
                            color="secondary"
                            size="small">
                            Remove
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                            color="primary"
                            onClick={() => {
                                add({})
                                pannelChoice(values[array].length)
                            }}
                            size="small">
                            Add Another
                        </Button>
                    </Grid>  
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>)
}

export default ArrayCostItemCard;