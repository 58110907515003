import {app} from '../database/mongodb';
import { bugsnagClient } from '../bugsnag/bugsnag';
import { store } from '../store/store';
import { updateResults, addResults, startLoading, stopLoading } from '../store/appState/actions/actionCreators';

export const generateProjections = async () => {

    const events = store.getState().appState.events;
    store.dispatch(startLoading())
    try {
        let result: any = {};
        if (process.env.NODE_ENV === 'development') {
            result = await app.callFunction('generateProjectionsDev', [JSON.stringify(events)]);
        } else {
            result = await app.callFunction('generateProjections', [JSON.stringify(events)]);
        }

        if (process.env.NODE_ENV === 'development') {
            console.log(JSON.parse(result.body));
        }
        
        if(result.body) {
            if (store.getState().appState.results.length > 0) {
                //@ts-ignore
                store.dispatch(updateResults(JSON.parse(result.body).output.results));
            } else {
                //@ts-ignore
                store.dispatch(addResults(JSON.parse(result.body).output.results));
                
            }
        } else {
            throw new Error(JSON.stringify(result, null, 2))
        }
        store.dispatch(stopLoading())
    } catch (error) {
        bugsnagClient.notify(error)
        console.log(error)
        alert('Something went wrong calculating your projections, please contact support')
        store.dispatch(stopLoading())
    }
}


