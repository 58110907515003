export const ADD_USER_EVENT_STARTED = 'ADD_USER_EVENT_STARTED';
export const ADD_USER_EVENT_FAILED = 'ADD_USER_EVENT_FAILED';
export const ADD_USER_EVENT_SUCCESS = 'ADD_USER_EVENT_SUCCESS';

export const UPDATE_USER_EVENT_STARTED = 'UPDATE_USER_EVENT_STARTED';
export const UPDATE_USER_EVENT_FAILED = 'UPDATE_USER_EVENT_FAILED';
export const UPDATE_USER_EVENT_SUCCESS = 'UPDATE_USER_EVENT_SUCCESS';

export const ADD_RESULTS_STARTED = 'ADD_RESULTS_STARTED';
export const ADD_RESULTS_FAILED = 'ADD_RESULTS_STARTED';
export const ADD_RESULTS_SUCCESS = 'ADD_RESULTS_SUCCESS';

export const UPDATE_RESULTS_STARTED = 'UPDATE_RESULTS_STARTED';
export const UPDATE_RESULTS_FAILED = 'UPDATE_RESULTS_FAILED';
export const UPDATE_RESULTS_SUCCESS = 'UPDATE_RESULTS_SUCCESS';

export const GET_RESULTS_STARTED = 'GET_RESULTS_STARTED';
export const GET_RESULTS_FAILED = 'GET_RESULTS_FAILED';
export const GET_RESULTS_SUCCESS = 'GET_RESULTS_SUCCESS';

export const DELETE_USER_EVENT_STARTED = 'DELETE_USER_EVENT_STARTED';
export const DELETE_USER_EVENT_FAILED = 'DELETE_USER_EVENT_FAILED';
export const DELETE_USER_EVENT_SUCCESS = 'DELETE_USER_EVENT_SUCCESS';

export const GET_USER_EVENTS_STARTED = 'GET_USER_EVENTS_STARTED';
export const GET_USER_EVENTS_FAILED = 'GET_USER_EVENTS_FAILED';
export const GET_USER_EVENTS_SUCCESS = 'GET_USER_EVENTS_SUCCESS';

export const REGISTER_USER_STARTED  = 'REGISTER_USER_STARTED';
export const REGISTER_USER_FAILED  = 'REGISTER_USER_FAILED';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS ';

export const LOGIN_USER_STARTED  = 'LOGIN_USER_STARTED';
export const LOGIN_USER_FAILED  = 'LOGIN_USER_FAILED';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

export const RESTORE_SESSION = 'RESTORE_SESSION';

export const LOGOUT_USER_STARTED  = 'LOGOUT_USER_STARTED';
export const LOGOUT_USER_FAILED  = 'LOGOUT_USER_FAILED';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const SET_RESULTS = 'SET_RESULTS';

export const SET_ONBOARDING_COMPLETE_STARTED = 'SET_ONBOARDING_COMPLETE_STARTED';
export const SET_ONBOARDING_COMPLETE_FAILED = 'SET_ONBOARDING_COMPLETE_FAILED';
export const SET_ONBOARDING_COMPLETE_SUCCESS = 'SET_ONBOARDING_COMPLETE_SUCCESS';

export const START_ONBOARDING_STARTED = 'START_ONBOARDING_STARTED';
export const START_ONBOARDING_FAILED = 'START_ONBOARDING_FAILED';
export const START_ONBOARDING_SUCCESS = 'START_ONBOARDING_SUCCESS';

export const GET_USER_SETTINGS_STARTED = 'GET_USER_SETTINGS_STARTED';
export const GET_USER_SETTINGS_FAILED = 'GET_USER_SETTINGS_FAILED';
export const GET_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS';

// Add Event

interface AddUserEventStartedAction {
    type: typeof ADD_USER_EVENT_STARTED;
    payload?: object;
}

interface AddUserEventFailedAction {
    type: typeof ADD_USER_EVENT_FAILED;
    payload?: object;
}

interface AddUserEventSuccessAction {
    type: typeof ADD_USER_EVENT_SUCCESS;
    payload: any;
}

// Update Event 

interface UpdateUserEventStartedAction {
    type: typeof UPDATE_USER_EVENT_STARTED;
    payload?: object;
}

interface UpdateUserEventFailedAction {
    type: typeof UPDATE_USER_EVENT_FAILED;
    payload?: object;
}

interface UpdateUserEventSuccessAction {
    type: typeof UPDATE_USER_EVENT_SUCCESS;
    payload?: any;
}

// Delete Event

interface DeleteUserEventStartedAction {
    type: typeof DELETE_USER_EVENT_STARTED
    payload?: object;
}

interface DeleteUserEventFailedAction {
    type: typeof DELETE_USER_EVENT_FAILED
    payload?: object;
}

interface DeleteUserEventSuccessAction {
    type: typeof DELETE_USER_EVENT_SUCCESS,
    payload: Array<any>
}


// Get Events

interface GetUserEventStartedAction {
    type: typeof GET_USER_EVENTS_STARTED
    payload?: object;
}

interface GetUserEventFailedAction {
    type: typeof GET_USER_EVENTS_FAILED
    payload?: object;
}

interface GetUserEventSuccessAction {
    type: typeof GET_USER_EVENTS_SUCCESS,
    payload: Array<any>
}

interface registerUserStartedAction {
    type: typeof REGISTER_USER_STARTED,
    payload?: object
}

interface registerUserFailedAction {
    type: typeof REGISTER_USER_FAILED,
    payload?: object
}

interface registerUserSuccessAction {
    type: typeof REGISTER_USER_SUCCESS,
    payload?: object
}

// Login User
interface LoginUserStartedAction {
    type: typeof LOGIN_USER_STARTED,
    payload?: object
}

interface LoginUserFailedAction {
    type: typeof LOGIN_USER_FAILED,
    payload?: object
}

interface LoginUserSuccessAction {
    type: typeof LOGIN_USER_SUCCESS,
    payload?: object
}

// Logout and Restore Session

interface RestoreSessionAction {
    type: typeof RESTORE_SESSION,
    payload?: object
}

interface logoutUserStartedAction {
    type: typeof LOGOUT_USER_STARTED,
    payload?: object
}

interface logoutUserFailedAction {
    type: typeof LOGOUT_USER_FAILED,
    payload?: object
}

interface logoutUserSuccessAction {
    type: typeof LOGOUT_USER_SUCCESS,
    payload?: object
}

// Loading 

interface startLoadingAction {
    type: typeof START_LOADING,
    payload?: object
}

interface stopLoadingAction {
    type: typeof STOP_LOADING,
    payload?: object
}

// Get Results

interface getResultsStarted {
    type: typeof GET_RESULTS_STARTED,
    payload?: object
}

interface getResultsFailed {
    type: typeof GET_RESULTS_FAILED,
    payload?: object
}

interface getResultsSuccess {
    type: typeof GET_RESULTS_SUCCESS,
    payload: any
}

// Add Results

interface addResultsStarted {
    type: typeof ADD_RESULTS_STARTED,
    payload?: object
}

interface addResultsFailed {
    type: typeof ADD_RESULTS_FAILED,
    payload?: object
}

interface addResultsSuccess {
    type: typeof ADD_RESULTS_SUCCESS,
    payload: any
}

// Update Results

interface updateResultsStarted {
    type: typeof UPDATE_RESULTS_STARTED,
    payload?: object
}

interface updateResultsFailed {
    type: typeof UPDATE_RESULTS_FAILED,
    payload?: object
}

interface updateResultsSuccess {
    type: typeof UPDATE_RESULTS_SUCCESS,
    payload: any
}

// Onboarding Complate

interface setOnboardingCompleteStartedAction {
    type: typeof SET_ONBOARDING_COMPLETE_STARTED,
    payload?: object
}

interface setOnboardingCompleteFailedAction {
    type: typeof SET_ONBOARDING_COMPLETE_FAILED,
    payload?: object
}

interface setOnboardingCompleteSuccessAction {
    type: typeof SET_ONBOARDING_COMPLETE_SUCCESS,
    payload?: any
}

// Onboarding Start

interface startOnboardingStartAction {
    type: typeof START_ONBOARDING_STARTED,
    payload?: object
}

interface startOnboardingFailedAction {
    type: typeof START_ONBOARDING_FAILED,
    payload?: object
}

interface startOnboardingSuccessAction {
    type: typeof START_ONBOARDING_SUCCESS,
    payload?: any
}

// Get User Settings

interface getUserSettingsStartedAction {
    type: typeof GET_USER_SETTINGS_STARTED,
    payload?: object
}

interface getUserSettingsFailedAction {
    type: typeof GET_USER_SETTINGS_FAILED,
    payload?: object
}

interface getUserSettingsSuccessAction {
    type: typeof GET_USER_SETTINGS_SUCCESS,
    payload?: any
}

// Exports

export type AddEventTypes = AddUserEventStartedAction | AddUserEventFailedAction | AddUserEventSuccessAction;

export type UpdateEventTypes = UpdateUserEventStartedAction | UpdateUserEventFailedAction | UpdateUserEventSuccessAction;

export type DeleteEventTypes = DeleteUserEventStartedAction | DeleteUserEventFailedAction | DeleteUserEventSuccessAction;

export type GetEventTypes = GetUserEventStartedAction | GetUserEventFailedAction | GetUserEventSuccessAction;

export type RegisterUserTypes = registerUserStartedAction | registerUserFailedAction | registerUserSuccessAction;

export type LoginTypes = LoginUserStartedAction | LoginUserFailedAction |  LoginUserSuccessAction;

export type LogoutTypes = logoutUserStartedAction | logoutUserFailedAction | logoutUserSuccessAction;

export type SessionTypes = RestoreSessionAction;

export type LoadingTypes = startLoadingAction | stopLoadingAction;

export type GetResultTypes = 
    getResultsStarted |
    getResultsFailed |
    getResultsSuccess;

export type AddResultTypes = 
    addResultsStarted | 
    addResultsFailed |
    addResultsSuccess;

export type UpdateResultTypes = 
    updateResultsStarted |
    updateResultsFailed |
    updateResultsSuccess;

export type OnboardingTypes = 
    setOnboardingCompleteStartedAction |
    setOnboardingCompleteFailedAction |
    setOnboardingCompleteSuccessAction;

export type GetUserSettingTypes = 
    getUserSettingsStartedAction |
    getUserSettingsFailedAction |
    getUserSettingsSuccessAction |
    startOnboardingStartAction |
    startOnboardingFailedAction |
    startOnboardingSuccessAction;

export type AppStateActionTypes = 
    AddEventTypes | 
    UpdateEventTypes |
    GetEventTypes |  
    RegisterUserTypes |
    LoginTypes | 
    LogoutTypes |
    SessionTypes |
    LoadingTypes |
    GetResultTypes |
    AddResultTypes |
    UpdateResultTypes |
    DeleteEventTypes |
    OnboardingTypes | 
    GetUserSettingTypes;