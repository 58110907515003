import React from 'react';
import { Snackbar, IconButton, Icon } from '@material-ui/core';

interface Props {
    message: string;
    hideDuration: number;
    state: boolean;
}

const SnackBar: React.FC<Props> = ({message, hideDuration, state}) => {
    const [snackBarState, snackBarStateSetter] = React.useState(state);
    const snackbarClose = () => {
        snackBarStateSetter(false);
       }

    return (<Snackbar 
                open={snackBarState}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={hideDuration}
                onClose={snackbarClose}
                message={<span>{message}</span>}
                action={
                    <IconButton
                        key="close"
                        onClick={snackbarClose}
                    >
                        <Icon>close</Icon>
                    </IconButton>}
            />)
}

export default SnackBar;