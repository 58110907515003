import React from 'react';
import { Grid, Card, CardContent, Typography, Button, IconButton, Icon } from '@material-ui/core';
import { FieldSize, ProjectDurationFrequency, ComponentType } from '../../../classes/types'; 
import { inputHelper, inputArrayHelper } from '../../FormGenerator/fields';
import { FieldArray } from 'formik';

const typeComponent = (position: number, del: any) => (<Card style={{width: '100%', marginBottom: '5px'}}>
    <CardContent>
        <Grid container>
            {inputArrayHelper({
                name: 'componentName',
                label: "Name",
                input: 'text',
                required: true,
                size: FieldSize.four,
            }, 'productComponents', position)}
            {inputArrayHelper({
                name: 'componentType',
                label: "Type",
                input: 'select',
                options: ComponentType,
                required: true,
                size: FieldSize.four,
            }, 'productComponents', position)}
            {inputArrayHelper({
                name: 'unitCost',
                label: "Amount",
                input: 'number',
                required: true,
                size: FieldSize.three,
            }, 'productComponents', position)}
             <Grid item xs={1} style={{margin: 'auto'}}><Button onClick={() => del(position)}><IconButton><Icon>close</Icon></IconButton></Button></Grid>
        </Grid>
    </CardContent>
</Card>)
  
const partComponent = (position: number, del: any) => (<Card style={{width: '100%', marginBottom: '5px'}}>
<CardContent>
    <Grid container>
        {inputArrayHelper({
            name: 'componentName',
            label: "Name",
            input: 'text',
            required: true,
            size: FieldSize.four,
        }, 'productComponents', position)}
        {inputArrayHelper({
            name: 'unitVolume',
            label: "# of Items",
            input: 'number',
            required: true,
            size: FieldSize.four,
        }, 'productComponents', position)}
        {inputArrayHelper({
            name: 'unitCost',
            label: "Amount",
            input: 'money',
            required: true,
            size: FieldSize.three,
        }, 'productComponents', position)}
         <Grid item xs={1} style={{margin: 'auto'}}><Button onClick={() => del(position)}><IconButton><Icon>close</Icon></IconButton></Button></Grid>
    </Grid>
</CardContent>
</Card>)

const cardStyle = {
    margin: 'auto', 
    width: '100%',
    backgroundColor: '#fffcfc',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#C9EAF8',
    marginBottom: '5px'
} as React.CSSProperties

interface FuncProps {
    componentType: string;
    values: any
}

const GenericProductForm: React.FC<FuncProps> = ({componentType, values}) => {

    const componentToDisplay = (position: number, del: any) => {
        if (componentType === 'type') {
            return typeComponent(position, del);
        } else if (componentType === 'part') {
            return partComponent(position, del);
        }
    }

    return <Grid container>
        <Card style={cardStyle}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}><Typography variant="subtitle1"><b>Product</b></Typography></Grid>
                    {inputHelper({
                        name: "productName",
                        label: "Name",
                        input: 'text',
                        required: true,
                        size: FieldSize.six
                    })}
                    {inputHelper({
                        name: "price",
                        label: "Price",
                        input: 'money',
                        required: true,
                        size: FieldSize.six
                    })}
                    <Card style={cardStyle}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12}><Typography variant="subtitle2">Product Components</Typography></Grid>
                            <FieldArray 
                                name="productComponents"
                                render={arrayHelpers => (
                                    <React.Fragment>
                                        {values.productComponents.map((component: any, i: number) => (
                                            <React.Fragment>
                                                {componentToDisplay(i, arrayHelpers.remove)}
                                            </React.Fragment>
                                        ))}
                                        <Grid item xs={12}><Button onClick={() => arrayHelpers.push({})}variant="outlined" color='primary'>Add Component</Button></Grid>
                                    </React.Fragment>
                                )}
                            />
                        </Grid>
                    </CardContent>
                </Card>
                </Grid>
            </CardContent>
        </Card>    
        <Card style={cardStyle}>
            <CardContent>
                <Grid item xs={12}><Typography variant="subtitle1"><b>Project Details</b></Typography></Grid>
                <Grid container>
                    {inputHelper({
                        name: "date",
                        label: "Start Date",
                        input: 'date',
                        required: true,
                        size: FieldSize.four
                    })}
                    {inputHelper({
                        name: "duration",
                        label: "Duration",
                        input: 'number',
                        required: true,
                        size: FieldSize.four
                    })}
                    {inputHelper({
                        name: "durationFrequency",
                        label: "Duration",
                        input: 'select',
                        options: ProjectDurationFrequency,
                        required: true,
                        size: FieldSize.four
                    })}
                    <Card style={cardStyle}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} style={{marginTop: '15px'}}><Typography variant="subtitle2">Setup Costs</Typography></Grid>
                                <FieldArray 
                                    name="projectCosts"
                                    render={arrayHelpers => (
                                        <React.Fragment>
                                            {values.projectCosts.map((cost: any, i: number) => 
                                                <Card style={{width: '100%', marginTop: '5px'}}>
                                                    <CardContent>
                                                        <Grid container>
                                                            {inputArrayHelper({
                                                                name: 'expenseSetupName',
                                                                label: 'Name',
                                                                input: 'text',
                                                                required: true,
                                                                size: FieldSize.six
                                                            }, 'projectCosts', i)}
                                                            {inputArrayHelper({
                                                                name: 'amount',
                                                                label: 'Amount',
                                                                input: 'text',
                                                                required: true,
                                                                size: FieldSize.five
                                                            }, 'projectCosts', i)}
                                                            <Grid item xs={1} style={{margin: 'auto'}}><Button onClick={() => arrayHelpers.remove(i)}><IconButton><Icon>close</Icon></IconButton></Button></Grid>
                                                        </Grid>

                                                    </CardContent>
                                                </Card>)}
                                                <Button style={{marginTop: '5px'}} onClick={() => arrayHelpers.push({})}variant="outlined" color='primary'>Add Setup Cost</Button>
                                        </React.Fragment>)}
                                    />
                                </Grid> 
                        </CardContent>
                    </Card>
                </Grid>
            </CardContent>
        </Card>    
    </Grid>
}

export default GenericProductForm
