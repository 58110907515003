import React from 'react';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import { Formik, Form, FieldArray } from 'formik';
import * as config from '../FormGenerator/formConfigs';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Icon, Typography, Card, CardContent } from '@material-ui/core';
import { inputHelper, inputArrayHelper } from '../FormGenerator/fields';
import { FieldSize, CustomerTypes} from '../../classes/types';
import { checkUserMobile } from '../../helpers/helpers';
import classes from './OnboardingSteps.module.css';
import ArrayCostItemCard from './FieldArray/ArrayCostItemCard';
import * as Yup from 'yup';

interface FuncProps {
    next: (form: any, stepName: string) => void;
    back: () => void;
    close: () => void;
    currentStep: number;
    companyName: string;
    funnelName: string;
    productName: string;
    initialValues: any;
}

const validationSchema = Yup.object().shape({
    companyCosts: Yup.array(Yup.object().shape({
        recurringStatus: Yup.boolean(),
        expenseSetupName: Yup
            .string()
            .when('recurringStatus', {
                is: false,
                then: Yup.string().required('Please enter a name')   
            }),
        amount: Yup
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Amount must be > $0')
            .required('Please input an amount'),
        expenseOngoingName: Yup
            .string()
            .when('recurringStatus', {
                is: true,
                then: Yup.string().required('Please enter a name')   
            }),
        recurring: Yup
            .mixed()
            .when('recurringStatus', {
                is: true,
                then: Yup.mixed().required('Please make a selection')   
            })
    })),
    productCosts: Yup.array(Yup.object().shape({
        recurringStatus: Yup.boolean(),
        expenseSetupName: Yup
            .string()
            .when('recurringStatus', {
                is: false,
                then: Yup.string().required('Please enter a name')   
            }),
        amount: Yup
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Amount must be > $0')
            .required('Please input an amount'),
        expenseOngoingName: Yup
            .string()
            .when('recurringStatus', {
                is: true,
                then: Yup.string().required('Please enter a name')   
            }),
        recurring: Yup
            .mixed()
            .when('recurringStatus', {
                is: true,
                then: Yup.mixed().required('Please make a selection')   
            })
    })),
});

const OnboardingCostsDialog: React.FC<FuncProps> = ({next, back, close, currentStep, companyName, productName, funnelName, initialValues}) => {
    const thisStep = 6;
    const stepName = 'startupCosts';

    const [foundingCostSelection, setFoundingCostSelection] = React.useState(0);
    const handleFoundingCostSelection = (choice: number) => {
        setFoundingCostSelection(choice);
    }

    const [productCostSelection, setProductCostSelection] = React.useState(0);
    const handleProductCostSelection = (choice: number) => {
        setProductCostSelection(choice);
    }

    const [funnelCostSelection, setFunnelCostSelection] = React.useState(0);
    const handleFunnelCostSelection = (choice: number) => {
        setFunnelCostSelection(choice);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                next(values, stepName);
                actions.resetForm();
                actions.setSubmitting(false);
                close()

            }}
        >
            {({ values, isSubmitting, dirty, errors }) => (
                <Dialog
                    open={thisStep === currentStep}
                    onClose={close}
                    disableBackdropClick={true}
                    fullWidth={true}
                    fullScreen={checkUserMobile()}
                    maxWidth="md"
                >
                    <DialogTitle>Tell us about your Startup Costs</DialogTitle>
                    <Form>
                        <DialogContent>
                            <Grid container>
                            <Grid item xs={12}><Typography variant="h6">{_.upperFirst(companyName)} Company Founding Costs</Typography></Grid>
                            <br></br>
                            <FieldArray 
                                name="companyCosts"
                                render={arrayHelpers => (
                                    <div className={classes.array}>
                                        {values.companyCosts.length > 0 ? 
                                        <React.Fragment>
                                        {values.companyCosts.map((cost: any, i: number) => (
                                            <ArrayCostItemCard 
                                                key={"companyCosts" + i}
                                                remove={arrayHelpers.remove} 
                                                add={arrayHelpers.push} 
                                                array={"companyCosts"}
                                                index={i}
                                                propsExpansion={foundingCostSelection}
                                                values={values}
                                                pannelChoice={handleFoundingCostSelection}
                                            />
                                        ))} </React.Fragment> : <Button color="primary" variant="outlined" onClick={() => arrayHelpers.push({})}>Add Cost</Button> }
                                    </div>
                                )}
                            />
                            <Grid item xs={12}><Typography variant="h6">{_.upperFirst(productName)} Setup Costs</Typography></Grid>
                            <br></br>
                            <FieldArray 
                                name="productCosts"
                                render={arrayHelpers => (
                                    <div className={classes.array}>
                                        {values.productCosts.length > 0 ? 
                                        <React.Fragment>
                                        {values.productCosts.map((cost: any, i: number) => (
                                            <ArrayCostItemCard 
                                                key={"productCosts" + i}
                                                remove={arrayHelpers.remove} 
                                                add={arrayHelpers.push} 
                                                array={"productCosts"}
                                                index={i}
                                                propsExpansion={productCostSelection}
                                                values={values}
                                                pannelChoice={handleProductCostSelection}
                                            />
                                        ))} </React.Fragment> : <Button color="primary" variant="outlined" onClick={() => arrayHelpers.push({})}>Add Cost</Button> }
                                    </div>
                                )}
                            />
                            <Grid item xs={12}><Typography variant="h6">{_.upperFirst(funnelName)} Setup Costs</Typography></Grid>
                            <br></br>
                            <FieldArray 
                                name="funnelCosts"
                                render={arrayHelpers => (
                                    <div className={classes.array}>
                                        {values.funnelCosts.length > 0 ? 
                                        <React.Fragment>
                                        {values.funnelCosts.map((cost: any, i: number) => (
                                            <ArrayCostItemCard 
                                                key={"funnelCosts" + i}
                                                remove={arrayHelpers.remove} 
                                                add={arrayHelpers.push} 
                                                array={"funnelCosts"}
                                                index={i}
                                                propsExpansion={funnelCostSelection}
                                                values={values}
                                                pannelChoice={handleFunnelCostSelection}
                                            />
                                        ))} </React.Fragment> : <Button color="primary" variant="outlined" onClick={() => arrayHelpers.push({})}>Add Cost</Button> }
                                    </div>
                                )}
                            />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                onClick={back}
                                color="secondary" 
                                type="button">
                                Back
                            </Button>
                            <Button 
                                disabled={
                                    isSubmitting || 
                                    !isEmpty(errors)
                                } 
                                color="primary" 
                                type="submit">
                                Next Step
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}

        </Formik>
    )
}

export default OnboardingCostsDialog;