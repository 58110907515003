import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Grid, ButtonGroup, Typography, DialogActions } from '@material-ui/core';
import { checkUserMobile } from '../../helpers/helpers';

import classes from './OnboardingSteps.module.css';

interface FuncProps {
    next: (selection: any, stepName: string) => void;
    back: () => void;
    close: () => void;
    currentStep: number;
}

const OnboardingProductSelectionDialog: React.FC<FuncProps> = ({next, back, close, currentStep}) => {

    const thisStep = 2;
    const stepName = 'productSelection';

    return (
        <Dialog
            open={currentStep === thisStep}
            onClose={close}
            disableBackdropClick={true}
            fullWidth={true}
            fullScreen={checkUserMobile()}
            maxWidth="md"
        >
            <DialogTitle>
                What are you planning to sell initially?
                <Typography variant="subtitle2" color="textSecondary">Click the option that best suits your business</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <div className={classes.buttons}>
                        <Button color="primary" variant="contained" onClick={()=> next('avgRevenue', stepName)}>Average Revenue</Button>
                        <Button color="primary" variant="contained" onClick={()=> next('oneProduct', stepName)}>One Product</Button>
                        <Button color="secondary" variant="contained" href="mailto:support@thena.app?subject=New Product Type Request">Request New Product</Button>
                    </div>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={back}
                    color="secondary" 
                >
                    Back
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default OnboardingProductSelectionDialog;