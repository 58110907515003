import React from 'react'
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';

import { SalesFunnelProjectEventSettings } from '../../classes/userEvents';
import { checkUserMobile, monthFormat } from '../../helpers/helpers';
import { CardHeader, CardContent, Typography, Grid, Divider, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import classes from './UserCards.module.css';
import ActionOptions from './ActionOptions/ActionOptions';

interface Props {
    event: SalesFunnelProjectEventSettings;
    deleteHandler: (event: SalesFunnelProjectEventSettings) => void;
}

const FunnelCard: React.FC<Props> = ({event, deleteHandler}) => {

    const funnelContainerStyle = {
        padding: '30px',
        margin: 'auto'
    } as React.CSSProperties

    const inputStyle = {
        width: '100%',
        height: '35px',
        marginBottom: '0px',
        border: 'solid',
        borderColor: 'white',
        backgroundColor: '#1976D2',
        textAlign: 'center',
        padding: 'auto',
        color: 'white',
        borderRadius: '15px'
    } as React.CSSProperties

    const leadStyle = (i: number, arrayLength: number) => {
        const width = (50 + (50/(arrayLength+1)*(arrayLength-i))).toString().concat('%')
       return {
        width,
        height: '35px',
        margin: 'auto',
        backgroundColor: '#C9EAF8',
        border: 'solid',
        borderColor: 'white',
        textAlign: 'center',
        padding: 'auto',
        borderRadius: '15px'
       } as React.CSSProperties
    } 

    const outputStyle = {
        width: '50%',
        height: '35px',
        padding: '1px',
        margin: 'auto',
        textAlign: 'center',
        backgroundColor: 'orange',
        border: 'solid',
        borderColor: 'white',
        borderRadius: '15px'
    } as React.CSSProperties

    let gridWidthForDisplays = 6;

    const handleMobileDisplay = () => {
        if (checkUserMobile()) {
            gridWidthForDisplays = 12
        } else {
            // @ts-ignore
            return <Grid item xs={gridWidthForDisplays}>
                <Typography style={{textAlign: 'center'}} variant="h6">Funnel</Typography>
                <Grid container style={funnelContainerStyle}>
                    <Grid item xs={12}><div style={inputStyle}> {event.funnelInput}</div></Grid>
                    {event.leadTypes.map((type, i) => <Grid item xs={12}>
                        <div style={leadStyle(i, event.leadTypes.length)}>{type.leadName}</div>
                    </Grid>)}
                    <Grid item xs={12}><div style={outputStyle}>{_.capitalize(event.funnelOutput)}</div></Grid>
                </Grid>
            </Grid>

        }

    }

    return (
        <React.Fragment>
            <CardHeader
                title={'Funnel: ' + _.startCase(event.projectName)}
                subheader={moment(event.date).format('DD MMMM YYYY')}
                action={<ActionOptions event={event} deleteHandler={deleteHandler} />}
            />
            <CardContent>
                <Grid container>
                    {/*
                    //@ts-ignore */}
                    <Grid item xs={gridWidthForDisplays}>
                        <Typography variant="body1"><b>Project Duration: </b>{numeral(event.duration).format(monthFormat(event.duration))} {event.durationFrequency}</Typography>
                        <Typography variant="body1"><b>Project End Date: </b>
                            {moment(event.date).add(event.duration, event.durationFrequency).format('DD MMMM YYYY')}
                        </Typography>
                        <br></br>
                        <Divider />
                    <Typography variant="h6">Project Costs</Typography>
                        <Grid container>
                            {event.projectCosts.map(cost => 
                            <Grid item xs={12} key={cost.expenseSetupName}>
                                <ExpansionPanel className={classes.arrayCard}>
                                    <ExpansionPanelSummary>
                                        <Typography variant="body1"><b>Name: </b>{cost.expenseSetupName}</Typography>  
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="body1"><b>Amount: </b>${numeral(cost.amount).format('0,0.00')}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>)}
                        </Grid>
                        <br></br>
                        <Divider />
                        <Typography variant="h6">Maintenance Costs</Typography>
                        {event.ongoingCosts.map((cost: any) => 
                            <Grid item xs={12} key={cost.expenseOngoingName + cost.amount}>
                                <ExpansionPanel className={classes.arrayCard}>
                                    <ExpansionPanelSummary>
                                        <Typography variant="body1"><b>Name: </b>{cost.expenseOngoingName}</Typography>  
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="body1"><b>Amount: </b>${numeral(cost.amount).format('0,0.00')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1"><b>Recurring: </b>{cost.recurring}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>)}
                    </Grid>
                    {handleMobileDisplay()}
                </Grid>
            </CardContent>
        </React.Fragment>
    )

}

export default FunnelCard;