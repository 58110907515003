import React from 'react';
import UserEventCard from '../../components/UserEventCards/UserEventCard';
import FormGenerator from '../../components/FormGenerator/FormGenerator';
import { Route, withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { AppState } from '../../store/store';

interface StateProps {
    events: Array<any>
}

type Props = StateProps & RouteComponentProps;

const UserEventTimelinePage: React.FC<Props> = ({events}) => {
    const display = events
        .sort((a: any, b: any) => { return new Date(a.date).getTime() - new Date(b.date).getTime(); })
        .map(event => <UserEventCard event={event} key={event._id.toString()} />)
    return (
        <React.Fragment>
            {display}
            <Route exact path='/events/:type/:id/' component={FormGenerator} />
            <Route exact path='/events/:type/:id/:component' component={FormGenerator} />
        </React.Fragment>
    )


}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        events: state.appState.events,
    }
}


export default withRouter(connect(mapStateToProps)(UserEventTimelinePage));