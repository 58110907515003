import React from 'react';
import { Dialog, DialogContent, CircularProgress } from '@material-ui/core';
import { AppState } from '../../store/store';
import { connect } from 'react-redux';

import classes from './LoadingDialog.module.css';

interface MyProps {}

interface StateProps {
    loading: boolean
}

const LoadingDialog: React.FC<StateProps> = ({loading}) => (
    <Dialog open={loading}>
        <DialogContent>
            <div className={classes.spinner}>
                <CircularProgress />
            </div>
        </DialogContent>
    </Dialog>
)

const mapStateToProps = (state: AppState ): StateProps => {
    return {
        loading: state.appState.loading
    }
}

export default connect(mapStateToProps, null)(LoadingDialog);