import React from 'react';
import { isEmpty } from 'lodash';
import { Formik, Form, FieldArray } from 'formik';
import * as config from '../FormGenerator/formConfigs';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Icon } from '@material-ui/core';
import { inputHelper, inputArrayHelper } from '../FormGenerator/fields';
import { FieldSize, CustomerTypes} from '../../classes/types';
import * as Yup from 'yup';
import { checkUserMobile } from '../../helpers/helpers';


import _ from 'lodash';

interface FuncProps {
    next: (form: any, stepName: string) => void;
    back: () => void;
    close: () => void;
    currentStep: number;
    initialValues: any;
}

const validationSchema = Yup.object().shape({
    projectName: Yup
        .string()
        .min(4)
        .required('Please enter your funnel name'),
    funnelInput: Yup
        .string()
        .min(1)
        .required('Please enter your funnel source'),
    funnelOutput: Yup
        .mixed()
        .required('Please make a selection'),
    funnelOutputRate: Yup        
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(1, 'Rate must be 1% or more')
        .max(100, "Rate cannot exceed 100%")
        .required('Please enter a rate'),
    leadTypes: Yup.array(Yup.object().shape({
        leadName: Yup
            .string()
            .min(4, "Tier name must be longer than 4 characters")
            .required('Please enter your company name'),
        conversionRate: Yup        
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(1, 'Rate must be 1% or more')
            .max(100, "Rate cannot exceed 100%")
            .required('Please enter a rate'),
        
    }))
})

const OnboardingSalesFunnelStepOneDialog: React.FC<FuncProps>= ({next, back, close, currentStep, initialValues}) => {


    const inputStyle = {
        width: '100%',
        height: '35px',
        marginBottom: '0px',
        border: 'solid',
        borderColor: 'white',
        backgroundColor: '#1976D2',
        textAlign: 'center',
        padding: 'auto',
        color: 'white',
        borderRadius: '15px'
    } as React.CSSProperties

    const leadStyle = (i: number, arrayLength: number) => {
        const width = (50 + (50/(arrayLength+1)*(arrayLength-i))).toString().concat('%')
       return {
        width,
        height: '35px',
        margin: 'auto',
        backgroundColor: '#C9EAF8',
        border: 'solid',
        borderColor: 'white',
        textAlign: 'center',
        padding: 'auto',
        borderRadius: '15px'
       } as React.CSSProperties
    } 
    
    const outputStyle = {
        width: '50%',
        height: '35px',
        padding: '1px',
        margin: 'auto',
        textAlign: 'center',
        backgroundColor: 'orange',
        border: 'solid',
        borderColor: 'white',
        borderRadius: '15px'
    } as React.CSSProperties

    const funnelContainerStyle = {
        border: 'solid', 
        borderWidth: '1px', 
        padding: '10px'
    } as React.CSSProperties

    const thisStep = 4;
    const stepName = 'salesFunnelStepOne';

    const leadInfo = (values: any) => {
        if (values.leadTypes) {
            return values.leadTypes.map((type:any, i:number) => <Grid key={type+i} xs={12}><div style={leadStyle(i, values.leadTypes.length)}> {type.leadName ? _.upperFirst(type.leadName) : null }</div></Grid>)
        } else {
            return
        }
    }

    let gridWidthForDisplay = 6;

    const hideFunnelOnMobile = (values: any) => {
        if (!checkUserMobile()) {
            //@ts-ignore
            return <Grid item xs={gridWidthForDisplay}>
                <Grid container>
                    <Grid item xs={12}><div style={inputStyle}> {_.capitalize(values.funnelInput)}</div></Grid>
                    {leadInfo(values)}
                    <Grid item xs={12}><div style={outputStyle}> {_.capitalize(values.funnelOutput)}</div></Grid>
                </Grid>
            </Grid>
        } else {
            gridWidthForDisplay = 12;
            return
        }
    }

    return(
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                next(values, stepName);
                actions.resetForm();
                actions.setSubmitting(false);
                close()

            }}
        >
            {({ values, isSubmitting, dirty, errors }) => (
                <Dialog
                    open={thisStep === currentStep}
                    onClose={close}
                    disableBackdropClick={true}
                    fullWidth={true}
                    fullScreen={checkUserMobile()}
                    maxWidth="md"
                >
                    <Form>
                        <DialogTitle>
                        Create a Sales Funnel: Define The Funnel
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                {inputHelper({
                                    name: 'projectName',
                                    label: "Funnel Name",
                                    input: 'text',
                                    required: true,
                                    size: FieldSize.six,
                                })}
                            </Grid>
                            <Grid container alignItems="center" style={funnelContainerStyle}>
                                {/*
                                //@ts-ignore */}
                                <Grid item xs={gridWidthForDisplay}>
                                    <Grid container>
                                        {inputHelper({
                                            name: "funnelInput",
                                            label: "Funnel Input",
                                            input: 'text',
                                            required: true,
                                            size: FieldSize.six,
                                        })}
                                        <Grid item xs={6}/>
                                        <FieldArray
                                            name="leadTypes"
                                            render={arrayHelpers => (
                                                <React.Fragment>
                                                    {values.leadTypes && values.leadTypes.length > 0 ? (
                                                        values.leadTypes.map((lead: any, i: number) => 
                                                        <React.Fragment>
                                                        {inputArrayHelper({
                                                            name: 'leadName',
                                                            label: 'Tier Name',
                                                            input: 'text',
                                                            required: true,
                                                            size: FieldSize.six
                                                        }, 'leadTypes', i)}
                                                        {inputArrayHelper({
                                                            name: 'conversionRate',
                                                            label:  "Percentage of " + _.capitalize(values.funnelInput),
                                                            input: 'percentage',
                                                            required: true,
                                                            size: FieldSize.five
                                                        }, 'leadTypes', i )}
                                                        <Grid item xs={1}>
                                                            <Button color="secondary" onClick={() => arrayHelpers.remove(i)}><Icon>close</Icon></Button>
                                                            {i + 1 === values.leadTypes.length ? <Button color="primary" onClick={() => arrayHelpers.push({})}><Icon>add</Icon></Button> : null}
                                                            </Grid>
                                                    </React.Fragment>
                                                        )):(
                                                        <Grid item xs={12}>
                                                            <Button 
                                                                variant="outlined" 
                                                                color="primary"
                                                                onClick={() => arrayHelpers.push({})}
                                                            >Add Lead Tier
                                                            </Button>
                                                        </Grid>

                                                        )}
                                                </React.Fragment>)}
                                        />
                                        {inputHelper({
                                            name: "funnelOutput",
                                            label: "Funnel Output",
                                            input: 'select',
                                            options: CustomerTypes,
                                            required: true,
                                            size: FieldSize.six
                                        })}
                                        {inputHelper({
                                            name: "funnelOutputRate",
                                            label:  "Percentage of " + _.capitalize(values.funnelInput),
                                            input: 'percentage',
                                            required: true,
                                            size: FieldSize.six,
                                        })}
                                    </Grid>
                                </Grid>
                                {hideFunnelOnMobile(values)}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                onClick={back}
                                color="secondary" 
                                type="button">
                                Back
                            </Button>
                            <Button 
                                disabled={
                                    isSubmitting || 
                                    !isEmpty(errors)
                                } 
                                color="primary" 
                                type="submit">
                                Next Step
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}
        </Formik>
    )
}

export default OnboardingSalesFunnelStepOneDialog;