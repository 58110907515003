import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, Button, Grid, Avatar, Typography } from '@material-ui/core';
import { checkUserMobile } from '../../helpers/helpers';
import logo from '../../assets/thena_icon.svg';


const VerifyEmailDialog: React.FC<RouteComponentProps> = (props) => {
    const [state, setState] = React.useState(true);
    const handleStateChange = () => {
        setState(!state);
    }
    return (
        <Dialog 
            disableBackdropClick
            disableEscapeKeyDown
            fullScreen={checkUserMobile()}
            open={state} 
            onClose={() => {
                handleStateChange()
            }}
            id="EventAddMenu">
            <DialogTitle id="eventMenu">
                <Grid container>
                    <Grid item xs={1}>
                        <Avatar alt="Thena" src={logo} style={{boxShadow: "1px 1px 0.5px grey"}}/>
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="h6">Please Verify Your Email</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                When you created your account we sent an email to you with a link to verify your email.
                <br></br>
                <br></br>
                Please go to your inbox and click on the link.
                <br></br>
                <br></br>
                <div style={{textAlign: 'center'}}>
                    <a href="https://mail.google.com">G-mail</a> | <a href="https://outlook.live.com/owa/">Outlook</a> | <a href="https://mail.yahoo.com">Yahoo</a>
                </div>
            </DialogContent>
        </Dialog>
    )

}

export default withRouter(VerifyEmailDialog)