import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Grid, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { inputHelper } from '../FormGenerator/fields';
import { FieldSize } from '../../classes/types';
import {checkUserMobile} from '../../helpers/helpers';

import classes from './OnboardingSteps.module.css';

interface FuncProps {
    next: (selection: any, stepName: string) => void;
    back: () => void;
    close: () => void;
    submit: (values: any) => void;
    currentStep: number;
    initialValues: any
}

const validationSchema = Yup.object().shape({
    date: Yup
    .date()
    .typeError('Please enter a date')
    .required('Please enter a date')
})

const OnboardingConfirmationDialog: React.FC<FuncProps> = ({submit, back, close, currentStep, initialValues}) => {

    const thisStep = 7;

    return (
        <Dialog
            open={currentStep === thisStep}
            onClose={close}
            disableBackdropClick={true}
            fullWidth={true}
            fullScreen={checkUserMobile()}
            maxWidth="md"
        >
            <DialogTitle>
                Ok, just one more question and you are almost ready to go! <span role="img">🙂 🔥 🥂 🎉</span> 
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        submit(values);
                        actions.resetForm();
                        actions.setSubmitting(false);
                        close()
                    }}
                >
                    {({ values, isSubmitting, dirty, errors }) => (
                        <Form>
                            <Grid container>
                                <Grid item xs={12}><Typography variant="h6">Which month do you expect to start generating revenue in?</Typography></Grid>
                                <Grid item xs={6}>
                                {inputHelper({
                                    name: 'date',
                                    label: 'Revenue Start Date',
                                    input: 'date',
                                    required: true,
                                    size: FieldSize.six
                                })}
                                </Grid>
                                <Grid item xs={12}/>
                                <div className={classes.buttons}>
                                    <Button 
                                        disabled={
                                            isSubmitting || 
                                            !isEmpty(errors)
                                        } 
                                        variant="contained" 
                                        color="primary"
                                        type="submit"
                                        >
                                        Let's do it!
                                    </Button>
                                </div>
                            </Grid>
                        </Form>)}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export default OnboardingConfirmationDialog;