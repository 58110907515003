import React from 'react';
import { isEmpty } from 'lodash';
import { Formik, Form, FieldArray } from 'formik';
import * as config from '../FormGenerator/formConfigs';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography } from '@material-ui/core';
import ArrayItemCard from './FieldArray/ArrayItemCard';
import classes from './OnboardingSteps.module.css';
import { inputHelper } from '../FormGenerator/fields';
import { FieldSize } from '../../classes/types';
import * as Yup from 'yup';
import {checkUserMobile} from '../../helpers/helpers';

interface FuncProps {
    next: (form: any, stepName: string) => void;
    back: () => void;
    close: () => void;
    currentStep: number;
    initialValues: any;
}

const validationSchema = Yup.object().shape({
    companyName: Yup
        .string()
        .min(4)
        .required('Please enter your company name'),
    date: Yup
        .date()
        .required('Please enter your founding date'),
    founders: Yup.array(Yup.object().shape({
        name: Yup
            .string()
            .min(4, 'Must be at least 4 characters long')
            .required('Please input a name')
        })).min(0, 'One founder is required').required('At least 1 founder is required')
})

const OnboardingCompanyFoundersDialog: React.FC<FuncProps>= ({next, back, close, currentStep, initialValues}) => {

    const foundersOptions = config.domains.filter(domain => domain.kind === 'founders')[0];
    const thisStep = 1;
    const stepName = 'companySetup';

    const [selection, setSelection] = React.useState(0);

    const handleExpansionChoice = (choice: number) => {
        setSelection(choice);
    }

    return(
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                next(values, stepName);
                actions.resetForm();
                actions.setSubmitting(false);
                close()

            }}
        >
            {({ values, isSubmitting, dirty, errors }) => (
                <Dialog
                    open={currentStep === thisStep}
                    onClose={close}
                    disableBackdropClick={true}
                    fullWidth={true}
                    fullScreen={checkUserMobile()}
                    maxWidth="md"
                >
                    <Form>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} ><Typography variant="h6">When will your company be founded?</Typography></Grid>
                                {inputHelper({
                                    name: "companyName",
                                    label: "Company Name",
                                    input: 'text',
                                    required: true,
                                    size: FieldSize.six,
                                })}
                                {inputHelper({ 
                                        name: "date",
                                        label: "Founded Date",
                                        input: 'date',
                                        required: true,
                                        size: FieldSize.six,
                                })}
                                <Grid item xs={12} ><Typography variant="h6">Tell me about your founding team?</Typography></Grid>
                                <div className={classes.array}>
                                    <FieldArray
                                        name="founders"
                                        render={arrayHelpers => (
                                            <React.Fragment>
                                                {values.founders.map((founder: any, i: number) => {
                                                    return (<ArrayItemCard 
                                                        remove={arrayHelpers.remove} 
                                                        add={arrayHelpers.push} 
                                                        form={foundersOptions} 
                                                        array={foundersOptions.kind}
                                                        index={i}
                                                        propsExpansion={selection}
                                                        values={values}
                                                        pannelChoice={handleExpansionChoice}
                                                        />)
                                                }
                                                )}
                                            </React.Fragment>
                                        )}
                                        />
                                </div>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                onClick={back}
                                color="secondary" 
                                type="button">
                                Basic Setup
                            </Button> 
                            <Button 
                                disabled={
                                    isSubmitting || 
                                    !isEmpty(errors)
                                } 
                                color="primary" 
                                type="submit">
                                Next Step
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}
        </Formik>
    )
}

export default OnboardingCompanyFoundersDialog;