import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store/store';
import _ from 'lodash';
import ProjectionGrid from '../../components/DataComponents/ProjectionGrid';

interface MyProps {
}

interface StateProps {
    events: Array<Result>
}

interface Result {
    _id: object;
    owner_id: string;
    projection: Array<any>
}

type Props = MyProps & StateProps;

const ProjectionsPageBasic: React.FC<Props> = ({events}) => {
    
    let projections: Array<any> = [];
    
    const getKeys = (subdomain: string, firstDomain?: string) => {
        let allKeys: Array<any> = []
        if (firstDomain) {
            projections.forEach(month => allKeys = allKeys.concat(Object.keys(month[firstDomain][subdomain])))
        } else {
            projections.forEach(month => allKeys = allKeys.concat(Object.keys(month[subdomain])))
        }
        const uniqueKeys = allKeys.filter((key, index) => allKeys.indexOf(key) === index && key !== 'sum');
        return uniqueKeys;
    }
    let funnels: any = [];

    if (events.length > 0) {
        projections = events[0].projection;
        funnels = getKeys('funnels');
    }
    return <ProjectionGrid projections={projections} />
}


const mapStateToProps = (state: AppState, ownProps: MyProps): StateProps => {
    return {
        events: state.appState.results
    }
}

export default connect(mapStateToProps, null)(ProjectionsPageBasic);