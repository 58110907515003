import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import { CompanyEventSettings } from '../../classes/userEvents';
import { CardHeader, CardContent, Typography, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ActionOptions from './ActionOptions/ActionOptions';
import classes from './UserCards.module.css';

interface Props {
    event: CompanyEventSettings;
    deleteHandler: (event: CompanyEventSettings) => void;
}

const CompanyCard: React.FC<Props> = ({event, deleteHandler}) => {

    const frequency = (founder: any) => {
        switch(founder.availabilityFrequency) {
            case 'weekly':
                return 'week'
            case 'daily':
                return 'day'
            default:
                return ''
        }
    }

    return (
        <React.Fragment>
            <CardHeader 
                title={'Founded: ' + _.startCase(event.companyName)} 
                subheader={moment(event.date).format('DD MMMM YYYY')} 
                action={<ActionOptions event={event} deleteHandler={deleteHandler} />}
            />
            <CardContent>
                <Typography variant="h6">Founders</Typography>
                <Grid container>
                    {event.founders.map(founder => 
                        <Grid item xs={12} key={founder.name}>
                            <ExpansionPanel className={classes.arrayCard}>
                                <ExpansionPanelSummary>
                                    <Typography variant="body1"><b>Name: </b>{founder.name}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="body1"><b>Availability: </b>{founder.availabilityHours} hours per {frequency(founder)}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1"><b>Compensation: </b>${numeral(founder.compensation).format('0,0.00')} per month</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1"><b>Cash Investment: </b> ${numeral(founder.cashInvestment).format('0,0.00')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1"><b>Ownership: </b> {founder.equity}%</Typography>
                                        </Grid>    
                                    </Grid>                                
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>)}
                </Grid>
                <Typography variant="h6">Setup Costs</Typography>
                <Grid container>
                    {event.setupCosts.map(cost => 
                        <Grid item xs={12} key={cost.expenseSetupName}>
                            <ExpansionPanel className={classes.arrayCard}>
                                <ExpansionPanelSummary>
                                    <Typography variant="body1"><b>Name: </b>{cost.expenseSetupName}</Typography>  
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant="body1"><b>Amount: </b>${numeral(cost.amount).format('0,0.00')}</Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>)}
                </Grid>
            </CardContent>

        </React.Fragment>
    )

}

export default CompanyCard;