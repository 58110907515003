import * as actionTypes from './actionTypes';
import { eventsCollection, projectionsCollection, userSettingsCollection } from '../../../database/mongodb';
import { Dispatch } from 'redux';
import * as auth from '../../../stitch/authentication';
import { store } from '../../store';
import { bugsnagClient } from '../../../bugsnag/bugsnag';

// Set Onboarding Complete

const setOnboardingCompleteStarted = (): actionTypes.OnboardingTypes => {
  
    return {
        type: actionTypes.SET_ONBOARDING_COMPLETE_STARTED
    }
}

const setOnboardingCompleteFailed = (): actionTypes.OnboardingTypes => {
   
    return {
        type: actionTypes.SET_ONBOARDING_COMPLETE_FAILED
    }
}

const setOnboardingCompleteSuccess = (event: object): actionTypes.OnboardingTypes => {
  
    return {
        type: actionTypes.SET_ONBOARDING_COMPLETE_SUCCESS,
        payload: event
    }
}

// Get User Settings

const getUserSettingsStarted = (): actionTypes.GetUserSettingTypes => {
  
    return {
        type: actionTypes.GET_USER_SETTINGS_STARTED
    }
}

const getUserSettingsFailed = (): actionTypes.GetUserSettingTypes => {
   
    return {
        type: actionTypes.GET_USER_SETTINGS_FAILED
    }
}

const getUserSettingsSuccess = (event: object): actionTypes.GetUserSettingTypes => {
  
    return {
        type: actionTypes.GET_USER_SETTINGS_SUCCESS,
        payload: event
    }
}

// Add Event

const addUserEventStarted = (): actionTypes.AddEventTypes => {
  
    return {
        type: actionTypes.ADD_USER_EVENT_STARTED
    }
}

const addUserEventFailed = (): actionTypes.AddEventTypes => {
   
    return {
        type: actionTypes.ADD_USER_EVENT_FAILED
    }
}

const addUserEventSuccess = (event: object): actionTypes.AddEventTypes => {
  
    return {
        type: actionTypes.ADD_USER_EVENT_SUCCESS,
        payload: event
    }
}

// Update Event

const updateUserEventStarted = (): actionTypes.UpdateEventTypes => {
    return {
        type: actionTypes.UPDATE_USER_EVENT_STARTED
    }
}

const updateUserEventFailed = (): actionTypes.UpdateEventTypes => {
   
    return {
        type: actionTypes.UPDATE_USER_EVENT_FAILED
    }
}

const updateUserEventSuccess = (event: any): actionTypes.UpdateEventTypes => {
  
    return {
        type: actionTypes.UPDATE_USER_EVENT_SUCCESS,
        payload: event
    }
}

// Delete Event

const deleteUserEventStarted = (): actionTypes.DeleteEventTypes => {
    return {
        type: actionTypes.DELETE_USER_EVENT_STARTED
    }
}

const deleteUserEventFailed = (): actionTypes.DeleteEventTypes => {
    return {
        type: actionTypes.DELETE_USER_EVENT_FAILED
    }
}


const deleteUserEventSuccess = (events: Array<any>): actionTypes.DeleteEventTypes => {
    return {
        type: actionTypes.DELETE_USER_EVENT_SUCCESS,
        payload: events
    }
}

// Get Events

const getEventsStarted = (): actionTypes.GetEventTypes => {
    return {
        type: actionTypes.GET_USER_EVENTS_STARTED
    }
}

const getEventsFailed = (): actionTypes.GetEventTypes => {
    return {
        type: actionTypes.GET_USER_EVENTS_FAILED
    }
}

const getEventsSuccess = (events: Array<any>): actionTypes.GetEventTypes => {
    return {
        type: actionTypes.GET_USER_EVENTS_SUCCESS,
        payload: events
    }
}

// Get Results

const getResultsStarted = (): actionTypes.GetResultTypes => {
    return {
        type: actionTypes.GET_RESULTS_STARTED
    }
}

const getResultsFailed = (): actionTypes.GetResultTypes => {
    return {
        type: actionTypes.GET_RESULTS_FAILED
    }
}

const getResultsSuccess = (events: Array<any>): actionTypes.GetResultTypes => {
    return {
        type: actionTypes.GET_RESULTS_SUCCESS,
        payload: events
    }
}

// Add Results

const addResultsStarted = (): actionTypes.AddResultTypes => {
    return {
        type: actionTypes.ADD_RESULTS_STARTED
    }
}

const addResultsFailed = (): actionTypes.AddResultTypes => {
    return {
        type: actionTypes.ADD_RESULTS_FAILED
    }
}

const addResultsSuccess = (events: Array<any>): actionTypes.AddResultTypes => {
    return {
        type: actionTypes.ADD_RESULTS_SUCCESS,
        payload: events
    }
}


// Update Results

const updateResultsStarted = (): actionTypes.UpdateResultTypes => {
    return {
        type: actionTypes.UPDATE_RESULTS_STARTED
    }
}

const updateResultsFailed = (): actionTypes.UpdateResultTypes => {
    return {
        type: actionTypes.UPDATE_RESULTS_FAILED
    }
}

const updateResultsSuccess = (events: Array<any>): actionTypes.UpdateResultTypes => {
    return {
        type: actionTypes.UPDATE_RESULTS_SUCCESS,
        payload: events
    }
}

// Login 

export const loginStarted = (): actionTypes.LoginTypes => {
    return {
        type: actionTypes.LOGIN_USER_STARTED
    }
}

export const loginFailed = (): actionTypes.LoginTypes => {
    return {
        type: actionTypes.LOGIN_USER_FAILED
    }
}

export const loginSuccess = (): actionTypes.LoginTypes => {
    return {
        type: actionTypes.LOGIN_USER_SUCCESS
    }
}

// Register

export const registerStarted = (): actionTypes.RegisterUserTypes => {
    return {
        type: actionTypes.REGISTER_USER_STARTED
    }
}

export const registerFailed = (): actionTypes.RegisterUserTypes => {
    return {
        type: actionTypes.REGISTER_USER_FAILED
    }
}

export const registerSuccess = (): actionTypes.RegisterUserTypes => {
    return {
        type: actionTypes.REGISTER_USER_SUCCESS
    }
}


const logoutStarted = (): actionTypes.LogoutTypes => {
    return {
        type: actionTypes.LOGOUT_USER_STARTED
    }
}

const logoutFailed = (): actionTypes.LogoutTypes => {
    return {
        type: actionTypes.LOGOUT_USER_FAILED
    }
}

const logoutSuccess = (): actionTypes.LogoutTypes => {
    return {
        type: actionTypes.LOGOUT_USER_SUCCESS
    }
}

// Exports 

export function getResults() {
    return async (dispatch: Dispatch<actionTypes.GetResultTypes>) => {
        try {
            dispatch(getResultsStarted());
            const result: any = await projectionsCollection.find().toArray()
            dispatch(getResultsSuccess(result));
        } catch(error) {
            dispatch(getResultsFailed())
            bugsnagClient.notify(error) 
        }
    }
}

export function getEvents(history?: any) {

    return async (dispatch: Dispatch<actionTypes.GetEventTypes>) => {

        try {
            dispatch(getEventsStarted());
            const result: any = await  eventsCollection.find().toArray();
            if (history) {
                if(result.length === 0) {
                    history.push('/setup')
                } 
            }
            dispatch(getEventsSuccess(result));
        } catch(error) {
            dispatch(getEventsFailed());
            bugsnagClient.notify(error) 
        }
    }
}

export function getUserSettings() {

    return async (dispatch: Dispatch<actionTypes.GetUserSettingTypes>) => {

        try {
            dispatch(getUserSettingsStarted());
            const array: any = await  userSettingsCollection.find().toArray();
            const userSettings = array[0]
            if (!array[0]) {
                throw Error('No UserSettings Available')
            }
            dispatch(getUserSettingsSuccess(userSettings));
        } catch(error) {
            bugsnagClient.notify(error) 
            dispatch(getUserSettingsFailed());
        }
    }
}

export function setOnboardingComplete(props: any) {
        return async (dispatch: Dispatch<actionTypes.OnboardingTypes>) => {
            try {
                dispatch(setOnboardingCompleteStarted())
                const user: any = auth.getCurrentUser();
                const event = {
                    owner_id: user.id,
                    onboardingComplete: true
                } 
                await userSettingsCollection.insertOne(event);
                const result: any = await userSettingsCollection.find().toArray();
                const userSettings = result[0];
                props.history.push('/')
                dispatch(setOnboardingCompleteSuccess(userSettings))
            } catch(error) {
                bugsnagClient.notify(error) 
                dispatch(setOnboardingCompleteFailed())
            }
        }
}

export function addEventToUserEvents(event: any) {
    const user: any = auth.getCurrentUser();
    Object.keys(event).forEach(key => event[key] === undefined ? delete event[key] : '');
    event.owner_id = user.id;

    return async (dispatch: Dispatch<actionTypes.AddEventTypes>) => {

        try {
            dispatch(addUserEventStarted());
            const result = await eventsCollection.insertOne(event);
            const newEvent: any = await eventsCollection.find({_id: result.insertedId}).toArray();
            dispatch(addUserEventSuccess(newEvent[0]));

        } catch(error) {
            bugsnagClient.notify(error) 
            dispatch(addUserEventFailed())
        }
    }
}

export function updateEvent(event: any) {
    return async (dispatch: Dispatch<actionTypes.UpdateEventTypes>) => {
        try {
            dispatch(updateUserEventStarted())
            const result: any = await eventsCollection.findOneAndUpdate({_id: event._id}, event)
            const updatedEvent: any = await eventsCollection.find({_id: result._id}).toArray();
            dispatch(updateUserEventSuccess(updatedEvent[0]))
        } catch(error) {
            dispatch(updateUserEventFailed());
            bugsnagClient.notify(error) 
        }
    }
}

export function addResults(events: any) {

    return async (dispatch: Dispatch<actionTypes.AddResultTypes>) => {
        try {
            dispatch(addResultsStarted());
            const user: any  = auth.getCurrentUser()
            let resultObject = {
                owner_id: user.id,
                projection: events
            }
            const result = await projectionsCollection.insertOne(resultObject);
            dispatch(addResultsSuccess([resultObject]));
        } catch(error) {
            dispatch(addResultsFailed())
            bugsnagClient.notify(error) 
        }
    }
}

export function updateResults(events: any){
    return async (dispatch: any) => {
        try {
            dispatch(updateResultsStarted());
            const user: any = auth.getCurrentUser();
            const id = store.getState().appState.results[0]._id;
            let resultObject = {
                owner_id: user.id,
                projection: events,
                _id: id
            }
            const result: any = await projectionsCollection.findOneAndReplace({_id: id}, resultObject, {returnNewDocument: true})
            dispatch(updateResultsSuccess(result));
        } catch(error) {
            dispatch(updateResultsFailed());
            bugsnagClient.notify(error) 
        }  
    }
}

export function deleteUserEvent(event: any, props?: any) {

    return async (dispatch: Dispatch<actionTypes.DeleteEventTypes>) => {
        try {
            dispatch(deleteUserEventStarted())
            const query = {_id: event._id}
            await eventsCollection.deleteOne(query);
            const events: Array<any> = await  eventsCollection.find().toArray();
            dispatch(deleteUserEventSuccess(events))
            if (props && store.getState().appState.events.length === 0) {
                props.history.push('/setup')
            }

        } catch(error) {
            bugsnagClient.notify(error) 
            dispatch(deleteUserEventFailed());
        }
    }
}


export function restoreSession(): actionTypes.SessionTypes {
    return {
        type: actionTypes.RESTORE_SESSION
    }
}

export const logout = () => {
    return (dispatch: Dispatch<actionTypes.LogoutTypes>) => {
        dispatch(logoutStarted());
        auth.logoutCurrentUser()
            .then(result => dispatch(logoutSuccess()))
            .catch(error => {
                bugsnagClient.notify(error) 
                dispatch(logoutFailed())
            });
    } 
} 

export const startLoading = (): actionTypes.LoadingTypes => {
    return {
        type: actionTypes.START_LOADING
    }
}

export const stopLoading = (): actionTypes.LoadingTypes => {
    return {
        type: actionTypes.STOP_LOADING
    }
}
