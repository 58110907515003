import React from 'react';
import { connect } from 'react-redux';
import { bugsnagClient } from '../../bugsnag/bugsnag';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getEvents, getResults, getUserSettings, startLoading, stopLoading } from '../../store/appState/actions/actionCreators';
import { login, registerNewAccount } from '../../stitch/authentication';
import {checkUserMobile} from '../../helpers/helpers';


import { Dialog } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes, AppState } from '../../store/store';
import { bindActionCreators } from 'redux';
import Login from './Forms/Login';
import Register from './Forms/Register';
import Email from './Forms/Email';
import SnackBar from '../SnackBar/SnackBar';

interface StateProps {
    loggedin: boolean;
}

interface DispatchProps {
    getEvents: (history: any) => void;
    getResults: () => void;
    getUserSettings: () => void;
    startLoading: () => void;
    stopLoading: () => void;
}

type Props = RouteComponentProps & DispatchProps & StateProps

const LoginDialog: React.FC<Props> = ({ 
    getEvents, 
    getResults,
    getUserSettings,
    startLoading,
    stopLoading,
    location, 
    history, 
    loggedin
}) => {

    const [which, whichSelection] = React.useState('register');
    const [email, emailSetter] = React.useState('');
    const [locationStateUsed, locationStateUsedSetter] = React.useState(false);
    const [snackBarState, snackBarStateSetter] = React.useState(false);
    const [hideDuration, hideSetter] = React.useState(6000);
    const [msg, msgSetter] = React.useState('hi');

    React.useEffect(() => {
        if(location.state && !locationStateUsed) {
            snackBarStateSetter(location.state.snackbar);
            msgSetter(location.state.message);
            hideSetter(location.state.duration);
            locationStateUsedSetter(true)
        } 
    }, []);

    const switchForm = (selection: string) => {
        whichSelection(selection);
    }

    const handleIncorrectInfo = async (message: string) => {
        msgSetter(message)
        snackBarStateSetter(true)
    }

    const showSnackBar = () => {
        if (snackBarState === true) {
            return <SnackBar message={msg} state={snackBarState} hideDuration={hideDuration} />
        }
    }

    const loginStateHandler = async (email: string, password: string) => {

        try {
            const result = await login(email, password)
            if (result.isLoggedIn) {
                startLoading();
                await getResults();
                await getUserSettings()
                await getEvents(history);
                stopLoading();
            } else {
                handleIncorrectInfo(result.message)
            }
        } catch (error) {
            bugsnagClient.notify(error)
        }
    }

    const registerAccount = async (values: any) => {
        try {
            const result = await registerNewAccount(values);
            debugger
            if (result) {
                handleIncorrectInfo('Email already in use')
            } else {
                debugger
                emailSetter(email);
                snackBarStateSetter(true)
                history.push({
                    pathname: '/setup',
                    state: {snackState: true, message: `We have sent an email to ${email} so we can confirm your email. Please open and confirm your email to complete registration. You can continue to use the application right now. 
                        However, if you leave the application you will need to verify your email before using the app again.`   
                    } 
                })
            }
            
                
        } catch(error) {
            bugsnagClient.notify(error)
        }
    }

    const form = () => {
        switch(which) {
            case 'login':
                return <Login 
                    loginStateHandler={loginStateHandler} 
                    switchForm={switchForm}
                    newAC={locationStateUsed}
                />
            case 'register':
                return <Register 
                    registerAccount={registerAccount} 
                    switchForm={switchForm}
                />
            case 'email':
                return <Email email={email} />
            default: 
                return <Login 
                        loginStateHandler={loginStateHandler} 
                        switchForm={switchForm}
                        newAC={locationStateUsed}
                    />
        }
    }

    return (

        <React.Fragment>
            <Dialog 
                fullScreen={checkUserMobile()}
                open={!loggedin}>
                {form()}
            </Dialog>
            {showSnackBar()}
        </React.Fragment>  
    )
}

const mapStateToProps = (state: AppState, ownProps: any): StateProps => {
    return {
        loggedin: state.appState.loggedin,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>, ownProps: any): DispatchProps => {
    return {
        getEvents: bindActionCreators(getEvents, dispatch),
        getResults: bindActionCreators(getResults, dispatch),
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch),
        getUserSettings: bindActionCreators(getUserSettings, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginDialog));