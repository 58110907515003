import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import * as Yup from 'yup';

import { Formik, Form, Field } from 'formik';
import { DialogTitle, DialogContent, Grid, DialogActions, Button } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { TextField } from 'formik-material-ui';

interface FuncProps extends RouteComponentProps {
    newAC: boolean;
    loginStateHandler: (email: string, password: string) => any;
    switchForm: (selection: string) => void
}

const Login: React.FC<FuncProps> = ({loginStateHandler, switchForm, history, newAC}) => {
    const initialValues = {
        email: '',
        password: ''
    }

    const validationSchema = Yup.object().shape({
        email: Yup
            .string()
            .email()
            .required('Please enter your email'),
        password: Yup
            .string()
            .required('Please enter your password')
    });

    return (
        <React.Fragment>
            <DialogTitle>Please Login</DialogTitle>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        loginStateHandler(values.email, values.password);
                        actions.resetForm();
                        actions.setSubmitting(false);
                    }}
                >
                    {({
                        isSubmitting, dirty, errors
                    }) => {
                        return (
                            <Form>
                                <DialogContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Field 
                                                name="email"
                                                label="Email"
                                                required={true}
                                                type="text"
                                                fullWidth
                                                component={TextField}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field 
                                                name="password"
                                                label="Password"
                                                required={true}
                                                type="password"
                                                fullWidth
                                                component={TextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Grid item xs={4}><Button 
                                            variant="outlined" 
                                            disabled={isSubmitting || !isEmpty(errors) || !dirty} 
                                            color="secondary" 
                                            type="submit">
                                        Login</Button>
                                    </Grid> 
                                    <Grid item xs={8}><Button 
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => switchForm('register')}
                                        type="button">
                                    Register for New Account</Button>
                                    </Grid> 
                                </DialogActions>
                            </Form>
                        )}}
                </Formik>
            </React.Fragment>)
}

export default withRouter(Login);