import React from 'react';
import { connect } from 'react-redux';
import { TableRow, TableCell, TableBody, Table, TableHead, Card, CardContent, Typography } from '@material-ui/core';
import { AppState } from '../../store/store';
import numeral from 'numeral';
import moment from 'moment';
import classes from './ProjectionsPage.module.css'
import _ from 'lodash';
import cardClasses from '../../components/UserEventCards/UserCards.module.css'

interface MyProps {
}

interface StateProps {
    events: Array<Result>
}

interface Result {
    _id: object;
    owner_id: string;
    projection: Array<any>
}

type Props = MyProps & StateProps;

const sectionHeaderStyle = {
    backgroundColor: '#F4F4F4'
} as React.CSSProperties;

const titleStyle = {
    minWidth: '200px',
    maxWidth: '400px',
    margin: 'auto',
    border: 'solid',
    borderColor: 'lightGrey',
    borderWidth: 'thin',
    boxSizing: "border-box"
} as React.CSSProperties;

const cellStyle = {
    minWidth: '100px',
    maxWidth: '200px',
    width: '5px',
    margin: 'auto',
    border: 'solid',
    borderColor: 'lightGrey',
    borderWidth: 'thin',
    textAlign: 'center',
    boxSizing: "border-box"
} as React.CSSProperties;

const positiveCellStyle = {
    minWidth: '100px',
    maxWidth: '200px',
    width: '5px',
    margin: 'auto',
    border: 'solid',
    borderColor: 'lightGrey',
    borderWidth: 'thin',
    textAlign: 'center',
    boxSizing: "border-box",
    color: "green"
} as React.CSSProperties;

const negativeCellStyle = {
    minWidth: '100px',
    maxWidth: '200px',
    width: '5px',
    margin: 'auto',
    border: 'solid',
    borderColor: 'lightGrey',
    borderWidth: 'thin',
    textAlign: 'center',
    boxSizing: "border-box",
    color: "red"
} as React.CSSProperties;

const positiveValueStyle = {
    color: "green"
} as React.CSSProperties;

const negativeValueStyle = {
    color: "red"
} as React.CSSProperties;

const handleCellStyleColor = (amount: number) => {
    if (amount > 0) {
        return positiveCellStyle
    } else if (amount < 0) {
        return negativeCellStyle
    } else {
        return cellStyle
    }
}

const handleMobileValueColor = (amount: number) => {
    if (amount > 0) {
        return positiveValueStyle
    } else if (amount < 0) {
        return negativeValueStyle
    } else {
        return
    }
}

const ProjectionsPageBasic: React.FC<Props> = ({events}) => {
    
    let projections: Array<any> = [];
    
    const getKeys = (subdomain: string, firstDomain?: string) => {
        let allKeys: Array<any> = []
        if (firstDomain) {
            projections.forEach(month => allKeys = allKeys.concat(Object.keys(month[firstDomain][subdomain])))
        } else {
            projections.forEach(month => allKeys = allKeys.concat(Object.keys(month[subdomain])))
        }
        const uniqueKeys = allKeys.filter((key, index) => allKeys.indexOf(key) === index && key !== 'sum');
        return uniqueKeys;
    }
    let funnels: any = [];

    const createFunnelSection = (funnel: string) => {
       const funnelComponents = projections[0].funnels[funnel].map((value:any) => value.name).filter((value: any, index: any, _arr: any) => _arr.indexOf(value) === index);
       
        return <React.Fragment>
            <TableRow>
                <TableCell style={sectionHeaderStyle} className={classes.stickyHeaderSection}><b>{_.upperFirst(funnel)}</b></TableCell>
                {projections.map((month, index) => <TableCell style={sectionHeaderStyle} className={classes.stickyHeaderSection} key={'header' + funnel +index} />)}
            </TableRow>
            {funnelComponents.filter((component: string) => component !== 'Customers' && component !== 'Subscribers').map((component: string) => <TableRow>
                <TableCell className={classes.firstColumnFixed}>{_.capitalize(component)}</TableCell>
                {projections.map((month, index) => <TableCell key={month.date} style={cellStyle}>
                    {month.funnels[funnel].filter((value: any) => value.name === component)[0].amount}
                </TableCell>)}
            </TableRow>)}
            {funnelComponents.filter((component: string) => component === 'Customers' || component === 'Subscribers').map((component: string) => <TableRow>
                <TableCell variant="head" className={classes.firstColumnFixed} style={titleStyle}>{_.capitalize(component)}</TableCell>
                {projections.map((month, index) => <TableCell key={month.date} style={cellStyle}>
                    <b>{month.funnels[funnel].filter((value: any) => value.name === component)[0].amount}</b>
                </TableCell>)}
            </TableRow>)}
              
        </React.Fragment>
    }

    const createSection = (domain: string, format: string, sign?: string) => {
        return getKeys(domain).map((key, keyIndex) => 
            <TableRow key={key+keyIndex}>
                <TableCell style={titleStyle} className={classes.firstColumnFixed}>{key}</TableCell>
                {projections.map((month, index) => 
                    <TableCell style={cellStyle} key={month.month+index}>
                        {sign}{numeral(projections[index][domain][key]).format(format)}
                    </TableCell>
                            )}
            </TableRow>
        )
    }

    if (events.length > 0) {
        projections = events[0].projection;
        funnels = getKeys('funnels');
    }

    const mobileCard = (event: any) => (
        <Card key={event.month.toString()} className={cardClasses.card} style={{backgroundColor: '#fffcfc'}}>
            <CardContent>
                <Typography variant="h6">{moment(event.month).format('MMM YYYY')}</Typography>
                <Typography variant="body2" color="textPrimary"><b>Revenue:</b> ${numeral(event.revenue.sum).format('0,0.00')}</Typography>
                <Typography variant="body2" color="textPrimary"><b>Expenses:</b> ${numeral(event.expenses.sum * -1).format('0,0.00')}</Typography>
                <Typography variant="body2" color="textPrimary"><b>Profit/Loss:</b> <span style={handleMobileValueColor(event.totals.gross)}> ${numeral(event.totals.gross).format('0,0.00')}</span></Typography>
                <Typography variant="body2" color="textPrimary"><b>Cash on Hand:</b> <span style={handleMobileValueColor(event.totals.runningTotal)}> ${numeral(event.totals.runningTotal).format('0,0.00')}</span></Typography>
            </CardContent>
        </Card>
    )
 
    return (<Table>
        <TableHead>
            <TableRow>
                <TableCell className={classes.stickyHeader}><b>Month</b></TableCell>
                {projections.map(event =>
                    <TableCell className={classes.stickyHeader} key={event.month.toString()}>
                        {moment(event.month).format('MMM YYYY')}
                    </TableCell>)}
            </TableRow>
        </TableHead>
        <TableBody>
            {funnels.map((funnel: string) => createFunnelSection(funnel))}
            <TableRow>
                <TableCell style={sectionHeaderStyle} className={classes.stickyHeaderSection} >
                    <b>Revenue</b>
                </TableCell>
                {projections.map((month, index) => <TableCell style={sectionHeaderStyle} key={'headerRevenue'+index} className={classes.stickyHeaderSection}/>)}
            </TableRow>
            {createSection('revenue', '0,00.00', '$')}
            <TableRow>
                <TableCell style={titleStyle} variant="head" className={classes.firstColumnFixed} >Revenue Totals</TableCell>
                    {projections.map((month, index) => <TableCell style={cellStyle} key={'sum'+index}><b>${numeral(projections[index]['revenue'].sum).format('0,0.00')}</b></TableCell>)}   
            </TableRow>
            <TableRow>
                <TableCell style={sectionHeaderStyle} className={classes.stickyHeaderSection}>
                    <b>Expenses</b>
                </TableCell>
                {projections.map((month, index) => <TableCell style={sectionHeaderStyle} key={'headerExpenses'+index} className={classes.stickyHeaderSection} />)}   
            </TableRow>
            {createSection('expenses', '0,00.00', '$')}
            <TableRow>
                <TableCell style={titleStyle} variant="head" className={classes.firstColumnFixed}>Expenses Totals</TableCell>
                    {projections.map((month, index) => <TableCell style={cellStyle} key={'sum'+index}><b>${numeral(projections[index]['expenses'].sum).format('0,0.00')}</b></TableCell>)}   
            </TableRow>
            <TableRow>
                <TableCell style={sectionHeaderStyle} className={classes.stickyHeaderSection}>
                    <b>Operating Profit/Loss</b>
                </TableCell>
                {projections.map((month, index) => <TableCell style={sectionHeaderStyle} key={'headerGross'+index} className={classes.stickyHeaderSection} />)}   
            </TableRow>
            <TableRow>
                <TableCell style={titleStyle} variant="head" className={classes.firstColumnFixed}>Gross</TableCell>
                {projections.map((event, index)  => <TableCell style={handleCellStyleColor(event.totals.gross)} key={index + 'gross'+ event.totals.gross}><b>${numeral(event.totals.gross).format('0,0.00')}</b></TableCell>)} 
            </TableRow>
            <TableRow>
                <TableCell style={titleStyle} variant="head" className={classes.firstColumnFixed}>Cash on Hand</TableCell>
                {projections.map((event, index)  => <TableCell style={handleCellStyleColor(event.totals.runningTotal)} key={index + 'runningTotal'+ event.totals.runningTotal}><b>${numeral(event.totals.runningTotal).format('0,0.00')}</b></TableCell>)} 
            </TableRow>
        </TableBody>
    </Table>
    )
}


const mapStateToProps = (state: AppState, ownProps: MyProps): StateProps => {
    return {
        events: state.appState.results
    }
}

export default connect(mapStateToProps, null)(ProjectionsPageBasic);