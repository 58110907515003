import * as actionTypes from '../actions/actionTypes';

export interface MonthResult {
    month: Date;
    traffic: number;
    views: number;
    abandoned: number;
    sales: number
    revenue: number
    expenses: number
    expenseItems: Array<ExpenseItem>
}

interface ExpenseItem {
    [key: string]: any;
}

export interface AppStateInitialState {
    loading: boolean;
    loggedin: boolean;
    eventsChanged: boolean;
    events: Array<any>;
    results: Array<any>;
    userSettings: UserSettings
}

interface UserSettings {
    _id: object;
    owner_id: string;
    onboardingComplete: boolean;
    registrationToken: string;
    tokenCreationTime: Date | undefined;
    emailVerified: boolean
}

const initialState: AppStateInitialState  = {
    loading: false,
    loggedin: false,
    eventsChanged: false,
    events: [],
    results: [],
    userSettings: {
        _id: {},
        owner_id: '',
        onboardingComplete: false,
        registrationToken: '',
        tokenCreationTime: undefined,
        emailVerified: false

    }
}

// Add Events

const addNewEvent = (state: AppStateInitialState, action: actionTypes.AddEventTypes) => {
    return {
        ...state,
        loading: false,
        eventsChanged: true,
        events: state.events.concat(action.payload)
    }
}

const startAddEvent = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: true
    }
}

const failAddEvent = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: false
    }
}

const updateEvent = (state: AppStateInitialState, action: actionTypes.UpdateEventTypes) => {
    return {
        ...state,
        loading: false,
        eventsChanged: true,
        events: state.events.filter(event => event._id.toString() !== action.payload._id.toString()).concat(action.payload)
    }
}

const updateEventStart = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: true
    }
}

const updateEventFail = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: false
    }
}
// Delete Event

const startDeleteEvent = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: true
    }
}

const failDeleteEvent = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: false
    }
}

const deleteEvent = (state: AppStateInitialState, action: actionTypes.AppStateActionTypes) => {
    return {
        ...state,
        loading: false,
        eventsChanged: true,
        events: action.payload
    }
}

// Get Event

const getEventsAdded = (state: AppStateInitialState, action: actionTypes.AppStateActionTypes) => {
    return {
        ...state,
        loading: false,
        events: action.payload
    }
}

const startGetEvents = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: true
    }
}

const failGetEvents = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: false
    }
}

// Auth

const authEventStart = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: true
    }
}

const failAuthEvent = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: false
    }
}

const registerSuccess = (state: AppStateInitialState) => {
    return {
        ...state,
        loading:false
    }
}

const successLogin = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: false,
        loggedin: true
    }
}

const successLogout = (state: AppStateInitialState) => {
    return initialState
}

const restoreSession = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: false,
        loggedin: true,
    }
}

const startLoading = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: true
    }
}

const stopLoading = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: false
    }
}

// Results

const getResults = (state: AppStateInitialState, action: actionTypes.GetResultTypes) => {
    return {
        ...state,
        results: action.payload
    }
} 

const addResult = (state: AppStateInitialState, action: actionTypes.AddResultTypes) => {
    return {
        ...state,
        loading: false,
        eventsChanged: false,
        results: [...state.results.filter(result => result._id === action.payload._id).concat(action.payload)]
    }
}

const startAddResult = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: true
    }
}

const failAddResult = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: false
    }
}

const updateResult = (state: AppStateInitialState, action: actionTypes.UpdateResultTypes) => {
    return {
        ...state,
        loading: false,
        eventsChanged: false,
        results: [...state.results.filter(result => result._id === action.payload._id).concat(action.payload)]
    }
}

const startUpdateResult = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: true
    }
}

const failUpdateResult = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: false
    }
}

const startOnboardingComplete = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: true
    }
}

const failOnboardingComplete = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: false
    }
}

const onboardingComplete = (state: AppStateInitialState, action: actionTypes.OnboardingTypes) => {
    return {
        ...state,
        userSettings: action.payload
    }
}

const getUserStarted = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: true
    }
}

const getUserFailed = (state: AppStateInitialState) => {
    return {
        ...state,
        loading: false
    }
}

const getUserSuccess = (state: AppStateInitialState, action: actionTypes.GetUserSettingTypes) => {
    return {
        ...state,
        userSettings: action.payload
    }
}


export const appStateReducer = (state = initialState, action: actionTypes.AppStateActionTypes): AppStateInitialState => {
    switch(action.type) {
        case actionTypes.ADD_USER_EVENT_SUCCESS:
            return addNewEvent(state, action);
        case actionTypes.ADD_USER_EVENT_STARTED:
            return startAddEvent(state);
        case actionTypes.ADD_USER_EVENT_FAILED:
            return failAddEvent(state);
        case actionTypes.UPDATE_USER_EVENT_STARTED:
            return updateEventStart(state);
        case actionTypes.UPDATE_USER_EVENT_FAILED:
            return updateEventFail(state);
        case actionTypes.UPDATE_USER_EVENT_SUCCESS:
            return updateEvent(state, action);
        case actionTypes.DELETE_USER_EVENT_STARTED:
            return startDeleteEvent(state);
        case actionTypes.DELETE_USER_EVENT_FAILED:
            return failDeleteEvent(state);
        case actionTypes.DELETE_USER_EVENT_SUCCESS:
            return deleteEvent(state, action);
        case actionTypes.GET_USER_EVENTS_STARTED:
            return startGetEvents(state);
        case actionTypes.GET_USER_EVENTS_FAILED:
            return failGetEvents(state)
        case actionTypes.GET_USER_EVENTS_SUCCESS:
            return getEventsAdded(state, action);
        case actionTypes.REGISTER_USER_STARTED:
            return authEventStart(state);
        case actionTypes.REGISTER_USER_FAILED:
            return failAuthEvent(state);
        case actionTypes.REGISTER_USER_SUCCESS:
            return registerSuccess(state);
        case actionTypes.LOGIN_USER_STARTED:
            return authEventStart(state);
        case actionTypes.LOGIN_USER_FAILED:
            return failAuthEvent(state);
        case actionTypes.LOGIN_USER_SUCCESS:
            return successLogin(state);
        case actionTypes.LOGOUT_USER_STARTED:
            return authEventStart(state);
        case actionTypes.LOGOUT_USER_FAILED:
            return failAuthEvent(state);
        case actionTypes.LOGOUT_USER_SUCCESS:
            return successLogout(state);
        case actionTypes.RESTORE_SESSION:
            return restoreSession(state);
        case actionTypes.START_LOADING:
            return startLoading(state);
        case actionTypes.STOP_LOADING:
            return stopLoading(state);
        case actionTypes.GET_RESULTS_SUCCESS:
            return getResults(state, action);
        case actionTypes.ADD_RESULTS_STARTED:
            return startAddResult(state);
        case actionTypes.ADD_RESULTS_FAILED:
            return failAddResult(state);
        case actionTypes.ADD_RESULTS_SUCCESS:
            return addResult(state, action);
        case actionTypes.UPDATE_RESULTS_STARTED:
            return startUpdateResult(state);
        case actionTypes.UPDATE_RESULTS_FAILED:
            return failUpdateResult(state);
        case actionTypes.UPDATE_RESULTS_SUCCESS:
            return updateResult(state, action);
        case actionTypes.SET_ONBOARDING_COMPLETE_STARTED:
            return startOnboardingComplete(state);
        case actionTypes.SET_ONBOARDING_COMPLETE_FAILED:
            return failOnboardingComplete(state);
        case actionTypes.SET_ONBOARDING_COMPLETE_SUCCESS:
            return onboardingComplete(state, action);
        case actionTypes.GET_USER_SETTINGS_STARTED:
            return getUserStarted(state);
        case actionTypes.GET_USER_SETTINGS_FAILED:
            return getUserFailed(state);
        case actionTypes.GET_USER_SETTINGS_SUCCESS:
            return getUserSuccess(state, action);
        default:
            return state
    }
}