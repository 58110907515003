import React from 'react';
import { SwipeableDrawer, List, ListItem, ListItemIcon, Icon, ListItemText, Divider, Typography } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import { logout } from '../../../store/appState/actions/actionCreators'
import logo from '../../../assets/thena_icon.svg';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter, NavLink } from 'react-router-dom';
import { ActionTypes } from '../../../store/store';
import { checkUserMobile } from '../../../helpers/helpers';
import { generateProjections } from '../../../stitch/functions';

interface FuncProps {
    state: boolean;
    toggleDrawer: () => void;
}

interface DispatchProps {
    logout: () => void;
}

type Props = FuncProps & DispatchProps & RouteComponentProps

const spreadsheetButton = (toggleDrawer: () => void) => {
    if (checkUserMobile()) {
        return
    } else {
        return (
            <NavLink
                style={{ textDecoration: 'none', color: 'black'}} 
                to='/projections'>
                <ListItem
                    button
                    onClick={toggleDrawer}
                >
                    <ListItemIcon><Icon>table_chart</Icon></ListItemIcon>
                    <ListItemText>Spreadsheet</ListItemText>
                </ListItem>
            </NavLink>
        )
    }
}

const runCalcButton = () => {
    if (process.env.NODE_ENV === 'development') {
        return <ListItem 
            style={{color: 'orange'}}
            button
            onClick={() => {
                generateProjections()
            }}>
            <ListItemIcon><Icon>replay</Icon></ListItemIcon>
            <ListItemText><b>Run Calc</b></ListItemText>
        </ListItem>
    } else {
        return null
    }
}

const Drawer: React.FC<Props> = ({state, toggleDrawer, logout, history}) => (

    <SwipeableDrawer
        open={state}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
    >
        <div style={{width: '250px', marginLeft: '10px'}}>
            <div style={{textAlign: 'center'}}>
                <img src={logo} width="75px" height="75px"/>
            </div>
            <List>
                <Typography variant="subtitle2">The Plan</Typography>
                <NavLink
                    style={{ textDecoration: 'none', color: 'black'}} 
                    to='/events'>
                    <ListItem
                        button
                        onClick={toggleDrawer}
                    >
                        <ListItemIcon><Icon>calendar_today</Icon></ListItemIcon>
                        <ListItemText>Timeline</ListItemText>
                    </ListItem>
                </NavLink>
                <Divider />
                <Typography variant="subtitle2" style={{marginTop:'10px'}}>Projections</Typography>
                <NavLink
                    style={{ textDecoration: 'none', color: 'black'}} 
                    to='/grid'>
                    <ListItem
                        button
                        onClick={toggleDrawer}
                    >
                        <ListItemIcon><Icon>grid_on</Icon></ListItemIcon>
                        <ListItemText>The Grid</ListItemText>
                    </ListItem>
                </NavLink>
                {spreadsheetButton(toggleDrawer)}
                <NavLink
                    style={{ textDecoration: 'none', color: 'black'}} 
                    to='/analytics'>
                    <ListItem
                        button
                        onClick={toggleDrawer}
                    >
                        <ListItemIcon><Icon>insert_chart_outlined</Icon></ListItemIcon>
                        <ListItemText>Analytics</ListItemText>
                    </ListItem>
                </NavLink>
                {runCalcButton()}
                <Divider />
                <ListItem
                    button
                    onClick={async () => {
                        logout();
                        history.push('/login')
                        toggleDrawer()
                    }}
                >
                    <ListItemIcon><Icon>exit_to_app</Icon></ListItemIcon>
                    <ListItemText>Log Out</ListItemText>
                </ListItem>
        

            </List>
        </div>
    </SwipeableDrawer>
)

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>, ownProps: FuncProps): DispatchProps => {
    return {
        logout: bindActionCreators(logout, dispatch),
    }
}

export default withRouter(connect(null, mapDispatchToProps)(Drawer));