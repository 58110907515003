import React from 'react';
import { IconButton, Icon } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {generateProjections} from '../../../stitch/functions';

interface Props extends RouteComponentProps {
    deleteHandler: (event: any) => void;
    event: any;
    editDisabled?: boolean;
    deleteDisabled?: boolean;
}

const ActionOptions: React.FC<Props> = ({deleteHandler, event, history, editDisabled, deleteDisabled}) => {

    if (!editDisabled) {
        editDisabled = false;
    }

    if (!deleteDisabled) {
        deleteDisabled = false;
    }

    return <React.Fragment>
        <IconButton 
            disabled={editDisabled}
            color="primary"
            onClick={() => history.push({
                pathname: `events/edit/${event._id.toString()}`,
                state: {event}
            })}
            >
            <Icon>edit</Icon>
        </IconButton>
        <IconButton
            disabled={deleteDisabled} 
            color="primary"
            onClick={async () => {
                await deleteHandler(event);
                generateProjections();
            }}
            >
            <Icon>delete</Icon>
        </IconButton>
    </React.Fragment>
}

export default withRouter(ActionOptions);