import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Card } from '@material-ui/core';
import { ActionTypes } from '../../store/store';
import { deleteUserEvent } from '../../store/appState/actions/actionCreators';
import { UserEventKinds } from '../../classes/types';

import classes from './UserCards.module.css';
import BasicCompanyCard from './BasicCompanyCard';
import CompanyCard from './CompanyCard';
import GenericCard from './GenericCard';
import GenericEmployeeCard from './GenericEmployeeCard';
import OneOffCostCard from './OneOffCostCard';
import OneProductCard from './OneProductCard';
import RecurringCostCard from './RecurringCostCard';
import FunnelCard from './FunnelCard';
import GenericProjectCard from './GenericProjectCard';
import AvgRevenueProductCard from './AvgRevenueProductCard';

interface MyProps extends RouteComponentProps {
    event: any
}

interface DispatchProps {
    deleteHandler: (event: any) => void;
}

type Props = MyProps & DispatchProps;

const UserEventCard: React.FC<Props> = ({event, deleteHandler, history}) => {

    const cardToDisplay = () => {
        switch(event.kind) {
            case UserEventKinds.account:
                return <CompanyCard deleteHandler={deleteHandler} event={event}/>
            case UserEventKinds.basicCompany:
                return <BasicCompanyCard deleteHandler={deleteHandler} event={event} history={history}/>
            case UserEventKinds.project_one_product_creation:
                return <OneProductCard deleteHandler={deleteHandler} event={event}/>
            case UserEventKinds.project_avgRevenue_product_creation:
                return <AvgRevenueProductCard deleteHandler={deleteHandler} event={event} />
            case UserEventKinds.genericEmployee:
                return <GenericEmployeeCard deleteHandler={deleteHandler} event={event}/>
            case UserEventKinds.recurringExpense:
                return <RecurringCostCard deleteHandler={deleteHandler} event={event}/>
            case UserEventKinds.oneOffExpense:
                return <OneOffCostCard deleteHandler={deleteHandler} event={event}/>
            case UserEventKinds.project_sales_funnel:
                return <FunnelCard deleteHandler={deleteHandler} event={event}/>
            case UserEventKinds.project_generic:
                return <GenericProjectCard deleteHandler={deleteHandler} event={event} />
            default: 
                return <GenericCard deleteHandler={deleteHandler} event={event}/>
        }
    }

    return (
        <Card className={classes.card} style={{backgroundColor: '#fffcfc'}}>
            {cardToDisplay()}
        </Card>
    )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>, ownProps: MyProps): DispatchProps => {
    return {
        deleteHandler: event => dispatch(deleteUserEvent(event, ownProps))
    }
}

export default withRouter(connect(null, mapDispatchToProps)(UserEventCard));