import React from 'react';
import { isEmpty } from 'lodash';
import { Formik, Form, FieldArray } from 'formik';
import * as config from '../../FormGenerator/formConfigs';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography} from '@material-ui/core';
import ArrayItemCard from '../FieldArray/ArrayItemCard';
import { inputHelper, inputArrayHelper } from '../../FormGenerator/fields';
import classes from '../OnboardingSteps.module.css';
import { checkUserMobile } from '../../../helpers/helpers';
import { FieldSize, UserEventKinds } from '../../../classes/types';
import * as Yup from 'yup';

interface FuncProps {
    next: (form: any, stepName: string) => void;
    back: () => void;
    close: () => void;
    currentStep: number;
    initialValues: any;
}

const validationSchema = Yup.object().shape({
    productName: Yup
        .string()
        .required('Please enter a name'),
    price: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Price must be > $0')
        .required('Please input a price'),
    productComponents: Yup.array(Yup.object().shape({
        componentName: Yup
            .string()
            .required('Please enter a name'),
        unitCost: Yup
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Cost must be > $0')
            .required('Please enter a cost'),
        componentType: Yup
            .mixed()
            .required('Please make a selection')
    }))
});

const OnboardingAverageRevenueDialog: React.FC<FuncProps> = ({next, back, close, currentStep, initialValues}) => { 

    const avgRevenueComponents = config.domains.filter(domain => domain.kind === 'avgRevenueComponents')[0];
    const thisStep = 3;
    const stepName = 'productConfig';

    const [componentPanel, setComponentPanel] = React.useState(0);
    const handleComponentPanel = (choice: number) => {
        setComponentPanel(choice);
    }


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                values.kind = UserEventKinds.project_avgRevenue_product_creation;
                next(values, stepName);
                actions.resetForm();
                actions.setSubmitting(false);
                close()

        }}
        >
            {({values, isSubmitting, dirty, errors}) => (
                <Dialog 
                    open={thisStep === currentStep}
                    onClose={close}
                    disableBackdropClick={true}
                    fullWidth={true}
                    fullScreen={checkUserMobile()}
                    maxWidth="md"
                >
                    <Form>
                        <DialogContent>
                            <Grid container>
                                <Typography variant="h6">On average, how much revenue do you expect to make per purchase?</Typography>
                                {inputHelper({
                                    name: 'productName',
                                    label: "Revenue Stream Name",
                                    input: 'text',
                                    required: true,
                                    size: FieldSize.six,
                                })}
                                {inputHelper({
                                    name: 'price',
                                    label: 'Average Purchase Amount',
                                    input: 'money',
                                    required: true,
                                    size: FieldSize.six
                                })}
                                <Grid item xs={12} />
                                <Grid item xs={12}><Typography variant="h6">What component costs are there to creating this product?</Typography></Grid>
                                <FieldArray 
                                    name="productComponents"
                                    render={arrayHelpers => (
                                        <div className={classes.array}>
                                            {values.productComponents.map((component: any, i: number) => (
                                                <ArrayItemCard 
                                                    remove={arrayHelpers.remove} 
                                                    add={arrayHelpers.push} 
                                                    form={avgRevenueComponents} 
                                                    array="productComponents"
                                                    index={i}
                                                    propsExpansion={componentPanel}
                                                    values={values}
                                                    pannelChoice={handleComponentPanel}
                                                />
                                            ))}
                                        </div>
                                    )}
                                />
                            </Grid> 
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                onClick={back}
                                color="secondary" 
                                type="button">
                                Back
                            </Button>
                            <Button 
                                disabled={
                                    isSubmitting || 
                                    !isEmpty(errors)
                                } 
                                color="primary" 
                                type="submit">
                                Next Step
                            </Button>
                        </DialogActions>
                    </Form>

                </Dialog>
            )}

        </Formik>
    )
}

export default OnboardingAverageRevenueDialog;