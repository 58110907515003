import React from 'react';

import {store} from '../../store/store'
import { RouteComponentProps, withRouter } from 'react-router';
import { Snackbar } from '@material-ui/core';
import { app } from '../../database/mongodb';
import { getUserSettings } from '../../store/appState/actions/actionCreators';

const RegisterUser: React.FC<RouteComponentProps> = ({history, location}) => {

    const params = new URLSearchParams(location.search);
    //@ts-ignore
    const token = params.get('token');
    const tokenId = params.get('tokenId');
    const [tokenSent, setTokenSent]= React.useState(false);


    if (token && tokenId && !tokenSent) {
        app.callFunction('handleRegLinkClicked',[token, tokenId])
            .then(res => {
                setTokenSent(true)
                history.push({
                    pathname: '/setup',
                    state: {
                        snackbar: true,
                        message: 'Email has been confirmed',
                        duration: 10000
                    }
                })
                // @ts-ignore
                store.dispatch(getUserSettings())
            })                
        .catch(error => { 
            history.push({
            pathname: '/login',
            state: {
                snackbar: true,
                message: error.message,
                duration: 1000000
            }
        })}) 
        
    }
    return (<div></div>)

}

export default withRouter(RegisterUser);