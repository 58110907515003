import React from 'react'
import { Button, Grid, Typography, IconButton, Icon } from '@material-ui/core';
import { inputHelper, inputArrayHelper } from '../../FormGenerator/fields';
import { FieldSize, BasicOnboardingExpenseOptions } from '../../../classes/types'; 
import { FieldArray } from 'formik';

interface FuncProps {
    values: any;
}

const BasicCompanyForm: React.FC<FuncProps> = ({values}) => (
    <Grid container>
        <Grid item xs={12} ><Typography variant="subtitle1"><b>What is your company name and when will it be founded?</b></Typography></Grid>
        {inputHelper({
            name: "companyName",
            label: "Company Name",
            input: 'text',
            required: true,
            size: FieldSize.six
        })}
        {inputHelper({ 
            name: "date",
            label: "Founded Date",
            input: 'date',
            required: true,
            size: FieldSize.six
        })}
        <Grid container>
            <Grid item xs={12} ><Typography variant="subtitle1"><b>Who is founding the company?</b></Typography></Grid>
                <FieldArray
                    name="founders"
                    render={arrayHelpers => (
                        <React.Fragment>
                            {values.founders.map((founder: any, i: number) => (<Grid container>
                                    {inputArrayHelper({
                                        name: 'name',
                                        label: 'Name',
                                        input: 'text',
                                        required: true,
                                        size: FieldSize.four
                                    }, 'founders', i)}
                                    {inputArrayHelper({
                                        name: 'compensation',
                                        label: 'Monthly Wage',
                                        input: 'money',
                                        required: false,
                                        size: FieldSize.four
                                    }, 'founders', i)}
                                    {inputArrayHelper({
                                        name: 'cashInvestment',
                                        label: 'Cash Investment',
                                        input: 'money',
                                        required: false,
                                        size: FieldSize.three
                                    }, 'founders', i)}
                                    <Grid item xs={1} style={{margin: 'auto'}}><Button onClick={() => arrayHelpers.remove(i)}><IconButton><Icon>close</Icon></IconButton></Button></Grid>
                                </Grid>)
                            )}
                            <Button onClick={() => arrayHelpers.push({})}variant="outlined" color='primary'>Add Team Member</Button>
                        </React.Fragment>
                    )}
                    />
                <Grid item xs={12} />
                <Grid item xs={12} ><Typography variant="subtitle1"><b>What are you selling and how much are you selling it for?</b></Typography></Grid>
                {inputHelper({
                    name: 'productName',
                    label: 'Service/Product Name',
                    input: 'text',
                    required: true,
                    size: FieldSize.six
                })}
                {inputHelper({
                    name: 'price',
                    label: 'Sale Price',
                    input: 'money',
                    required: true,
                    size: FieldSize.six
                })}
                <Grid item xs={12} ><Typography variant="subtitle1"><b>How many sales do you expect per month and when do you expect to start selling?</b></Typography></Grid>
                <Grid item xs={12} ><Typography color="textSecondary" variant="subtitle2">If your sales vary due to seasonality etc. you can add that later 🙂 (coming soon)</Typography></Grid>
                {inputHelper({
                    name: "amountOfSales",
                    label: "Amount",
                    input: 'number',
                    required: true,
                    size: FieldSize.six
                })}
                {inputHelper({
                    name: "salesStartDate",
                    label: "Date",
                    input: 'date',
                    required: true,
                    size: FieldSize.six
                })}
                <Grid item xs={12} ><Typography variant="subtitle1"><b>What costs are involved?</b></Typography></Grid>
                <FieldArray
                    name="costs"
                    render={arrayHelpers => (
                        <React.Fragment>
                            {values.costs.map((costs: any, i: number) => (<Grid container>
                                    {inputArrayHelper({
                                        name: 'expenseName',
                                        label: 'Name',
                                        input: 'text',
                                        required: true,
                                        size: FieldSize.four
                                    }, 'costs', i)}
                                    {inputArrayHelper({
                                        name: 'expenseAmount',
                                        label: 'Amount',
                                        input: 'money',
                                        required: true,
                                        size: FieldSize.four
                                    }, 'costs', i)}
                                    {inputArrayHelper({
                                        name: 'recurring',
                                        label: 'Recurring',
                                        input: 'select',
                                        options: BasicOnboardingExpenseOptions,
                                        required: true,
                                        size: FieldSize.three
                                    }, 'costs', i)}
                                    <Grid item xs={1} style={{margin: 'auto'}}><Button onClick={() => arrayHelpers.remove(i)}><IconButton><Icon>close</Icon></IconButton></Button></Grid>
                                </Grid>)
                            )}
                            <Button onClick={() => arrayHelpers.push({})}variant="outlined" color='primary'>Add Expense</Button>
                        </React.Fragment>
                    )}
                />
        </Grid>
    </Grid>
)

export default BasicCompanyForm;