import React from 'react';
import { Card, Icon, IconButton, CardHeader } from '@material-ui/core';
import ActionOptions from './ActionOptions/ActionOptions';


interface Props {
    event: any;
    deleteHandler: (event: any) => void;
}

const GenericCard: React.FC<Props> = (props) => {

    return (
    <Card>
        <CardHeader 
            action={<ActionOptions event={props.event} deleteHandler={props.deleteHandler} />}
        />
        {props.event.date}{props.event.startDate}
        <br></br>
        {props.event.kind}
    </Card>
    )
}

export default GenericCard;