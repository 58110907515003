import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store/store';
import _ from 'lodash';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import PieChart from '../../components/DataComponents/PieChart';
import RevExpLineGraph from '../../components/DataComponents/RevExpLineGraph';

interface MyProps {
}

interface StateProps {
    events: Array<Result>
}

interface Result {
    _id: object;
    owner_id: string;
    projection: Array<any>
}

type Props = MyProps & StateProps;

const AnalyticsPage: React.FC<Props> = ({events}) => {
    
    let projections: Array<any> = [];
    
    const getKeys = (subdomain: string, firstDomain?: string) => {
        let allKeys: Array<any> = []
        if (firstDomain) {
            projections.forEach(month => allKeys = allKeys.concat(Object.keys(month[firstDomain][subdomain])))
        } else {
            projections.forEach(month => allKeys = allKeys.concat(Object.keys(month[subdomain])))
        }
        const uniqueKeys = allKeys.filter((key, index) => allKeys.indexOf(key) === index && key !== 'sum');
        return uniqueKeys;
    }
    let funnels: any = [];

    if (events.length > 0) {
        projections = events[0].projection;
        funnels = getKeys('funnels');
    }

    const card = {
        width: '98%',
        backgroundColor: '#fffcfc',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#C9EAF8',
        marginBottom: '10px'
    }

    return <Grid container style={{width: '98%', margin: '1%'}}>
        <Grid item xs={12} md={4}>
            <Card style={card}>
                <CardContent>
                     {/* 
                    // @ts-ignore */}
                    <Typography style={{textAlign: 'center'}} variant="h6">Expenses Breakdown</Typography>
                    <PieChart projections={projections} type='expenses' color='blue'/>
                </CardContent>
            </Card>
           
        </Grid>
        <Grid item xs={12} md={4}>
            <Card style={card}>
                <CardContent>
                    <Typography style={{textAlign: 'center'}} variant="h6">Revenue Breakdown</Typography>
                    <PieChart projections={projections} type='revenue' color='warm'/>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} md={4}>
            <Card style={card}>
                <CardContent>
                    <Typography style={{textAlign: 'center'}} variant="h6">Revenue vs Expenses</Typography>
                    <RevExpLineGraph projections={projections} />
                </CardContent>
            </Card> 
        </Grid>
    </Grid>
}


const mapStateToProps = (state: AppState, ownProps: MyProps): StateProps => {
    return {
        events: state.appState.results
    }
}

export default connect(mapStateToProps, null)(AnalyticsPage);