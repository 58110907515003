import React from 'react';
import _ from 'lodash';

import {Field} from 'formik';
import { Grid, InputAdornment, MenuItem, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';

import { OptionSettings} from './formConfigs';
import ArrayOptions from './ArrayOptions/ArrayOptions';
import { DomainConfig } from './formConfigs';
import classes from './FormGenerator.module.css'
import { store } from '../../store/store';
import { UserEventKinds } from '../../classes/types';

export const createFieldArray = (domain: DomainConfig, values: any) => {
    return <div className={classes.array}><ArrayOptions domain={domain} values={values} /></div>
}

export const inputHelper: React.FC<OptionSettings> = (option) => {
    switch(option.input) {
        case('label'):
            return(
                <Grid item xs={12} sm={option.size} className={classes.field} key={option.name}>
                    <Typography variant="subtitle1">{option.label}</Typography>
                    <Typography variant="subtitle2" color="textSecondary">{option.helperText}</Typography>
                </Grid>
            )
        case('text'):
            return (
            <Grid item xs={12} sm={option.size} className={classes.field} key={option.name}>
                <Field
                    name={option.name} 
                    label={option.label} 
                    required={option.required}
                    placeholder={option.placeholder}
                    type="text" 
                    fullWidth  
                    margin="normal"
                    component={TextField}/>
            </Grid>)
        case('textarea'):
           return (
            <Grid item xs={12} sm={option.size} className={classes.field} key={option.name}>
               <Field 
                   name={option.name} 
                   label={option.label} 
                   required={option.required}
                   placeholder={option.placeholder}
                   type="text" 
                   multiline
                   margin="normal"
                   fullWidth  
                   component={TextField}/>
           </Grid>)
        case('number'):
            return (
            <Grid item xs={12} sm={option.size} className={classes.field} key={option.name}>
                <Field 
                    name={option.name} 
                    label={option.label} 
                    required={option.required} 
                    placeholder={option.placeholder}
                    type="number" 
                    fullWidth 
                    margin="normal"
                    component={TextField}/>
            </Grid>)
        case('date'):
            return (
            <Grid item xs={12} sm={option.size} className={classes.field} key={option.name}>
                <Field 
                    name={option.name} 
                    label={option.label} 
                    required={option.required} 
                    placeholder={option.placeholder}
                    fullWidth 
                    margin="normal"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    component={TextField}/>
            </Grid>)
        case('money'):
            return (
                <Grid item xs={12} sm={option.size} className={classes.field} key={option.name}>
                    <Field 
                        name={option.name} 
                        label={option.label} 
                        required={option.required} 
                        placeholder={option.placeholder}
                        fullWidth
                        margin="normal" 
                        type="number"
                        InputProps={{
                            min: 0,
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        
                        component={TextField}>
                    
                    </Field>
                </Grid>)
        case('percentage'):
            return (
                <Grid item xs={12} sm={option.size} className={classes.field} key={option.name}>
                    <Field 
                        name={option.name} 
                        label={option.label} 
                        required={option.required} 
                        placeholder={option.placeholder}
                        fullWidth 
                        margin="normal"
                        type="number"
                        InputProps={{
                            min: 0,
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        component={TextField}>
                    
                    </Field>
                </Grid>)
        case('hours'):
        return (
        <Grid item xs={12} sm={option.size} className={classes.field} key={option.name}>
            <Field 
                name={option.name} 
                label={option.label} 
                required={option.required} 
                placeholder={option.placeholder}
                fullWidth 
                margin="normal"
                type="number"
                component={TextField}>
            
            </Field>
        </Grid>)
        case('select'):
        return (
        <Grid item xs={12} sm={option.size} className={classes.field} key={option.name}>
            <Field 
                name={option.name} 
                select
                label={option.label} 
                required={option.required} 
                placeholder={option.placeholder} 
                type="text"
                fullWidth
                margin="normal"
                component={TextField}>
            {Object.entries(option.options as object).map(item => <MenuItem value={item[0]}>{item[1]}</MenuItem>)}
            </Field>
        </Grid>)
        default:
            return (
            <Grid item xs={12} sm={option.size} className={classes.field}>
                <Field name={option.name} label={option.label} required={option.required}  placeholder={option.placeholder} fullWidth margin="normal" type="text" component={TextField}/>
            </Grid>)
        
    }
}

export const inputArrayHelper = (option: OptionSettings, array: string, i: number) => {
    switch(option.input) {
        case('label'):
            return(
                <Grid item xs={12} sm={option.size} className={classes.field} key={option.name}>
                    <Typography variant="subtitle1">{option.label}</Typography>
                    <Typography variant="subtitle2" color="textSecondary">{option.helperText}</Typography>
                </Grid>
            )
        case('text'):
            return (
                <Grid item xs={12} sm={option.size} className={classes.field} key={`${array}[${i}].${option.name}`}>
                    <Field 
                        name={`${array}[${i}].${option.name}`} 
                        label={option.label} 
                        required={option.required}
                        type="text" 
                        margin="normal"
                        fullWidth  
                        component={TextField}/>
                </Grid>)
        case('number'):
            return (
                <Grid item xs={12} sm={option.size} className={classes.field} key={`${array}[${i}].${option.name}`}>
                    <Field 
                        name={`${array}[${i}].${option.name}`} 
                        label={option.label} 
                        required={option.required} 
                        type="number" 
                        margin="normal"
                        fullWidth 
                        component={TextField}/>
                </Grid>)
        case('date'):
            return (
                <Grid item xs={12} sm={option.size} className={classes.field} key={`${array}[${i}].${option.name}`}>
                    <Field 
                        name={`${array}[${i}].${option.name}`} 
                        label={option.label} 
                        required={option.required} 
                        fullWidth 
                        type="date"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        component={TextField}/>
                </Grid>)
        case('select'):
        if (option.options === 'improvVariables') {
            const ExpectationOptions = () => {
                const funnels: Array<any> = store.getState().appState.events.filter(event => event.kind === UserEventKinds.project_sales_funnel || UserEventKinds.basicCompany)
                const options: any = {}
                funnels.forEach(funnel => {
                    if (funnel.kind === UserEventKinds.project_sales_funnel) {
                        options[funnel.funnelInput] = funnel.funnelInput;
                        funnel.leadTypes.forEach((type: any) => options[type.leadName] = type.leadName)
                        options[funnel.funnelOutput] = _.capitalize(funnel.funnelOutput) + 's'
                    } else if (funnel.kind === UserEventKinds.basicCompany) {
                        options['customer'] = 'Sales'
                    }
                })
                return options
            }
            return (
                <Grid item xs={12} sm={option.size} className={classes.field}>
                    <Field 
                        name={`${array}[${i}].${option.name}`} 
                        select
                        label={option.label} 
                        required={option.required} 
                        placeholder={option.placeholder} 
                        type="text"
                        fullWidth
                        margin="normal"
                        component={TextField}>
                    {Object.entries(ExpectationOptions() as object).map(item => <MenuItem value={item[0]}>{item[1]}</MenuItem>)}
                    </Field>
                </Grid>
            )
        } else {
        return (
            <Grid item xs={12} sm={option.size} className={classes.field}>
                <Field 
                    name={`${array}[${i}].${option.name}`} 
                    select
                    label={option.label} 
                    required={option.required} 
                    placeholder={option.placeholder} 
                    type="text"
                    fullWidth
                    margin="normal"
                    component={TextField}>
                {Object.entries(option.options as object).map(item => <MenuItem value={item[0]}>{item[1]}</MenuItem>)}
                </Field>
            </Grid>)}
        case('money'):
            return (
                <Grid item xs={12} sm={option.size} className={classes.field} key={`${array}[${i}].${option.name}`}>
                    <Field 
                        name={`${array}[${i}].${option.name}`} 
                        label={option.label} 
                        required={option.required} 
                        placeholder={option.placeholder}
                        fullWidth
                        margin="normal" 
                        type="number"
                        InputProps={{
                            min: 0,
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        component={TextField}>
                    
                    </Field>
                </Grid>)
        case('percentage'):
            return (
                <Grid item xs={12} sm={option.size} className={classes.field} key={`${array}[${i}].${option.name}`}>
                    <Field 
                        name={`${array}[${i}].${option.name}`} 
                        label={option.label} 
                        required={option.required} 
                        placeholder={option.placeholder}
                        fullWidth 
                        margin="normal"
                        type="number"
                        InputProps={{
                            min: 0,
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        component={TextField}>
                    
                    </Field>
                </Grid>)
        case('hours'):
            return (
                <Grid item xs={12} sm={option.size} className={classes.field} key={`${array}[${i}].${option.name}`}>
                    <Field 
                        name={`${array}[${i}].${option.name}`} 
                        label={option.label} 
                        required={option.required} 
                        placeholder={option.placeholder}
                        fullWidth 
                        margin="normal"
                        type="number"
                        component={TextField}>
                    
                    </Field>
                </Grid>)
        default:
            return (
                <Grid item xs={12} sm={option.size} className={classes.field} key={`${array}[${i}].${option.name}`}>
                    <Field name={`${array}[${i}].${option.name}`}  label={option.label} required={option.required}  margin="normal" fullWidth type="text" component={TextField}/>
                </Grid>)
        
    }
}