import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import { appStateReducer } from './appState/reducers/reducers';
import {AppStateActionTypes} from '../store/appState/actions/actionTypes';

export type ActionTypes = AppStateActionTypes;

const rootReducer = combineReducers({
    appState: appStateReducer
});

export type AppState = ReturnType<typeof rootReducer>

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<AppState, ActionTypes>)));