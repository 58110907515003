
// Employee Stuff

export type compensationOrRateFrequency = "hourly" | 'daily' | 'weekly' | 'monthly' | 'annual';
export type invoiceFrequency = 'completion' | 'weekly' | 'monthly';
export enum PayrollFrequency {
    monthly = 'monthly',
    semi_monthly = 'semi-monthly'
}

// Expense Stuff

export type expenseTypes = "per customer" | "component" | "per month" | "per employee" | "one off"

// Task & Project Stuff

export enum ProjectDurationFrequency {
    weeks = 'weeks',
    months = 'months'
}

export enum acceptTerms {
    yes = 'Yes'
}

export enum ComponentType {
    percent = "Percentage of Revenue",
    fixedAmount = "Fixed Amount"
}

export enum MomentGranularity {
    days = 'days',
    months = 'months',
    weeks = 'weeks',
    years = 'years',
    day = 'day',
    month = 'month',
    week = 'week',
    year = 'year'
}

export enum TaskFrequencyAndAvailabilityType {
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
}

export enum RecurringExpense {
    monthly = 'monthly',
    annual = 'annual'
}

export enum inputField {
    MyField = 'MyField',
    MediumField = 'MediumField',
    MediumDateField = 'MediumDateField',
    bigField = 'bigField',
    numberField = 'numberField',
    mediumNumberField = 'mediumNumberField',
    dateField = 'dateField',
    textArea = 'textArea',
    selectField = 'selectField',
    percentageField = 'percentageField'
}

export type Options = TaskFrequencyAndAvailabilityType | PayrollFrequency;

// Tasks / Skills
export type ProductTaskType = 'design' | 'build' |'strategy' | "admin";
export type MarketingTaskType = "marketing";
export type WebStoreTaskType = "design" | "build";
export type SalesTaskType = 'lead generation' | "lead nuturing" |  "closing" ;
export type CustomerServiceTaskType = "customer service"
export type AdminTaskType = "admin";
export type ManagementTaskType = "managing";
export type StrategyTaskType = "strategy";
export type LeadershipTaskType = "leadership";
export type OperationsTaskType = "process" | "build" | "ship";
// export type ProductTaskType = 'design' | 'build' | 'manufacturing' | 'quality assurance' | 'testing' | 'market research' | 'strategy'
// export type MarketingTaskType = 'content creation' | 'graphic design' | 'video design' | 'written content' | 'social media' | 'paid ads' | 'event' | 'strategy'
// export type WebStoreTaskType = "design" | "market research" | "software development" | "website video" | "website photos" | "website content" | "build"
// export type SalesTaskType = "lead nuturing" | "prospecting" | "meetings/presentations" | "closing" | "data entry" | "phone calls" | 'lead generation' 
// export type CustomerServiceTaskType = "phone calls" | "emails" | "live chat" | "social media" | "questions" | "concerns"
// export type AdminTaskType = "payroll" | "hr" | "bookkeeping" | "accounting" | "money"
// export type ManagementTaskType = "managing"| "daily check in" | "one to one" | "quarterly reviews" | "annual review" | "manager's weekly meeting"
// export type StrategyTaskType = "strategy" | "random alignments" | "weekly meeting" | "quarterly meeting" | "annualy meeting"
// export type LeadershipTaskType = "leadership" | "investor meetings" | "investor updates" | "monthly tasks" | "board meetings" | "meetings"
export type TaskType = 
    ProductTaskType | 
    MarketingTaskType |
    WebStoreTaskType |
    SalesTaskType |
    CustomerServiceTaskType |
    AdminTaskType |
    ManagementTaskType |
    StrategyTaskType |
    OperationsTaskType |
    LeadershipTaskType;
    
// General Stuff

export enum FieldSize {
    one = 1,
    two = 2,
    three = 3,
    four = 4,
    five = 5,
    six = 6,
    seven = 7,
    eight = 8,
    nine = 9,
    ten = 10,
    eleven = 11,
    twelve = 12

}

export enum FormDomains {
    company = 'company',
    founders = 'founders'
}

export enum FormPosition {
    start = 'start',
    middle = 'middle',
    end = 'end'
}

export enum numberOneToTen {
    one = 1,
    two = 2,
    three = 3,
    four = 4,
    five = 5,
    six = 6,
    seven = 7,
    eight = 8,
    nine = 9,
    ten = 10
}
export enum DepartmentType {

    product = "product",
    marketing = "marketing",
    webstore = "webstore",
    sales = "sales",
    customer_service = "customer service",
    admin = "admin",
    leadership = "leadership",
    operations = "operations",
}

export enum UserEventKinds {
    account = 'account',
    basicCompany = 'basicCompany',
    base = 'base',
    project_product_creation = 'project_product_creation',
    project_one_product_creation = 'project_one_product_creation',
    project_avgRevenue_product_creation = 'project_avgRevenue_product_creation',
    project_sales_funnel = 'project_sales_funnel',
    project_generic = 'project_generic',
    project_marketingCampaign = 'project_marketingCampaign',
    oneOffExpense = 'oneOffExpense',
    recurringExpense = 'recurringExpense',
    genericEmployee = 'genericEmployee'
}
  
    

// Projects

export type ProjectType = 'product development' | 'marketing campaign' | 'leadership' | 'recurring';
export type ProductDesignStages  = 'prototype' | 'build';

export enum EventKindsPeople {
    founder = "founder",
    contractor = "contractor",
    investor = "investor",
    employee = "employee"
}

export enum MarketingChannels {
    facebook = 'Facebook',
    google = 'Google',
    instagram = 'Instagram'
}

export enum EventKindsProjects {
    timeline_project = "timeline_project",
    timeline_project_asset_creation = 'timeline_project_asset_creation',
    timeline_project_product_creation = "timeline_project_product_creation",
    timeline_project_salesFunnel = "timeline_project_salesFunnel", 
    timeline_project_marketingCampaign = "timeline_project_marketingCampaign"
}

export enum AssetKinds {
    product = 'asset_product'
}
export type EventKindsTasks =
    'task'|
    "recurring_task" |
    'task_product'|
    "task_management" |
    "task_strategy" |
    "task_marketing" |
    "task_web_store" |
    "task_sales" |
    "task_customer_service" |
    "task_admin" |
    "task_project_settings" |
    "task_operations" |
    "recurring_task_leadership" |
    "task_leadership";


export enum EventKindsGeneric {
    timelineEvent = 'timelineEvent',
    asset = 'asset',
    funnel_variable = 'funnel_variable',
    expectation_state = 'expectation_state',
    expectation = 'expectation',
    expectation_project = 'expectation_project',
    account = 'account',
    new_month = "new month",
    end_month = "end month",
    withdrawal = "withdrawal",
    item_created = 'item created',
    new_day = "new day"

}

export enum EventKindProject {
    expectation = 'expectation',
    expectation_project = 'expectation_project',
}


export enum EventKindsBank {
    credit = 'credit',
    debit = 'debit'
}

export enum EventKindContract {
    purchase = 'purchase'
}
    

export enum EventKindsExpense {
    expense = 'expense',
    recurring  = 'recurring',
    expense_product = 'expense_product',
    expense_product_component = 'expense_product_component'

}

export enum FunnelPersonas {
    input = 'input',
    lead = 'lead',
    customer = 'customer',
    subscriber = 'subscriber'
}

export enum CustomerTypes {
    customer = "Customer"
}

export enum SalesAssets { 
    sales_funnel = 'asset_salesFunnel',
    marketing_website = 'asset_marketingWebsite'
}

export enum ExpectationType {
    new = 'New Amount',
    percent_improvement = '% improvement'
}

export enum ExpectationRecurring {
    once = 'Once',
    monthly = "Every Month"
}

export enum ExpectationPermanence {
    yes = 'Yes',
    no = "No"
}


export enum ExpectationRampUp {
    atOnce = 'All at Once',
    gradually = "Gradually"
}

export enum BasicOnboardingExpenseOptions {
    oneOff = 'one off',
    monthly = "monthly",
    annually = "annually",
    perPurchase = 'per purchase'
}

export type EventKinds = 
    EventKindContract |
    EventKindsProjects |
    EventKindsPeople |
    EventKindsTasks | 
    FunnelPersonas |
    EventKindsGeneric |
    EventKindsBank |
    EventKindProject |
    EventKindsExpense;

export type AllKinds = EventKinds | AssetKinds | UserEventKinds | AssetKinds | SalesAssets;
