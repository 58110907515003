import React from 'react';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from './theme/theme';

import './App.css';

import Layout from './containers/Layout/Layout';

const App: React.FC = () => (<MuiThemeProvider theme={theme}><Layout /></MuiThemeProvider>)

export default App;
