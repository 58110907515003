import React from 'react';
import * as Yup from 'yup';

import { Formik, Form, Field } from 'formik';
import { DialogTitle, DialogContent, Grid, DialogActions, Button, Typography, Checkbox, FormControl, FormLabel, FormGroup, FormControlLabel } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { TextField } from 'formik-material-ui';
import { inputHelper } from '../../FormGenerator/fields';
import { FieldSize, acceptTerms } from '../../../classes/types';

interface FuncProps {
    registerAccount: (values: any) => void;
    switchForm: (selection: string) => void
}

const Register: React.FC<FuncProps> = ({registerAccount, switchForm}) => {

    const initialValues = {
        email: '',
        password: '',
        confirmPassword: '',
        company: '',
        industry: '',
        firstName: '',
        lastName: '',
        acceptTerms: ''
    }


    const validationSchema = Yup.object().shape({
        firstName: Yup
            .string()
            .required('Please enter your first name'),
        lastName: Yup
            .string()
            .required('Please enter your last name'),
        company: Yup
            .string()
            .required('Please enter your company name'),
        industry: Yup
            .string()
            .required('Please tell us what your company does'),
        email: Yup
            .string()
            .required('Please enter your email'),
        password: Yup
            .string()
            .min(8, 'at least 8 chars')
            .matches(/[a-z]/, 'at least one lowercase char')
            .matches(/[A-Z]/, 'at least one uppercase char')
            .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, 'at least 1 number or special char (@,!,#, etc).')
            .required('Please enter your password'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), ''], 'Passwords must match')
            .required('Passwords must match'),
        acceptTerms: Yup
            .mixed()
            .required('You must accept to create an account')
    });

    return (
        <React.Fragment>
            <DialogTitle>{`Please register for an account`}</DialogTitle>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        registerAccount(values)
                        actions.resetForm();
                        actions.setSubmitting(false);
                    }}
                >
                    {({
                        isSubmitting, dirty, errors
                    }) => {
                        return (
                            <Form>
                                <DialogContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Field 
                                                name="firstName"
                                                label="First Name"
                                                required={true}
                                                type="text"
                                                fullWidth
                                                component={TextField}
                                            />
                                            <Field 
                                                name="lastName"
                                                label="Last Name"
                                                required={true}
                                                type="text"
                                                fullWidth
                                                component={TextField}
                                            />
                                            <Field 
                                                name="company"
                                                label="Company Name"
                                                required={true}
                                                type="text"
                                                fullWidth
                                                component={TextField}
                                            />
                                            <Field 
                                                name="industry"
                                                label="What does your company do?"
                                                required={true}
                                                type="text"
                                                fullWidth
                                                component={TextField}
                                            />
                                            <Field 
                                                name="email"
                                                label="Email"
                                                required={true}
                                                type="text"
                                                fullWidth
                                                component={TextField}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field 
                                                name="password"
                                                label="Password"
                                                required={true}
                                                type="password"
                                                fullWidth
                                                component={TextField}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field 
                                                name="confirmPassword"
                                                label="Confirm Password"
                                                required={true}
                                                type="password"
                                                fullWidth
                                                component={TextField}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <br></br>
                                            <Typography variant="body1">Do you agree with our <a href="https://www.thena.app/terms-of-service/" target="_blank" rel="noopener noreferrer">terms of service</a> and <a href="https://app.termly.io/document/privacy-notice/2efd9f4a-9d36-42b5-9a00-c6c8f3a370de" rel="noopener noreferrer" target="_blank">privacy policy</a>?</Typography>
                                            {inputHelper({
                                                    name: 'acceptTerms',
                                                    label: '',
                                                    input: 'select',
                                                    options: acceptTerms,
                                                    required: true,
                                                    size: FieldSize.four
                                                })}
                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    <Typography variant="body1">Password must have:</Typography>
                                    <Typography variant="body2">- at least 8 characters</Typography>
                                    <Typography variant="body2">- 1 lowercase character</Typography>
                                    <Typography variant="body2">- 1 uppercase characters</Typography>
                                    <Typography variant="body2">- at least 1 number or special char (@,!,#, etc).</Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Grid item xs={8}><Button 
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => switchForm('login')}
                                        type="button">
                                    Login to Existing Account</Button>
                                    </Grid> 
                                    <Grid item xs={4}><Button 
                                            variant="outlined" 
                                            disabled={isSubmitting || !isEmpty(errors) || !dirty} 
                                            color="secondary" 
                                            type="submit">
                                        Register!</Button>
                                    </Grid> 
                                </DialogActions>
                            </Form>
                        )}}
                </Formik>
            </React.Fragment>
    )
}


export default Register;