import React from 'react';
import { isEmpty } from 'lodash';
import { Formik, Form, FieldArray } from 'formik';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, CardContent, Card, IconButton, Icon } from '@material-ui/core';
import { inputHelper, inputArrayHelper } from '../FormGenerator/fields';
import { basicCompanyValidation } from '../FormGenerator/formConfigs';
import { FieldSize, BasicOnboardingExpenseOptions } from '../../classes/types';
import * as Yup from 'yup';
import {checkUserMobile} from '../../helpers/helpers';

interface FuncProps {
    next: (form: any, stepName: string) => void;
    submitBasic: (form: any, stepName: string) => void;
    close: () => void;
    currentStep: number;
    initialValues: any;
}

const cardStyle = {
    margin: 'auto', 
    width: '97%',
    backgroundColor: '#fffcfc',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#C9EAF8',
    marginBottom: '5px'
}

const cardContent = {
    padding: "4px",
    "&:last-child": {
    paddingBottom: 0
    }
}

const OnboardingBasicDialog: React.FC<FuncProps>= ({submitBasic, close, currentStep, next, initialValues}) => {

    const thisStep = 0;
    const stepName = 'basicSetup';

    return(
        <Formik
            initialValues={initialValues}
            validationSchema={basicCompanyValidation}
            onSubmit={(values, actions) => {
                submitBasic(values, stepName);
                actions.resetForm();
                actions.setSubmitting(false);
                close()

            }}
        >
            {({ values, isSubmitting, errors }) => (
                <Dialog
                    open={currentStep === thisStep}
                    onClose={close}
                    disableBackdropClick={true}
                    fullWidth={true}
                    fullScreen={checkUserMobile()}
                    maxWidth="md"
                >
                    <Form>
                        <DialogTitle>
                            5 quick questions to generate your projections
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Card style={cardStyle}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12} ><Typography variant="subtitle1"><b>What is your company name and when will it be founded?</b></Typography></Grid>
                                            {inputHelper({
                                                name: "companyName",
                                                label: "Company Name",
                                                input: 'text',
                                                required: true,
                                                size: FieldSize.six
                                            })}
                                            {inputHelper({ 
                                                name: "date",
                                                label: "Founded Date",
                                                input: 'date',
                                                required: true,
                                                size: FieldSize.three
                                            })}
                                        </Grid>  
                                    </CardContent>
                                </Card>
                                <Card style={cardStyle}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12} ><Typography variant="subtitle1"><b>Who is founding the company?</b></Typography></Grid>
                                            <Grid item xs={12} ><Typography color="textSecondary" variant="subtitle2">You need at least <b style={{color: 'black'}}>one</b> 🙂</Typography></Grid>
                                            <FieldArray
                                                name="founders"
                                                render={arrayHelpers => (
                                                    <React.Fragment>
                                                        
                                                            {values.founders.map((founder: any, i: number) => (<Card style={cardStyle}>
                                                            <CardContent style={cardContent}>
                                                                <Grid container>
                                                                    {inputArrayHelper({
                                                                        name: 'name',
                                                                        label: 'Name',
                                                                        input: 'text',
                                                                        required: true,
                                                                        size: FieldSize.four
                                                                    }, 'founders', i)}
                                                                    {inputArrayHelper({
                                                                        name: 'compensation',
                                                                        label: 'Monthly Wage',
                                                                        input: 'money',
                                                                        required: false,
                                                                        size: FieldSize.four
                                                                    }, 'founders', i)}
                                                                    {inputArrayHelper({
                                                                        name: 'cashInvestment',
                                                                        label: 'Cash Investment',
                                                                        input: 'money',
                                                                        required: false,
                                                                        size: FieldSize.three
                                                                    }, 'founders', i)}
                                                                    <Grid item xs={1} style={{margin: 'auto'}}><Button onClick={() => arrayHelpers.remove(i)}><IconButton><Icon>close</Icon></IconButton></Button></Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>))}
                                                        <Grid item xs={12}><Button onClick={() => arrayHelpers.push({})}variant="outlined" color='primary'>Add Team Member</Button></Grid>
                                                    </React.Fragment>
                                                )}
                                                />
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card style={cardStyle}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12} />
                                            <Grid item xs={12} ><Typography variant="subtitle1"><b>What are you selling and how much are you selling it for?</b></Typography></Grid>
                                            {inputHelper({
                                                name: 'productName',
                                                label: 'Service/Product Name',
                                                input: 'text',
                                                required: true,
                                                size: FieldSize.six
                                            })}
                                            {inputHelper({
                                                name: 'price',
                                                label: 'Sale Price',
                                                input: 'money',
                                                required: true,
                                                size: FieldSize.six
                                            })}
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card style={cardStyle}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12} ><Typography variant="subtitle1"><b>How many sales do you expect per month and when do you expect to start selling?</b></Typography></Grid>
                                                <Grid item xs={12} ><Typography color="textSecondary" variant="subtitle2">If your sales vary due to seasonality etc. you can add that later 🙂 (coming soon)</Typography></Grid>
                                                {inputHelper({
                                                    name: "amountOfSales",
                                                    label: "Amount",
                                                    input: 'number',
                                                    required: true,
                                                    size: FieldSize.six
                                                })}
                                                {inputHelper({
                                                    name: "salesStartDate",
                                                    label: "Date",
                                                    input: 'date',
                                                    required: true,
                                                    size: FieldSize.three
                                                })}
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card style={cardStyle}>
                                    <CardContent>
                                        <Grid item xs={12} ><Typography variant="subtitle1"><b>What costs are involved?</b></Typography></Grid>
                                            <FieldArray
                                                name="costs"
                                                render={arrayHelpers => (
                                                    <React.Fragment>
                                                        {values.costs.map((costs: any, i: number) => (<Card style={cardStyle}>
                                                            <CardContent style={cardContent}>
                                                                <Grid container>
                                                                        {inputArrayHelper({
                                                                            name: 'expenseName',
                                                                            label: 'Name',
                                                                            input: 'text',
                                                                            required: true,
                                                                            size: FieldSize.four
                                                                        }, 'costs', i)}
                                                                        {inputArrayHelper({
                                                                            name: 'expenseAmount',
                                                                            label: 'Amount',
                                                                            input: 'money',
                                                                            required: true,
                                                                            size: FieldSize.four
                                                                        }, 'costs', i)}
                                                                        {inputArrayHelper({
                                                                            name: 'recurring',
                                                                            label: 'Recurring',
                                                                            input: 'select',
                                                                            options: BasicOnboardingExpenseOptions,
                                                                            required: true,
                                                                            size: FieldSize.three
                                                                        }, 'costs', i)}
                                                                        <Grid item xs={1} style={{margin: 'auto'}}><Button onClick={() => arrayHelpers.remove(i)}><IconButton><Icon>close</Icon></IconButton></Button></Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>))}
                                                        <Button style={{marginLeft: '1%'}}onClick={() => arrayHelpers.push({})}variant="outlined" color='primary'>Add Cost</Button>
                                                    </React.Fragment>
                                                )}
                                            />
                                    </CardContent>
                                </Card>  
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                onClick={() => next({}, stepName)}
                                color="secondary" 
                                type="button">
                                Advanced Setup
                            </Button> 
                            <Button 
                                disabled={
                                    isSubmitting || 
                                    !isEmpty(errors)
                                } 
                                color="primary" 
                                type="submit">
                                Get Projections
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}
        </Formik>
    )
}

export default OnboardingBasicDialog;