import React from 'react';
import { Grid} from '@material-ui/core';
import { inputHelper } from '../../FormGenerator/fields';
import { FieldSize } from '../../../classes/types'; 

interface FuncProps {
    values: any;
}

const BasicRampUpForm: React.FC<FuncProps> = () => {
    return <Grid container>
        {inputHelper({
            name: "expectations[0].rampUpMonths",
            label: "Number of Months",
            input: 'number',
            required: true,
            size: FieldSize.six
        })}
    </Grid>
}

export default BasicRampUpForm;