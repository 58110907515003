import { Stitch, StitchAppClient } from "mongodb-stitch-browser-sdk";
import { RemoteMongoClient } from "mongodb-stitch-browser-sdk";

const APP_ID = "stitchfinancemodel-kbvql";

export const app: StitchAppClient = Stitch.hasAppClient(APP_ID) 
    ? Stitch.getAppClient(APP_ID)
    : Stitch.initializeAppClient(APP_ID);

const mongoClient: RemoteMongoClient = app.getServiceClient(RemoteMongoClient.factory, "mongodb-finance-model")

export const eventsCollection = mongoClient.db("finance-model").collection("events");

export const projectionsCollection = mongoClient.db("finance-model").collection("projections");

export const userSettingsCollection = mongoClient.db("finance-model").collection("userSettings");