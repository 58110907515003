import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import {monthFormat} from '../../helpers/helpers';

import { CardHeader, CardContent, Typography, Grid, Divider, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ActionOptions from './ActionOptions/ActionOptions';
import classes from './UserCards.module.css';
import { ProductCreateEventSettings } from '../../classes/userEvents';

interface Props {
    event: ProductCreateEventSettings;
    deleteHandler: (event: ProductCreateEventSettings) => void;
}

const AvgRevenueProductCard: React.FC<Props> = ({event, deleteHandler}) => {

    const title = () => {
        let avgRev = false
        event.productComponents.forEach(component => component.componentType ? avgRev = true : avgRev = false)

        if (avgRev) {
            return 'Revenue Steam: ' + _.startCase(event.productName) + ' Created'
        } else {
            return 'Product: ' + _.startCase(event.productName) + ' Created'
        }
    }

    const componentAmount = (component: any, price: number) => {
        if (component.componentType === 'percent') {
            return price * component.unitCost / 100
        } else return component.unitCost
    }

    return (
        <React.Fragment>
            <CardHeader 
                title={title()} 
                subheader={moment(event.date).format('DD MMMM YYYY')}
                action={<ActionOptions event={event} deleteHandler={deleteHandler} />}
            />
            <CardContent>
                <Typography variant="body1"><b>Project Duration: </b>{numeral(event.duration).format(monthFormat(event.duration))} {event.durationFrequency}</Typography>
                <Typography variant="body1"><b>Project End Date: </b>
                    {moment(event.date).add(event.duration, event.durationFrequency).format('DD MMMM YYYY')}
                </Typography>
                <br></br>
                <Divider />
                <Typography variant="h6">Project Costs</Typography>
                <Grid container>
                    {event.projectCosts.map(cost => 
                    <Grid item xs={12} key={cost.expenseSetupName}>
                         <ExpansionPanel className={classes.arrayCard}>
                                <ExpansionPanelSummary>
                                    <Typography variant="body1"><b>Name: </b>{cost.expenseSetupName}</Typography>  
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="body1"><b>Amount: </b>${numeral(cost.amount).format('0,0.00')}</Typography>
                                    </Grid>
                                </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                    </Grid>)}
                </Grid>
                <Typography variant="h5">Product</Typography>
                <Typography variant="body1"><b>Price: </b> ${numeral(event.price).format('0,0.00')}</Typography>

                <Typography variant="h6">Product Components</Typography>
                <Grid container>
                    {event.productComponents.map(component => 
                    <Grid item xs={12} key={component.componentName}>
                         <ExpansionPanel className={classes.arrayCard}>
                                <ExpansionPanelSummary>
                                    <Typography variant="body1"><b>Name: </b>{component.componentName}</Typography>  
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="body1"><b>Amount: </b>${numeral(componentAmount(component, event.price)).format('0,0.00')}</Typography>
                                    </Grid>
                                </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                    </Grid>)}
                </Grid>
            </CardContent>
        </React.Fragment>
    )

}

export default AvgRevenueProductCard;