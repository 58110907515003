import React from 'react'
import { Button, Grid, Icon, IconButton, Typography, Card, CardContent } from '@material-ui/core';
import { inputHelper, inputArrayHelper } from '../../FormGenerator/fields';
import { FieldSize, CustomerTypes, RecurringExpense } from '../../../classes/types'; 
import { checkUserMobile } from '../../../helpers/helpers';
import { FieldArray } from 'formik';
import _ from 'lodash';
import ArrayItemCard from '../../FormGenerator/ArrayItemCard';

interface FuncProps {
    values: any;
}

const inputStyle = {
    width: '90%',
    height: '35px',
    margin: 'auto',
    border: 'solid',
    borderColor: 'white',
    backgroundColor: '#1976D2',
    textAlign: 'center',
    padding: 'auto',
    color: 'white',
    borderRadius: '15px'
} as React.CSSProperties

const cardStyle = {
    margin: 'auto', 
    width: '100%',
    backgroundColor: '#fffcfc',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#C9EAF8',
    marginBottom: '5px'
} as React.CSSProperties

const leadStyle = (i: number, arrayLength: number) => {
    const width = (45 + (45/(arrayLength+1)*(arrayLength-i))).toString().concat('%')
   return {
    width,
    height: '35px',
    margin: 'auto',
    backgroundColor: '#C9EAF8',
    border: 'solid',
    borderColor: 'white',
    textAlign: 'center',
    padding: 'auto',
    borderRadius: '15px'
   } as React.CSSProperties
} 

const outputStyle = {
    width: '45%',
    height: '35px',
    padding: '1px',
    margin: 'auto',
    textAlign: 'center',
    backgroundColor: 'orange',
    border: 'solid',
    borderColor: 'white',
    borderRadius: '15px'
} as React.CSSProperties

const leadInfo = (values: any) => {
    if (values.leadTypes) {
        return values.leadTypes.map((type:any, i:number) => <Grid key={type+i} xs={12}><div style={leadStyle(i, values.leadTypes.length)}> {type.leadName ? _.upperFirst(type.leadName) : null }</div></Grid>)
    } else {
        return
    }
}

let gridWidthForDisplay = 6;

const hideFunnelOnMobile = (values: any) => {
    if (!checkUserMobile()) {
        //@ts-ignore
        return <Grid item xs={gridWidthForDisplay} style={{margin: 'auto'}}>
            <Grid container>
                <Grid item xs={12}><div style={inputStyle}> {_.capitalize(values.funnelInput)}</div></Grid>
                {leadInfo(values)}
                <Grid item xs={12}><div style={outputStyle}> {_.capitalize(values.funnelOutput)}</div></Grid>
            </Grid>
        </Grid>
    } else {
        gridWidthForDisplay = 12;
        return
    }
}

const SalesFunnelForm: React.FC<FuncProps> = ({values}) => (
    <Grid container>
        <Card style={cardStyle}>
            <CardContent>
                <Grid container>
                    {inputHelper({
                        name: 'projectName',
                        label: "Funnel Name",
                        input: 'text',
                        required: true,
                        size: FieldSize.six,
                    })}
                </Grid>

            </CardContent>
        </Card>
        <Card style={cardStyle}>
            <CardContent>
                <Grid container>
                {/*
                //@ts-ignore */}
                <Grid item xs={6}>
                    <Grid container>
                        {inputHelper({
                            name: "funnelInput",
                            label: "Funnel Input",
                            input: 'text',
                            required: true,
                            size: FieldSize.six,
                        })}
                        <Grid item xs={6}/>
                        <FieldArray
                            name="leadTypes"
                            render={arrayHelpers => (
                                <React.Fragment>
                                    {values.leadTypes && values.leadTypes.length > 0 ? (
                                        values.leadTypes.map((lead: any, i: number) => 
                                        <React.Fragment>
                                        {inputArrayHelper({
                                            name: 'leadName',
                                            label: 'Tier Name',
                                            input: 'text',
                                            required: true,
                                            size: FieldSize.six
                                        }, 'leadTypes', i)}
                                        {inputArrayHelper({
                                            name: 'conversionRate',
                                            label:  "Percentage of " + _.capitalize(values.funnelInput),
                                            input: 'percentage',
                                            required: true,
                                            size: FieldSize.five
                                        }, 'leadTypes', i )}
                                        <Grid item xs={1}>
                                            <Button color="secondary" onClick={() => arrayHelpers.remove(i)}><Icon>close</Icon></Button>
                                            {i + 1 === values.leadTypes.length ? <Button color="primary" onClick={() => arrayHelpers.push({})}><Icon>add</Icon></Button> : null}
                                            </Grid>
                                    </React.Fragment>
                                        )):(
                                        <Grid item xs={12}>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                onClick={() => arrayHelpers.push({})}
                                            >Add Lead Tier
                                            </Button>
                                        </Grid>

                                        )}
                                </React.Fragment>)}
                        />
                        {inputHelper({
                            name: "funnelOutput",
                            label: "Funnel Output",
                            input: 'select',
                            options: CustomerTypes,
                            required: true,
                            size: FieldSize.six
                        })}
                        {inputHelper({
                            name: "funnelOutputRate",
                            label:  "Percentage of " + _.capitalize(values.funnelInput),
                            input: 'percentage',
                            required: true,
                            size: FieldSize.six,
                        })}
                    </Grid>
                </Grid>
                {hideFunnelOnMobile(values)}
            </Grid> 
            </CardContent>
        </Card>
        
        <Card style={cardStyle}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} style={{marginTop: '15px'}}><Typography variant="subtitle1"><b>Setup Costs</b></Typography></Grid>
                    <FieldArray 
                        name="projectCosts"
                        render={arrayHelpers => (
                            <React.Fragment>
                                {values.projectCosts.map((cost: any, i: number) => 
                                    <Card style={{width: '100%', marginTop: '5px'}}>
                                        <CardContent>
                                            <Grid container>
                                                {inputArrayHelper({
                                                    name: 'expenseSetupName',
                                                    label: 'Name',
                                                    input: 'text',
                                                    required: true,
                                                    size: FieldSize.six
                                                }, 'projectCosts', i)}
                                                {inputArrayHelper({
                                                    name: 'amount',
                                                    label: 'Amount',
                                                    input: 'text',
                                                    required: true,
                                                    size: FieldSize.five
                                                }, 'projectCosts', i)}
                                                <Grid item xs={1} style={{margin: 'auto'}}><Button onClick={() => arrayHelpers.remove(i)}><IconButton><Icon>close</Icon></IconButton></Button></Grid>
                                            </Grid>

                                        </CardContent>
                                    </Card>)}
                                    <Button style={{marginTop: '5px'}} onClick={() => arrayHelpers.push({})}variant="outlined" color='primary'>Add Setup Cost</Button>
                            </React.Fragment>)}
                        />
                    </Grid> 
            </CardContent>
        </Card>
        <Card style={cardStyle}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} style={{marginTop: '15px'}}><Typography variant="subtitle1"><b>Ongoing Costs</b></Typography></Grid>
                    <FieldArray 
                        name="ongoingCosts"
                        render={arrayHelpers => (
                            <React.Fragment>
                                {values.ongoingCosts.map((cost: any, i: number) => 
                                    <Card style={{width: '100%', marginTop: '5px'}}>
                                        <CardContent>
                                            <Grid container>
                                                {inputArrayHelper({
                                                    name: 'expenseOngoingName',
                                                    label: 'Name',
                                                    input: 'text',
                                                    required: true,
                                                    size: FieldSize.four
                                                }, 'ongoingCosts', i)}
                                                {inputArrayHelper({
                                                    name: 'amount',
                                                    label: 'Amount',
                                                    input: 'money',
                                                    required: true,
                                                    size: FieldSize.three
                                                }, 'ongoingCosts', i)}
                                                 {inputArrayHelper({
                                                    name: 'recurring',
                                                    label: 'Recurring',
                                                    input: 'select',
                                                    options: RecurringExpense,
                                                    required: true,
                                                    size: FieldSize.four
                                                }, 'ongoingCosts', i)}
                                                <Grid item xs={1} style={{margin: 'auto'}}><Button onClick={() => arrayHelpers.remove(i)}><IconButton><Icon>close</Icon></IconButton></Button></Grid>
                                            </Grid>

                                        </CardContent>
                                    </Card>)}
                                    <Button style={{marginTop: '5px'}} onClick={() => arrayHelpers.push({})}variant="outlined" color='primary'>Add Ongoing Cost</Button>
                            </React.Fragment>)}
                        />
                    </Grid> 
            </CardContent>
        </Card>      
    </Grid>
)

export default SalesFunnelForm