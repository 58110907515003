import React from 'react';
import { isEmpty } from 'lodash';
import { Formik, Form, FieldArray } from 'formik';
import * as config from '../../FormGenerator/formConfigs';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography } from '@material-ui/core';
import ArrayItemCard from '../FieldArray/ArrayItemCard';
import { inputHelper } from '../../FormGenerator/fields';
import classes from '../OnboardingSteps.module.css';
import { FieldSize, UserEventKinds } from '../../../classes/types';
import { checkUserMobile } from '../../../helpers/helpers';
import * as Yup from 'yup';

interface FuncProps {
    next: (form: any, stepName: string) => void;
    back: () => void;
    close: () => void;
    currentStep: number;
    initialValues: any;
}

const validationSchema = Yup.object().shape({
    productName: Yup
        .string()
        .required('Please enter a name'),
    price: Yup
        .number()
        .nullable(true)
        .typeError('Please enter a number')
        .min(0, 'Price must be > $0')
        .required('Please input a price'),
    productComponents: Yup.array(Yup.object().shape({
        componentName: Yup
            .string()
            .required('Please enter a name'),
        unitCost: Yup
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Cost must be > $0')
            .required('Please enter a cost'),
        unitVolume: Yup
            .number()
            .nullable(true)
            .typeError('Please enter a number')
            .min(0, 'Volume must be > 0')
            .required('Please enter a volume')
    }))
});

const OnboardingOneProductDialog: React.FC<FuncProps>= ({next, back, close, currentStep, initialValues}) => {

    const form = config.onboardingSteps[3];
    const productComponents = config.domains.filter(domain => domain.kind === 'productComponents')[0];
    const thisStep = 3;
    const stepName = 'productConfig';

    const [projectSelection, setProjectSelection] = React.useState(0);
    const [componentSelection, setComponentSelection] = React.useState(0);

    const handleProjectExpansionChoice = (choice: number) => {
        setProjectSelection(choice);
    }

    const handleComponentExpansionChoice = (choice: number) => {
        setComponentSelection(choice);
    }

    return(
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                values.kind = UserEventKinds.project_one_product_creation;
                next(values, stepName);
                actions.resetForm();
                actions.setSubmitting(false);
                close()

            }}
        >
            {({ values, isSubmitting, dirty, errors }) => (
                <Dialog
                    open={currentStep === thisStep}
                    onClose={close}
                    disableBackdropClick={true}
                    fullWidth={true}
                    fullScreen={checkUserMobile()}
                    maxWidth="md"
                >
                    <Form>
                        <DialogTitle>
                        {form.title}
                        <Typography variant="subtitle2" color="textSecondary">{form.subtitle}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                {inputHelper({
                                    name: 'productName',
                                    label: 'Product Name',
                                    input: 'text',
                                    required: true,
                                    size: FieldSize.six
                                })}
                                {inputHelper( {
                                    name: 'price',
                                    label: 'Sale Price',
                                    input: 'money',
                                    required: true,
                                    size: FieldSize.six
                                })}
                                <Grid item xs={12}><Typography variant="h6">What component costs go into making one of these product?</Typography></Grid>
                                <FieldArray
                                    name="productComponents"
                                    render={arrayHelpers => (
                                        <div className={classes.array}>
                                            {values.productComponents.map((product: any, i: number) => {
                                                return (<ArrayItemCard 
                                                    remove={arrayHelpers.remove} 
                                                    add={arrayHelpers.push} 
                                                    form={productComponents} 
                                                    array={productComponents.kind}
                                                    index={i}
                                                    propsExpansion={componentSelection}
                                                    values={values}
                                                    pannelChoice={handleComponentExpansionChoice}
                                                    />)
                                            }
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                onClick={back}
                                color="secondary" 
                                type="button">
                                Back
                            </Button>
                            <Button 
                                disabled={
                                    isSubmitting || 
                                    !isEmpty(errors) || 
                                    !dirty
                                } 
                                color="primary" 
                                type="submit">
                                Next Step
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}
        </Formik>
    )
}

export default OnboardingOneProductDialog;