import React from 'react';
import { checkUserMobile } from '../../helpers/helpers';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import OnboardingBasicDialog from '../../components/OnboardingDialogs/OnboardingBasicDialog';
import OnboardingCompanyFoundersDialog from '../../components/OnboardingDialogs/OnboardingCompanyFoundersDialog';
import OnboardingProductSelectionDialog from '../../components/OnboardingDialogs/OnboardingProductSelectionDialog';
import OnboardingProduct from '../../components/OnboardingDialogs/OnboardingProduct';
import OnboardingSalesFunnelStepOneDialog from '../../components/OnboardingDialogs/OnboardingSalesFunnelStepOneDialog';
import OnboardingSalesFunnelStepTwoDialog from '../../components/OnboardingDialogs/OnboardingSalesFunnelStepTwoDialog';
import OnboardingCostsDialog from '../../components/OnboardingDialogs/OnboardingCostsDialog';
import OnboardingConfirmationDialog from '../../components/OnboardingDialogs/OnboardingConfirmationDialog';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes, AppState } from '../../store/store';
import { UserEventKinds, ExpectationRampUp, ProjectDurationFrequency } from '../../classes/types';
import { bindActionCreators } from 'redux';
import { addEventToUserEvents, setOnboardingComplete } from '../../store/appState/actions/actionCreators';
import { connect } from 'react-redux';
import moment from 'moment';

import { generateProjections } from '../../stitch/functions';

import SnackBar from '../../components/SnackBar/SnackBar';

interface DispatchProps {
    submit: (event: any) => void;
    setOnboardingComplete: (props: any) => void;
}

interface StateProps {
    events: Array<any>;
    onboardingComplete: boolean;
}

interface ClassState {
    basicCompany: any;
    company: any;
    product: any;
    salesFunnel: any;
    productSelected: string;
    currentStep: number;
    recurringCosts: any;
    revenueDate: any
}

export interface Vote {
    kind: string
    selected: boolean
}

type Props = RouteComponentProps & DispatchProps & StateProps

class OnboardingFlowPage extends React.Component<Props, ClassState> {
    
    state: ClassState =  {
        basicCompany: {
            companyName: undefined,
            date: undefined,
            founders: [{}],
            productName: undefined,
            price: undefined,
            amountOfSales: undefined,
            salesStartDate: undefined,
            costs: [],
            kind: UserEventKinds.basicCompany,
            expectations: []
        },
        company: {
            companyName: undefined,
            date: undefined,
            founders: [{}],
            setupCosts: [],
            kind: UserEventKinds.account
        },
        product: {
            productName: undefined,
            date: undefined,
            price: undefined,
            projectCosts: [],
            productComponents: [{}],
            duration: undefined,
            durationFrequency: ProjectDurationFrequency.months,
            kind: UserEventKinds.project_product_creation
        },
        salesFunnel: {
            projectName: undefined,
            date: undefined,
            duration: undefined,
            durationFrequency: ProjectDurationFrequency.months,
            funnelInput: 'Lead',
            funnelOutput: 'customer',
            funnelOutputRate: 10,
            leadTypes: [],
            projectCosts: [],
            ongoingCosts: [],
            projectExpectations: [],
            kind: UserEventKinds.project_sales_funnel
        },
        revenueDate: undefined,
        recurringCosts: {
            date: undefined,
            recurringCosts: [],
            kind: UserEventKinds.recurringExpense
        },
        productSelected: '',
        currentStep: 0
    }

    display = () => {
        switch(this.state.currentStep) {
            case 0:
                return <OnboardingBasicDialog 
                    submitBasic={this.submitBasicForm} 
                    next={this.nextStep}
                    close={this.closeStep} 
                    currentStep={this.state.currentStep}
                    initialValues={this.state.basicCompany}
                />
            case 1:
                return <OnboardingCompanyFoundersDialog 
                    next={this.nextStep} 
                    back={this.goBack}
                    close={this.closeStep} 
                    currentStep={this.state.currentStep}
                    initialValues={this.state.company}
                />
            case 2:
                return <OnboardingProductSelectionDialog 
                    next={this.nextStep} 
                    back={this.goBack}
                    close={this.closeStep} 
                    currentStep={this.state.currentStep}
                />
            case 3:
                return <OnboardingProduct 
                    next={this.nextStep} 
                    back={this.goBack}
                    close={this.closeStep} 
                    currentStep={this.state.currentStep} 
                    choice={this.state.productSelected}
                    initialValues={this.state.product}
                />
            case 4:
                return <OnboardingSalesFunnelStepOneDialog 
                    next={this.nextStep} 
                    back={this.goBack}
                    close={this.closeStep} 
                    currentStep={this.state.currentStep} 
                    initialValues={this.state.salesFunnel}
                />
            case 5:
                return <OnboardingSalesFunnelStepTwoDialog 
                    next={this.nextStep} 
                    back={this.goBack}
                    close={this.closeStep} 
                    currentStep={this.state.currentStep} 
                    variable={this.state.salesFunnel.funnelInput}
                    initialValues={this.state.salesFunnel}
                />
            case 6:
                return <OnboardingCostsDialog 
                    next={this.nextStep} 
                    back={this.goBack}
                    close={this.closeStep} 
                    currentStep={this.state.currentStep}
                    companyName={this.state.company.companyName}
                    productName={this.state.product.productName}
                    funnelName={this.state.salesFunnel.projectName}
                    initialValues={{
                        companyCosts: this.state.company.setupCosts,
                        productCosts: this.state.product.projectCosts,
                        funnelCosts: this.state.salesFunnel.projectCosts
                    }}
                />
            case 7:
                return <OnboardingConfirmationDialog 
                    next={this.nextStep} 
                    back={this.goBack}
                    close={this.closeStep} 
                    currentStep={this.state.currentStep} 
                    submit={this.submitForm}
                    initialValues={{date: this.state.revenueDate}}
                />
            default:
                alert('Something went wrong please contact support')
                return <div>Please contact support</div>
        }
    }

    submitBasicForm = async (form: any) => {
        this.setState(prevState => {
            return {
                ...prevState,
                basicCompany: {
                    ...prevState.basicCompany,
                    companyName: form.companyName,
                    date: form.date,
                    founders: form.founders,
                    productName: form.productName,
                    price: form.price,
                    amountOfSales: form.amountOfSales,
                    salesStartDate: form.salesStartDate,
                    costs: form.costs,
                    expectations: [{
                        improvNumber: form.amountOfSales,
                        variable: 'customer',
                        type: "new",
                        rampUpMonths: 0,
                        rampUpType: 'gradually'
                    }]
                }
            }  
        });

        await this.props.submit(this.state.basicCompany);
        await this.props.setOnboardingComplete(this.props);
        await generateProjections();
        if (checkUserMobile()) {
            this.props.history.push(`/grid`);
        } else {
            this.props.history.push(`/projections`);
        }
    }

    submitForm = async (form: any) => {
        let numberOfMonths: number = moment(form.date).clone().diff(moment(this.state.company.date).clone(), 'months', true)

        debugger
        this.setState(prevState => {
            return {
                ...prevState,
                salesFunnel: {
                    ...prevState.salesFunnel,
                    duration: numberOfMonths
                },
                product: {
                    ...prevState.product,
                    duration: numberOfMonths
                }
            }  
        });
        await this.props.submit(this.state.company);
        await this.props.submit(this.state.product);
        await this.props.submit(this.state.salesFunnel);
        if (this.state.recurringCosts.recurringCosts.length > 0) {
            await this.props.submit(this.state.recurringCosts);
        }
        await this.props.setOnboardingComplete(this.props);
        await generateProjections();
        if (checkUserMobile()) {
            this.props.history.push(`/grid`);
        } else {
            this.props.history.push(`/projections`);
        }
        
    }

    nextStep = (form: any, stepName: string) => {
        
        switch(stepName){
            case('basicSetup'):
                return this.setState(prevState => {
                    return {
                        currentStep: prevState.currentStep + 1
                    }  
                });
            case('companySetup'):
                return this.setState(prevState => {
                    return {
                        company: {
                            ...prevState.company,
                            companyName: form.companyName,
                            date: form.date,
                            founders: form.founders
                        },
                        product: {
                            ...prevState.product,
                            date: form.date
                        },
                        salesFunnel: {
                            ...prevState.salesFunnel,
                            date: form.date
                        },
                        currentStep: prevState.currentStep + 1
                    }  
                });
            case('productSelection'):
                return this.setState(prevState => {
                    return {
                        productSelected: form,
                        currentStep: prevState.currentStep + 1
                    }  
                    });
            case('productConfig'):
                return this.setState(prevState => {
                    return {
                        product: {
                            ...prevState.product,
                            productName: form.productName,
                            price: form.price,
                            productComponents: form.productComponents,
                            kind: form.kind
                        },
                        currentStep: prevState.currentStep + 1
                    }  
            });
            case('salesFunnelStepOne'):
                return this.setState(prevState => {
                    return {
                        salesFunnel: {
                            ...prevState.salesFunnel,
                            projectName: form.projectName,
                            funnelInput: form.funnelInput,
                            funnelOutput: form.funnelOutput,
                            funnelOutputRate: form.funnelOutputRate,
                            leadTypes: form.leadTypes
                        },
                        currentStep: prevState.currentStep + 1
                    }
                });
            case('salesFunnelStepTwo'):
                const expectation = [{
                    improvNumber: form.improvNumber,
                    variable: this.state.salesFunnel.funnelInput,
                    type: "new",
                    rampUpMonths: 0,
                    rampUpType: ExpectationRampUp.gradually
                }]
                return this.setState(prevState => {
                    return {
                        salesFunnel: {
                            ...prevState.salesFunnel,
                            projectExpectations: prevState.salesFunnel.projectExpectations.concat(expectation)
                        },
                        currentStep: prevState.currentStep + 1
                    }  
                });
            case('startupCosts'):
            const companyOneOff: Array<any> = [];
            const companyRecurring: Array<any> = [];
            const productOneOff: Array<any> = [];
            const productRecurring: Array<any> = [];
            const funnelOneOff: Array<any> = [];
            const funnelRecurring: Array<any> = [];

            form.companyCosts.forEach((cost: any) => {
                if (cost.recurring) {
                    cost.date = this.state.company.date;
                    companyRecurring.push(cost)
                } else {
                    companyOneOff.push(cost)
                }
            })

            form.productCosts.forEach((cost: any) => {
                if (cost.recurring) {
                    cost.date = this.state.company.date;
                    productRecurring.push(cost)
                } else {
                    productOneOff.push(cost)
                }
            })

            form.funnelCosts.forEach((cost: any) => {
                if (cost.recurring) {
                    cost.date = this.state.company.date;
                    funnelRecurring.push(cost)
                } else {
                    funnelOneOff.push(cost)
                }
            })
                return this.setState(prevState => {
                    return {
                        ...prevState,
                        company: {
                            ...prevState.company,
                            setupCosts: companyOneOff
                        },
                        salesFunnel: {
                            ...prevState.salesFunnel,
                            projectCosts: funnelOneOff,
                            ongoingCosts: funnelRecurring
                        },
                        product: {
                            ...prevState.product,
                            projectCosts: productOneOff
                        },
                        recurringCosts: {
                            ...prevState.recurringCosts,
                            recurringCosts: companyRecurring.concat(productRecurring)
                        },
                        currentStep: prevState.currentStep + 1
                    }  
                });
            default: alert('There has been an error, please contact support with reference OBFLOW');
        }
    }

    goBack = () => {
        this.setState(prevState => {
            return {
                currentStep: prevState.currentStep - 1
            }  
        });
    }

    closeStep = () => {}

    snackBar = () => this.props.location.state ? <SnackBar message={this.props.location.state.message} hideDuration={10000} state={this.props.location.state.snackState} /> : null

    render() {

        return (
            <React.Fragment>
                {this.display()}
                {this.snackBar()}
            </React.Fragment>
        )
    }


}

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps): StateProps => {
    return {
        events: state.appState.events,
        onboardingComplete: state.appState.userSettings.onboardingComplete
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>, ownProps: RouteComponentProps): DispatchProps => {
    return {
        submit: bindActionCreators(addEventToUserEvents, dispatch),
        setOnboardingComplete: bindActionCreators(setOnboardingComplete, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingFlowPage));