import React from 'react';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';

import { domains, FormConfig, DomainConfig } from './formConfigs';
import { Formik, Form } from 'formik';
import { inputHelper } from '../../components/FormGenerator/fields';
import { isEmpty } from 'lodash';
import { Typography, Button, Grid, DialogContent, DialogActions, Dialog, DialogTitle } from '@material-ui/core';
import {checkUserMobile} from '../../helpers/helpers';
import { basicCompanyValidation, salesFunnelValidation, projectOneProductCreationValidation, projectAvgRevProductCreationValidation } from './formConfigs';

import { generateProjections } from '../../stitch/functions';
import ArrayOptions from './ArrayOptions/ArrayOptions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes, store } from '../../store/store';
import { addEventToUserEvents, updateEvent } from '../../store/appState/actions/actionCreators';
import { forms } from '../FormGenerator/formConfigs';
import classes from './FormGenerator.module.css';
import { UserEventKinds } from '../../classes/types';
import BasicCompanyForm from '../Forms/BasicCompanyForm/BasicCompanyForm';
import SalesFunnelForm from '../Forms/SalesFunnelForm/SalesFunnelForm';
import GenericProductForm from '../Forms/GenericProductForm/GenericProductForm';
import BasicRampUpForm from '../Forms/BasicRampUpForm/BasicRampUpForm';

interface FuncProps extends RouteComponentProps<{
    type: string;
    id: string;
    component: string;
}> {}

interface DispatchProps {
    submit: (values: any) => void;
    update:  (values: any) => void;

}

type Props = FuncProps & DispatchProps;

const FormGenerator: React.FC<Props> = ({ submit, update, match, history}) => {

    const [state, setState] = React.useState(true);

    let form: any;
    let title: any;
    let kind: any;
    let validation: any;
    let event: any;
    let component: any;
    let initialValues: any;
    let buttonText: string;

    if(match.params.type === 'create') {
        form = forms.filter(form => form.kind === match.params.id)[0];
        initialValues = form.initialValues;
        title = form.title;
        validation = form.validation;
        buttonText = 'Create';
    } else if (match.params.type === 'edit') {
        event = store.getState().appState.events.filter(event => event._id.toString() === match.params.id)[0];
        if (!event) {
            return <Redirect to='/events' />
        }
        switch (event.kind) {
            case UserEventKinds.basicCompany:
                kind = UserEventKinds.basicCompany;
                title = 'Your Company Settings';
                initialValues = event;
                validation = basicCompanyValidation;
                buttonText = 'Update';
                break;
            case UserEventKinds.project_sales_funnel:
                kind = UserEventKinds.project_sales_funnel;
                title = `${event.projectName} Settings`;
                initialValues = event;
                validation = salesFunnelValidation;
                buttonText = 'Update';
                break;
            case UserEventKinds.project_avgRevenue_product_creation:
                kind = UserEventKinds.project_avgRevenue_product_creation;
                title = `${event.productName} Settings`;
                initialValues = event;
                validation = projectAvgRevProductCreationValidation;
                buttonText = 'Update';
                break;
            case UserEventKinds.project_one_product_creation:
                kind = UserEventKinds.project_one_product_creation;
                title = `${event.productName} Settings`;
                initialValues = event;
                validation = projectOneProductCreationValidation;
                buttonText = 'Update';
                break;
            default:
                form = forms.filter(form => form.kind === event.kind)[0];
                initialValues = event
                title = form.title;
                validation = form.validation;
                buttonText = 'Update';
                break;
        }
        if (match.params.component) {
            component = match.params.component;
            title = 'Sales Ramp Up Time';
        }
    }
    

    const createFieldArray = (domain: DomainConfig, values: any) => {
        return <div key={domain.kind} className={classes.array}><ArrayOptions domain={domain} values={values} /></div>
    }

    const display = (values: any, form?: FormConfig, kind?: string ) => {
        if (kind && !component) {
            switch(kind) {
                case UserEventKinds.basicCompany:
                    return <BasicCompanyForm values={values} />
                case UserEventKinds.project_sales_funnel:
                    return <SalesFunnelForm values={values} />
                case UserEventKinds.project_avgRevenue_product_creation:
                    return <GenericProductForm values={values} componentType={'type'} />
                case UserEventKinds.project_one_product_creation:
                    return <GenericProductForm values={values} componentType={'part'} />
                default:
                    return
            }
        }  else if (kind && component) {
            switch(component) {
                case 'basic-ramp-up':
                    return <BasicRampUpForm values={values}/>
            }
        } else if (form) {
            return form.components.map(component => {
                const newItem = domains.filter(domain => domain.kind === component)[0]
                if (newItem.type === 'array') {
                    return createFieldArray(newItem, values);
                } else {
                    return (
                        <React.Fragment key={newItem.title}>
                            <Grid item xs={12}><Typography variant="h6">{newItem.title}</Typography></Grid>
                            {newItem.options.map(option => inputHelper(option))}
                        </React.Fragment>)
                }
            });
        } else {
            return
        }   
    }

    const closeForm = () => {
        history.goBack()
        setState(false)
        
    }

    return (
        <Dialog
            open={state} 
            onClose={closeForm} 
            disableBackdropClick={true}
            maxWidth="md"
            fullScreen={checkUserMobile()}
            fullWidth={true}
        >
            <DialogTitle>{title}</DialogTitle>
            <Formik
                initialValues={initialValues}
                isInitialValid={match.params.type === 'edit'}
                validationSchema={validation}
                onSubmit={async (values, actions) => {
                    
                    if (match.params.type === 'create') {
                        let result: any = values;
                        result.kind = form.kind
                        await submit(result)
                    } else {
                        await update(values)
                    }
                    generateProjections();      
                    actions.resetForm();
                    actions.setSubmitting(false);
                    closeForm()
                }}
            >
                {({ values, isSubmitting, isValid, errors}) => (
                    <Form>
                        <DialogContent>
                            <Grid container> 
                                {display(values, form, kind)}
                            </Grid>   
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                disabled={
                                    isSubmitting || 
                                    !isEmpty(errors) ||
                                    !isValid
                                } 
                                color="primary" 
                                type="submit">
                                {buttonText}
                            </Button>
                            <Button 
                                onClick={closeForm}
                                color="secondary" 
                                type="button">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>, ownProps: FuncProps): DispatchProps => {
    return {
        submit: bindActionCreators(addEventToUserEvents, dispatch),
        update: bindActionCreators(updateEvent, dispatch),
    }
}

export default withRouter(connect(null, mapDispatchToProps)(FormGenerator));