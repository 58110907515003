import { app } from "../database/mongodb";
import _ from 'lodash';
import { bugsnagClient } from '../bugsnag/bugsnag';

import { UserPasswordCredential, UserPasswordAuthProviderClient } from "mongodb-stitch-browser-sdk";
import { store } from "../store/store";
import { loginStarted, loginFailed, loginSuccess } from "../store/appState/actions/actionCreators";
import { registerStarted, registerFailed, registerSuccess } from "../store/appState/actions/actionCreators";

export function createCredential (email: string, password: string) {
    email = _.toLower(email);
    return new UserPasswordCredential (email, password);
}

export async function login(email: string, password: string) {
   store.dispatch(loginStarted())
    try {
        email = _.toLower(email);
        const credential = createCredential(email,password);
        const result = await app.auth.loginWithCredential(credential)
        store.dispatch(loginSuccess())
        return result
    } catch(error) {
        store.dispatch(loginFailed())
        bugsnagClient.notify(error)
        return error

    }

}
  
export const hasLoggedInUser = ():boolean => {
    return app.auth.isLoggedIn;
}
  
export function getCurrentUser() {
    return app.auth.isLoggedIn ? app.auth.user : null;
    
}
  
export function logoutCurrentUser() {
    const user: any = getCurrentUser();
    return app.auth.logoutUserWithId(user.id);

}

const emailPassClient = app.auth
  .getProviderClient(UserPasswordAuthProviderClient.factory);


export async function registerNewAccount(values: any) {
    store.dispatch(registerStarted());
    try {
        const result = await app.auth.getProviderClient(UserPasswordAuthProviderClient.factory)
            .registerWithEmail(values.email, values.password);
        const user = await login(values.email, values.password);
        app.callFunction('savePersonalInfoInitial', [values.firstName, values.lastName, values.company, values.industry])
        store.dispatch(registerSuccess());
        return result

    } catch(error) {
        bugsnagClient.notify(error) 
        store.dispatch(registerFailed())
        return error
    }
}

export async function confirmEmail(token: string, tokenId: string) {
    try {
        return app.auth.getProviderClient(UserPasswordAuthProviderClient.factory)
            .confirmUser(token, tokenId);
    } catch(error) {
        bugsnagClient.notify(error) 
        return error
    }
}