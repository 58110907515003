export const checkUserMobile = (): boolean => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}

export const monthFormat = (value: number) => {
    if (value < 0) {
        return '0.0'
    } else return '0'
}